@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.referralSystem {
    width: 74.6vw;
    margin: 2vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }
}
