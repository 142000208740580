@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.referralSystemFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.6vw 0 $desctop20px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 100%;
        flex-wrap: wrap;
        margin: 4vw 0 0;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        flex-wrap: wrap;
        margin: $mobile20px 0 0;
    }

    &__item {
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 40%;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile34px;
            width: 100%;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        color: $textDarkLight;
        margin-bottom: 0.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            margin-bottom: $mobile10px;
        }
    }

    &__select {
        background: #f2f3f9;
        border-radius: 0.1vw;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            border-radius: 2px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            border-radius: 2px;
            width: 100%;
        }

        & > * {
            box-shadow: none !important;
            font-family: $MontserratRegular;
        }

        .ant-select-selection {
            min-width: 12.7vw;
            height: 3.91vw;
            border: 1px solid #f2f3f9;
            background-color: transparent;
            border-radius: 0.1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                border: 1px solid #f2f3f9;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                border: 1px solid #f2f3f9;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                    border-radius: 2px;
                }
            }
        }

        .anticon {
            color: $textDarkLight;
        }

        .ant-select-arrow {
            right: 1vw;
            font-size: 0.8vw;
            margin-top: 0;
            transform: translateY(-50%);

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                right: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                right: 3.2vw;
                font-size: $mobile10px;
            }
        }

        .ant-select-selection__rendered {
            height: 100%;
            display: flex;
            align-items: center;
            outline: none;
            margin: 0 1vw;
            line-height: inherit;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: 0 $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                margin: 0 6vw 0 $mobile12px;
            }
        }

        .ant-select-selection__placeholder {
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $greyButton;
            transform: translateY(-50%);
            margin: 0;
            line-height: normal;
            height: auto;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                border-radius: 2px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                border-radius: 2px;
            }
        }
    }

    &__deleteIcon {
        position: absolute;
        top: 2.9vw;
        right: 1vw;
        height: 0.8vw;
        width: 0.8vw;
        z-index: 9999;
        cursor: pointer;
        z-index: 1;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            right: 1.6vw;
            height: 1.6vw;
            width: 1.6vw;
            top: 5.7vw;
        }

        @media only screen and (max-width: 499px) {
            right: 3.2vw;
            height: 2.6vw;
            width: 2.6vw;
            top: 12.4vw;
        }
    }

    &__calendarIcon {
        height: $desctop14px;
        width: $desctop14px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet18px;
            width: $tablet18px;
            margin-top: -1.3vw;
            right: $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            height: 4.8vw;
            width: 4.8vw;
            margin-top: -3vw;
            right: $mobile12px;
        }
    }

    &__picker {
        background: #f2f3f9;
        min-width: 12.7vw;
        height: 3.91vw;
        border: 1px solid #f2f3f9;
        border-radius: 0.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: 7.82vw;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
        }

        .ant-calendar-picker-input {
            padding: 0;
            border: none;
            background-color: transparent;
            display: flex;
            flex: 1;
            height: 100%;
        }

        .ant-calendar-range-picker-separator {
            display: flex;
            align-items: center;
        }

        .ant-calendar-range-picker-input {
            color: $textDarkLight;
            font-family: $MontserratRegular;

            &::placeholder {
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: #92969c;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: 2.1vw;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile16px;
                    line-height: $mobile16px;
                }
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 14px;
                width: 42%;
            }
        }

        svg path {
            fill: #4F4F4F;
        }

        .anticon-calendar,
        .ant-calendar-picker-clear {
            display: none;
        }
    }
}

.myReferralsTable {
    margin-bottom: $desctop20px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: $tablet20px;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile20px;
    }

    .ant-table-body {
        @media only screen  and (max-width: 1024px) {
            overflow-x: scroll;
        }
    }

    .ant-table-thead {
        th {
            font-family: $MontserratRegular;
            color: #828282;
            background: #F7F7F7;
            border-bottom: $desctop1px solid #EFF0F3;

            @media only screen and (min-width: 500px) {
                font-size: $desctop12px;
                line-height: $desctop10px;
                padding: $desctop18px 0;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: $tablet18px 1vw $tablet18px  0;
            }

            @media only screen and (max-width: 499px) {
               white-space: nowrap;
            }

            &:first-child {

                @media only screen and (min-width: 500px) {
                    padding-left: 3.8vw;
                }

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-left: $tablet30px;
                }
            }

            &:last-child {
                @media only screen and (min-width: 500px) {
                    padding-right: $desctop30px;
                }

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-right: $tablet30px;
                }
            }
        }
    }

    .ant-table-tbody {
        td {
            color: $textDarkLight;
            font-family: $MontserratRegular;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: transparent;
            border-bottom: $desctop1px solid #EFF0F3;
            white-space: nowrap;

            @media only screen and (min-width: 500px) {
                height: $desctop60px;
                padding: 1.3vw 0;
                font-size: $desctop12px;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                padding-right: 3vw;
            }

            &:first-child {
                @media only screen and (min-width: 500px) {
                    padding-left: $desctop30px;
                }

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-left: 1vw;
                }
            }

            &:last-child {
                @media only screen and (min-width: 500px) {
                    padding-right: $desctop30px;
                }

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-right: $tablet30px;
                }
            }
        }
    }

    .ant-table-row-expand-icon {
        @media only screen and (min-width: 500px) {
            margin-right: $desctop8px !important;
            width: $desctop17px;
            height: $desctop17px;
            line-height: $desctop13px;
            border-radius: $desctop2px;
            border: $desctop1px solid #e8e8e8;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: $tablet10px !important;
            width: $tablet18px;
            height: $tablet18px;
            line-height: $tablet14px;
            border-radius: $desctop2px;
            border: $desctop1px solid #e8e8e8;
        }
    }

    .ant-table-row-collapsed {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
