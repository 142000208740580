@import '../.././assets/styles/variables';

.faq {
    padding: 3vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet20px $tablet88px 0;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
    }

    &__container {
        margin-bottom: 50px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 7vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px $tablet60px $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 $mobile60px $mobile42px $mobile60px;
            flex-wrap: wrap;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.35vw;
        margin-bottom: 1.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile16px;
        }
    }

    &__answer {
        font-family: $MontserratRegular;
        font-size: 0.9vw;
        line-height: 1.6vw;
        color: $greyLight;


        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            line-height: $tablet24px;
            width: 85%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
        }
    }
}
