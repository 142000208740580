@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.tradeModal {
    position: relative;
    padding: 5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        overflow: auto;
        padding: 10vw;
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;
        width: 1.1vw;
        height: 1.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            top: $tablet20px;
            right: $tablet20px;
            width: $tablet28px;
            height: $tablet28px;
        }

        @media only screen and (max-width: 499px) {
            top: $mobile20px;
            right: $mobile20px;
            width: $mobile20px;
            height: $mobile20px;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.7vw;
        line-height: 2vw;
        margin-bottom: 2vw;
        color: #1F1F1F;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet26px;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile25px;
            margin-bottom: $mobile20px;
            padding-right: 6vw;
        }
    }

    &__buySubmitBtn,
    &__sellSubmitBtn,
    &__exchangeSubmitBtn {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.4vw;
        min-width: 12.7vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            min-width: 31vw;
            margin-top: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            min-width: 100%;
            margin-top: $mobile30px;
            font-size: $mobile12px;
            margin-top: 0;
        }
    }

    &__buySubmitBtn {
        @include greenBtnWithBorderAndBackground;
    }

    &__sellSubmitBtn {
        @include redBtnWithBorderAndBackground;
    }

    &__exchangeSubmitBtn {
        @include blueBtnWithBorderAndBackground;
    }

    &__namePrice {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet30px;;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
            width: 100%;
            flex-wrap: wrap;
        }

        &_nameWrapper {
            display: flex;

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin-bottom: $mobile20px;;
            }
        }

        &_name,
        &_price {
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 1.1vw;
            color: $textDarkLight;
            margin-left: 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet24px;
                margin-left: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile24px;
                margin-left: $mobile10px;
            }

            span {
                color: $blueDark;
                font-size: 1vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet14px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                }
            }
        }

        &_price {
            @media only screen and (max-width: 499px) {
                margin-left: 12.27vw;
            }
        }
    }

    &__logo {
        width: 2.4vw;
        height: 2.4vw;
        border-radius: 50%;
        object-fit: cover;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: 4.7vw;
            width: 4.7vw;
        }

        @media only screen and (max-width: 499px) {
            height: 9.6vw;
            width: 9.6vw;
        }
    }

    &__newTrader {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            &:nth-child(2) {
                margin:  0 5px;
            }
        }

        &_ok,
        &_done {
            width: 0.6vw;
            height: 0.6vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet14px;
                height: $tablet14px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile12px;
                height: $mobile12px;
            }
        }

        span {
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 1.1vw;
            color: $textDarkLight;
            text-transform: uppercase;
            margin-left: 0.6vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                margin-left: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                margin-left: $mobile10px;
            }
        }
    }

    &__userInfo {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2vw;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            padding-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            padding-bottom: $mobile30px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2vw;
            background-image: linear-gradient(to right, transparent 33%, $dottedLine 0%);
            background-position: bottom;
            background-size: 1.3vw 1px;
            background-repeat: repeat-x;
        }
    }

    &__form {
        padding-top: 2vw;

        &_select {
            position: relative;

            @media only screen and (max-width: 499px) {
                margin-top: 5.34vw;
            }
        }
    }

    &__inputWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vw;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            margin-bottom: 0;
        }
    }

    &__inputItem {
        width: 45%;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile20px;
        }
    }

    &__input {
        width: 100%;
        height: 3.91vw;
        background: #f2f3f9;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
        border-radius: 0.1vw;
        border: 1px solid #f2f3f9;
        outline: none;
        padding: 0 1vw 0 1vw;
        margin-top: 0.7vw;

        @media only screen and (max-width: 1024px) {
            border: 1px solid #f2f3f9;
            border-radius: 1px;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            margin-top: $tablet10px;
            font-size: $tablet16px;
            line-height: $tablet16px;
            border-radius: 1px;
            padding: 0 $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            border-radius: 1px;
            height: $mobile60px;
            margin-top: $mobile10px;
            padding: 0 $mobile12px;
        }

        &:hover,
        &:active,
        &:focus {
            border: 1px solid $blueDark;

            @media only screen and (max-width: 1024px) {
                border: 1px solid $blueDark;
            }
        }

        &::placeholder {
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
            }
        }

        &_disabled {
            opacity: 0.7;
            user-select: none;
            pointer-events: none;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        line-height: 0.7vw;
        color: $textDarkLight;
        white-space: nowrap;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
        }

        &_disabled {
            user-select: none;
            pointer-events: none;
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: center;
        padding-top: 2vw;

        @media only screen and (max-width: 499px) {
            padding-top: 5.34vw;
        }
    }
}

.inputWrapper {
    &__invalid {
        color: $red;
        bottom: -1.6vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: fit-content;
        width: -moz-max-content;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }
}
