@import '../../../../assets/styles/variables';

.p2pPage {
    width: 74.6vw;
    margin: 2vw 7.85vw 4vw 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }
}

.orders {
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: $tablet40px;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
        }
    }
}

.mobileNewOrder {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &_link {
        display: none;

        @media only screen and (max-width: 499px) {
            display: flex;
            font-family: $MontserratRegular;
            font-size: $mobile12px;
            line-height: $mobile12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $blueDark;
        }
    }
}