@import '../../../../assets/styles/variables';

// collapse (accordion)
.ant-collapse,
.ant-collapse>.ant-collapse-item,
.ant-collapse-content {
    border: none;
}

.ant-collapse-item {
    margin-bottom: 1vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: $tablet10px;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile10px;
    }
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 2.1vw 4.2vw 2.1vw 3vw !important;
    font-family: $MontserratRegular;
    color: $textDarkLight;
    font-size: 1.3vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet32px $tablet88px !important;
        font-size: $tablet16px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile32px 6vw !important;
        font-size: $mobile16px;
    }
}

.ant-collapse-item {
    background-color: $cabinerBackColor;

    &:not(.ant-collapse-item-active) {
        &:hover {
            background-color: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        }
    }
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-collapse-item-active {
    background-color: $white;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
}

.ant-collapse>.ant-collapse-item-active>.ant-collapse-header {
    color: $blueDark !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 4.2vw;
    left: auto;
    font-size: 0.8vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        right: 7.7vw;
        font-size: 1.5vw;
    }

    @media only screen and (max-width: 499px) {
        right: $mobile20px;
        font-size: 2.5vw;
    }
}

.ant-collapse-borderless {
    background-color: $cabinerBackColor;
}

// collapse (accordion)

.profile-phoneInput {
    position: relative;

    .react-phone-number-input__icon {
        display: flex;

        svg,
        img {
            width: 1.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 2.5vw;
            }

            @media only screen and (max-width: 499px) {
                width: 5.5vw;
            }
        }
    }

    .react-phone-number-input__row {
        height: 100%;
    }

    .react-phone-number-input__country-select {
        display: none;
    }

    .react-phone-number-input__country {
        position: absolute;
        top: 55%;
        left: 1vw;
        transform: translateY(-50%);

        @media only screen  and (max-width: 1024px) {
            top: 57%;
        }
    }

    .react-phone-number-input__input {
        width: 100%;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 3vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1vw;
        line-height: 1.4vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            width: 100%;
            height: $tablet60px;
            margin-top: $tablet10px;
            border-radius: 1px;
            padding-left: 4.5vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
            height: $mobile60px;
            margin-top: $mobile10px;
            border-radius: 1px;
            padding-left: 9vw;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.6vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet55px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile55px;
            }
        }
    }
}
