@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.edcRent {
    font-size: calc(0.66vw + 0.66rem);
    font-family: $MontserratRegular;
    max-width: 100%;

    @media (max-width: 992px) {
        font-size: calc(0.86vw + 0.86rem);
    }

    @media (max-width: 560px) {
        font-size: calc(1vw + 1rem);
    }

    &__button {
        @include mainBtnStyle;
        @include darkBlueBtnWithBorderAndBackground;
        font-family: $MontserratRegular;
        width: 100%;
        min-width: calc(11.5em / 0.6);
        height: calc(3em / 0.6);
        font-size: 0.6em;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(1.5em / 0.6);

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0;
            border-radius: calc(1.5em / 0.6);
            font-size: 0.8em;
            line-height: 1;
            padding: 0;
            min-width: calc(10.5em / 0.6);
            height: calc(2em / 0.6);
            width: fit-content;
            margin: 0 auto;
        }

        @media only screen and (max-width: 499px) {
            min-width: 100%;
            height: calc(2em / 0.6);
            font-size: 0.8em;
            line-height: 1;
            margin: $mobile20px 0 0 0;
            border-radius: calc(1.5em / 0.6);
        }
    }

    .rent {
        padding: 1.15em 3.7em;
        width: 85vw;
        max-width: 100%;
        padding-bottom: 0.7em;

        @media (max-width: 1024px) and (min-width: 500px) {
            padding: 1.15em 11.5vw;
            width: 100%;
        }

        @media (max-width: 499px) {
            padding: 1.15em 6.7vw;
            width: 100%;
        }
    }

    .title {
        margin-bottom: 1.9em;
    }

    .titleTxt {
        font-size: 1.2em;
        color: #151719;
    }

    .rentContainer {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    .leftPart {
        max-width: 23vw;
        width: 100%;

        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }

    .paymentInfo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;

        @media (max-width: 1024px) and (min-width: 500px) {
            margin-bottom: 4vw;
        }

        @media (max-width: 499px) {
            margin-bottom: 7vw;
        }
    }

    .balance {
        text-align: right;
    }

    .captionWr {
        margin-bottom: 0.1em;
    }

    .caption {
        font-size: 0.7em;
        color: #333333;
        line-height: 1;
        display: block;
    }

    .valueWr {
        margin-bottom: 0.1em;
    }

    .value {
        font-size: 0.55em;
        color: #828282;
        letter-spacing: -0.03em;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .rightPart {
        max-width: 26.5em;
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: -0.5em;

        @media (max-width: 1024px) {
            max-width: 100%;
            position: static;
            margin-top: 2em;
        }
    }

    .listInfo {
        padding: 0.7em 1em 0.7em 2em;
        margin: 0;
        margin-left: 2.5em;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);

        @media (max-width: 1024px) {
            margin-left: 0;
        }
    }

    .infoItem {
        list-style: disc;

        &:not(:last-child) {
            margin-bottom: 0.3em;
        }
    }

    .infoTxt {
        color: #828282;
        font-size: 0.7em;
        line-height: 1.3;
    }

    .rentTable {
        padding: 1.15em 3.7em;
        width: 85vw;
        margin-bottom: 0.5em;
        max-width: 100%;

        @media (max-width: 1024px) and (min-width: 500px) {
            padding: 1.15em 11.5vw;
            width: 100%;
        }

        @media (max-width: 499px) {
            padding: 1.15em 6.7vw;
            width: 100%;
        }
    }

    .rentTabs {
        display: flex;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        margin-bottom: 1.5em;
    }

    .tabBtn {
        font-size: 1em;
        color: #828282;
        padding: 0.5em 1.65em 0.55em;
        border: none;
        background-color: transparent;
        margin-left: 0.4em;
        border-top-left-radius: 0.7em;
        border-top-right-radius: 0.7em;
        transition: color 0.3s, border 0.3s;
        border: 1px solid transparent;
        border-bottom: none;
        cursor: pointer;
        outline: none;

        &:hover {
            color: #007aff;
        }

        &.active {
            border: 1px solid #e0e0e0;
            border-bottom: none;
            color: #007aff;
        }
    }

    .scrollContainer {
        max-width: 100%;
        overflow: auto;
    }

    .fieldTicker {
        position: absolute;
        top: 2.8vw;
        right: 1vw;
        font-family: $MontserratRegular;
        font-size: $desctop16px;
        color: $textDarkLight;

        @media (max-width: 1024px) and (min-width: 500px) {
            font-size: $tablet16px;
            top: 5vw;
            right: 3vw;
        }

        @media (max-width: 499px) {
            font-size: $mobile16px;
            top: 11vw;
            right: 3vw;
        }
    }

    .wrapperInput {
        position: relative;
        margin-bottom: 2.2vw;

        @media (max-width: 1024px) and (min-width: 500px) {
            margin-bottom: 4vw;
        }

        @media (max-width: 499px) {
            margin-bottom: 7vw;
        }
    }
}

.overflowHidden {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 9vw;
    width: fit-content;
    display: block;
    margin-right: 0.2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        max-width: 20vw;
    }

    @media only screen and (max-width: 499px) {
        max-width: 29vw;
    }
}

.invalid {
    color: $red;
    bottom: -1.3vw;
    font-family: $MontserratRegular;
    font-weight: 400;
    font-size: 0.8vw;
    width: fit-content;
    width: -moz-max-content;
    position: absolute;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet10px;
        line-height: $tablet10px;
        bottom: -15px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile10px;
        line-height: $mobile10px;
        bottom: -15px;
    }
}

.modalWindow {
    position: relative;
    padding: 3.2vw 3.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 8vw;
    }

    @media only screen and (max-width: 499px) {
        padding: 10vw;
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (max-width: 499px) {
            top: 3.7vw;
            right: 3.7vw
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: $desctop24px;
        margin-bottom: 2vw;
        color: #1f1f1f;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__subTitle {
        font-family: $MontserratRegular;
        font-size: $desctop16px;
        line-height: $desctop24px;
        color: #92969C;
        margin-bottom: $desctop40px;
        margin-right: 20%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile34px;
            margin-bottom: 5.8vw;
            margin-right: 0;
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: space-between;

        &_return,
        &_cancel {
            @include mainBtnStyle;
            font-family: $MontserratRegular;
            width: fit-content;
            width: -moz-max-content;
            min-width: 14vw;
            height: $desctop40px;
            font-size: $desctop16px;
            line-height: $desctop12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $desctop30px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                border-radius: $tablet20px;
                font-size: $tablet16px;
                line-height: $tablet12px;
                padding: 0;
                min-width: 30vw;
                height: 6vw;
            }

            @media only screen and (max-width: 499px) {
                min-width: 50%;
                height: $mobile42px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                border-radius: $mobile20px;
            }
        }

        &_return {
            @include darkBlueBtnWithBorderAndBackground;
            border-radius: 5.34vw;
        }

        &_cancel {
            @include blackBtnWithOutBackground;
            margin-right: $desctop15px;
        }
    }
}
