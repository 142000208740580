@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.fee {
    background: $white;
    padding: 3vw 7.85vw;

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.35vw;
        margin-bottom: 1.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile16px;
        }
    }

    &__table {
        display: flex;
        margin-top: $desctop40px;

        @media only screen and (max-width: 499px) {
            margin-top: 40px;
            overflow: scroll;
        }

        &_label {
            padding: $desctop14px 4vw $desctop14px $desctop16px;
            background: #FAFAFA;
            display: flex;
            justify-content: space-between;
            display: flex;
            align-items: center;
            font-family: $MontserratRegular;
            font-size: $desctop12px;
            color: #828282;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                padding: 14px 55px 14px 16px;
                font-size: 12px;
            }
        }

        &_logo {
            width: 2.4vw;
            height: 2.4vw;
            margin-right: $desctop15px;

            @media only screen and (max-width: 499px) {
              margin-right: 15px;
              width: 35px;
              height: 35px;
            }
        }

        &_handle {
            min-width: $desctop50px;
            margin-right: $desctop30px;

            @media only screen and (max-width: 499px) {
                min-width: 50px;
                margin-right: 30px;
            }
        }

        &_coinName {
            color: #828282;
        }

        &_item {
            height: $desctop60px;
            display: flex;
            align-items: center;
            font-family: $MontserratRegular;
            font-size: $desctop14px;
            color: #151719;
            border-bottom: 1px solid rgba(196, 196, 196, 0.2);
            padding: $desctop18px 4vw $desctop18px $desctop16px;


            @media only screen and (max-width: 499px) {
                height: 60px;
                font-size: 14px;
                padding: 18px 55px 18px 16px;
                white-space: nowrap;
            }
        }

        &_tradeWithdrawDeposit {
            justify-content: flex-end;
            white-space: nowrap;
        }

        &_lightColor {
            font-size: $desctop8px;
            color: #4F4F4F;

            @media only screen and (max-width: 499px) {
                font-size: 8px;
            }
        }

        &_itemColumn {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            p {
                display: flex;
                align-items: center;
                white-space: nowrap;

                &:first-child {
                    margin-bottom: $desctop8px;

                    @media only screen and (max-width: 499px) {
                        margin-bottom: 8px;
                    }

                    span {
                        display: block;
                        margin-left: $desctop5px;

                        @media only screen and (max-width: 499px) {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}
