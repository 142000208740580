@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.areYouSureModal {

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 70vw;
        padding: 7vw;
    }

    @media only screen and (max-width: 499px) {
        width: 70vw;
        padding: 7vw;
    }
}
