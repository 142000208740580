@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixin';

.fiatHistory{
    margin: $desctop14px 0;
}

.table {
    font-size: calc(.66vw + .66rem);
    font-family: $MontserratRegular;

    @media(max-width: 1280px){
        font-size: calc(.56vw + .56rem);
    }
    @media(max-width: 992px){
        font-size: calc(.86vw + .86rem);
    }
    @media(max-width: 560px){
        font-size: calc(1vw + 1rem);
    }
}

.tHead {
    display: flex;
    background: #F7F7F7;
    padding: 0 1em;
    align-items: center;

    .tItem {
        padding: .75em 0;
        color: #828282;
        background: #F7F7F7;
        .tText {
            font-size: .6em;
            display: block;
            line-height: .8;
        }

    }

}

.tBody {}

.tBody__Item {
    display: flex;
    padding: .41em 1em;
    align-items: center;

    .itemId {
        span {
            font-size: .65em;
        }
    }
}

.tItem {
    color: #151719;


}
.tText{
    line-height: 1.6;
    display: block;
    font-size: .7em;
}
.itemDate {
    color: #828282;
    min-width: 4.8em;
    width: 4.8em;
}

.itemId {
    color: #4F4F4F;
    min-width: 6.35em;
    width: 6.35em;
    text-align: center;
}
.itemType{
    min-width: 5.1em;
    width: 5.1em;
    text-align: center;
}
.itemCurrency{
    min-width: 5.7em;
    width: 5.7em;
    text-align: center;
}
.itemAmount{
    min-width: 6.7em;
    width: 6.7em;
    text-align: center;
}
.itemFiat{
    min-width: 5.1em;
    width: 5.1em;
    text-align: center;
}
.itemTotal{
    min-width: 7.4em;
    width: 7.4em;
    text-align: center;
}
.itemPrice{
    min-width: 4.7em;
    min-width: 4.7em;
    text-align: right;
}
.itemStatus{
    margin-left: auto;
    @media(max-width: 1080px){
        min-width: 4.7em;
        min-width: 4.7em;
        text-align: right;
    }
    &.new{
        color: #2F80ED;
    }
    &.pending{
        color: #828282;
    }
    &.complete{
        color: #28A745;
    }
    &.failed{
        color: #E55541;
    }
}
.fiatHistory{
    overflow: auto;
}
