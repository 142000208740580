@import '../../assets/styles/variables';

#media {
    background-color: #f5f6fa;

    .slickSlider {
        margin: 0 -9vw;

        @media only screen and (max-width: 1024px) {
            margin: 0;
        }

        .slickSlider__arrowPrev,
        .slickSlider__arrowNext {
            position: absolute;
            bottom: -4.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                bottom: -$tablet70px;
            }

            @media only screen and (max-width: 499px) {
                bottom: -21.5vw;
            }
        }

        .slickSlider__arrowPrev {
            right: 16.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                right: 17.55vw;
            }
        }

        .slickSlider__arrowNext {
            right: 12vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: 0;
            }

            @media only screen and (max-width: 499px) {
                right: 0;
            }
        }
    }
}

#team {
    .slickSlider {
        .slickSlider__arrowPrev,
        .slickSlider__arrowNext {
            position: absolute;
            bottom: -4.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                bottom: -$tablet70px;
            }

            @media only screen and (max-width: 499px) {
                bottom: -21.5vw;
            }
        }

        .slickSlider__arrowPrev {
            right: 7.55vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                right: 17.55vw;
            }
        }

        .slickSlider__arrowNext {
            right: 3.05vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: 0;
            }

            @media only screen and (max-width: 499px) {
                right: 0;
            }
        }
    }
}
