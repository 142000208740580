@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.tableRowWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.1vw 3vw 2.1vw 3vw;
    margin-bottom: 0.5vw;
    transition: all 0.25s;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        flex-wrap: wrap;
        margin-bottom: $tablet10px;
        padding: $tablet30px;
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        border-radius: 2px;
    }

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: $mobile30px;
        background: $white;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        margin-bottom: $mobile10px;
        position: relative;
    }

    &:hover {
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    }

    &__logoStars {
        display: flex;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: -1;
            width: 35%;
            height: 6.25vw;
        }

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            order: 1;
            width: 45%;
            margin: 0 0 $mobile20px 0;
        }
    }

    &__logo {
        width: 2.4vw;
        height: 2.4vw;
        border-radius: 50%;
        object-fit: cover;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet36px;
            width: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            height: 9.6vw;
            width: 9.6vw;
        }
    }

    &__nameStarsWrapper {
        margin: 0 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0px 0 0 25px;
            overflow: hidden;
        }

        @media only screen and (max-width: 499px) {
            margin: $mobile10px 0 0 0;
        }

        &_name {
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            color: $greyLight;
            margin-bottom: 0.5vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 5.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: 1.7vw;
                margin-bottom: 5px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

    &__transfer,
    &__country,
    &__currencyRatioWrapper_value,
    &__currencyWrapper_value {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.2vw;
        color: $textDarkLight;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto 0.5vw 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            margin: $tablet40px 5px 0 5px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
        }
    }

    &__currencyWrapper {

        &_title {
            opacity: 0;
        }

        @media only screen and (max-width: 499px) {
            order: 7;
            margin: 0 !important;
            width: 45%;
        }

        &_value {
            width: 7.5vw;
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 14vw;
                margin-top: 0;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                margin: 0;
                width: 100%;
            }
        }
    }

    &__transfer {
        width: 8.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 16vw;
        }

        @media only screen and (max-width: 499px) {
            width: 45%;
            order: 4;
            margin: 0 0 $mobile20px 0;
        }
    }

    &__country {
        width: 7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 12vw;
        }

        @media only screen and (max-width: 499px) {
            width: 45%;
            order: 5;
            margin: 0;
        }
    }

    &__priceWrapper, &__currencyRatioWrapper, &__currencyWrapper {
        &_price, &_title{
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            color: $greyLight;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                margin-bottom: 8px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

    &__currencyRatioWrapper, &__currencyWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 2.58vw 5px 0 0;
            justify-content: flex-start;
        }
    }

    &__currencyRatioWrapper{

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: 0;
        }

        @media only screen and (max-width: 499px) {
            order: 6;
            margin: 0;
            width: 45%;
        }

        &_value {
            width: 10vw;
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
                width: 19vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                margin: 0;
                width: 100%;
            }
        }
    }

    &__priceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: -1;
            justify-content: flex-start;
            margin: 0;
            width: 40%;
        }

        @media only screen and (max-width: 499px) {
            width: 45%;
            order: 3;
            margin: 0 0 $mobile20px 0;
            position: absolute;
            right: 0;
            top: 21vw;
        }

        &_costBuy,
        &_costSell,
        &_costExchange {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.2vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 12.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                width: calc(100% - 8vw);
            }
        }

        &_costBuy {
            color: $greenLight;
        }

        &_costSell {
            color: $red;
        }

        &_costExchange {
            color: $blueDark;
        }
    }

    &__buttonWrapper {
        display: flex;
        align-items: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: -1;
            width: 25%;
            justify-content: flex-end;
        }

        @media only screen and (max-width: 499px) {
            order: 2;
            width: 45%;
            height: 9.6vw;
        }

        &_buttonBuy,
        &_buttonSell,
        &_buttonExchange {
            @include mainBtnStyle;
            height: 2.5vw;
            min-width: 9.5vw;
            font-family: $MontserratRegular;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: 1px;
                height: 9.6vw;
                min-width: 100%;
            }
        }

        &_buttonBuy {
            @include greenBtnWithOutBackground;

            @media only screen and (max-width: 499px) {
                @include greenBtnWithBorderAndBackground;
            }
        }

        &_buttonSell {
            @include redBtnWithOutBackground;

            @media only screen and (max-width: 499px) {
                @include redBtnWithBorderAndBackground;
            }
        }

        &_buttonExchange {
            @include blueBtnWithOutBackground;

            @media only screen and (max-width: 499px) {
                @include blueBtnWithBorderAndBackground;
            }
        }
    }

    &__yourOrderWrapper {
        display: flex;
        align-items: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: -1;
            width: 25%;
            justify-content: flex-end;
        }

        @media only screen and (max-width: 499px) {
            order: 2;
            width: 45%;
            height: 9.6vw;
        }

        &_button {
            @include mainBtnStyle;
            @include greenBtnWithBorderAndBackground;
            height: 2.5vw;
            min-width: 9.5vw;
            font-family: $MontserratRegular;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            pointer-events: none;
            padding: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: 0;
                width: 100%;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: 1px;
                height: 9.6vw;
                min-width: 100%;
            }
        }
    }

    &__statusWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: -1;
        }

        @media only screen and (max-width: 499px) {
            order: 1;
            width: 45%;
            margin: 0 0 $mobile20px 0;
        }

        &_statusLabel {
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            color: $greyLight;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                margin-bottom: 8px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                width: 100%;
                margin-bottom: 5px;
            }
        }

        &_statusPending,
        &_statusCompleted,
        &_statusCanceled {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.2vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 6.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                width: 100%;
            }
        }

        &_statusPending {
            color: $blueDark;
        }

        &_statusCompleted {
            color: $greenLight;
        }

        &_statusCanceled {
            color: $red;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
