@import '../../../../assets/styles/variables';


.rent__wrapper_input {
    width: 100%;
    height: 3.91vw;
    line-height: 1.1vw;
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: $MontserratRegular;
    font-size: 1.1vw;
    color: #151719;
    border-radius: 0.1vw;
    border: 1px solid #f2f3f9;
    outline: none;
    padding: 0 5.5vw 0 1vw;
    margin-top: 0.7vw;

    @media(max-width: 1024px) and (min-width: 500px) {
        width: 100%;
        height: 7.82vw;
        line-height: 7.82vw;
        margin-top: 1.31vw;
        font-size: 2.1vw;
        padding: 1.6vw 9vw 1.6vw 1.6vw;
        border: 1px solid #f2f3f9;
    }

    @media(max-width: 499px) {
        width: 100%;
        max-width: 100%;
        border: 1px solid #f2f3f9;
        height: 16vw;
        line-height: 16vw;
        margin-top: 2.67vw;
        font-size: 4.3vw;
        padding: 3.2vw 14vw 3.2vw 3.2vw;
    }
}

.rent__wrapper_label {
    font-family: $MontserratRegular;
    font-size: 0.8vw;
    line-height: 0.8vw;
    color: #151719;
    display: flex;
    flex-direction: column;

    @media(max-width: 1024px) and (min-width: 500px) {
        font-size: 1.31vw;
        line-height: 1.31vw;
    }

    @media(max-width: 499px) {
        font-size: 2.67vw;
        line-height: 2.67vw;
    }
}

.rentalPeriodSelect {
    margin-bottom: 2.2vw;
    position: relative;

    @media (max-width: 1024px) and (min-width: 500px) {
        margin-bottom: 4vw;
    }

    @media (max-width: 499px) {
        margin-bottom: 10vw;
    }

    .filterBlock-select {
        width: 100%;
    }
}

.returnRentModalWindow {
    width: 37vw;
    height: fit-content;
    height: -moz-max-content;
    padding: 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 80%;
    }

    @media only screen and (max-width: 499px) {
        width: 90%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
