@import '../../assets/styles/variables';

.starRaiting {
    display: flex;
    align-items: center;

    svg {
        width: 1.1vw;
        height: 1.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet16px;
            height: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            width: $mobile16px;
            height: $mobile16px;
        }
    }
}
