@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.firstScreen {
    display: flex;
    justify-content: space-between;
    padding: 0 7.85vw;
    height: 100vh;
    min-height: calc(100vh - 5.5vw);

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding: 5.4vw 6.7vw;
        margin-bottom: 4vw;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding: 5.4vw 6.7vw;
        margin-bottom: 0;
    }

    &__leftSide {
        width: 50%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            max-width: -webkit-fill-available;
            margin: 0 $tablet32px 0 $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            max-width: 100%;
            margin: 0;
        }

        &_title {
            font-family: $MontserratRegular;
            margin-bottom: 3vw;
            font-size: $desctop50px;
            line-height: $desctop60px;

            span {
                color: $brownColor;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet55px;
                line-height: $tablet64px;
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile32px;
                line-height: $mobile42px;
                margin: $mobile10px 0;
            }
        }

        &_btn {
            @include mainBtnStyle;
            @include darkBlueBtnWithWhiteHoverWithBorderAndBackground;
            font-family: $Montserrat700;
            height: $desctop58px;
            margin-bottom: 6.5vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: $desctop225px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 1024px) {
                height: auto;
                border-radius: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: $mobile60px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                margin-bottom: 0;
                border-radius: $mobile20px;
                margin: 4vw 0;
            }
        }
    }

    &__rightSide {
        position: relative;
        width: 45%;
        display: flex;
        align-items: center;
        margin-bottom: 5vw;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            margin: 3vw 4.17vw;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: 5vw;
        }
    }

    &__scrollDown {
        font-family: $MontserratRegular;
        color: $white;
        width: fit-content;
        width: -moz-max-content;
        display: flex;
        align-items: center;
        font-size: 0.8vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            line-height: $tablet14px;
        }

        @media only screen and (max-width: 499px) {
            display: none;
        }

        .firstScreen__scrollDown_mouse {
            animation-name: bounce;
        }

        &_mouse {
            margin-right: 1.2vw;
            width: 1vw;
            height: 1.7vw;
            animation-duration: 1.7s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet20px;
                height: $tablet32px;
                margin-right: $tablet32px;
            }
        }
    }
}

.about {
    padding: $desctop60px 0;

    @media only screen and (max-width: 499px) {
        padding: 8vw 6.7vw;
        background-size: 75%;
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: $desctop25px;
        line-height: $desctop34px;
        color: $textDarkLight;
        text-align: center;
        margin-bottom: $desctop40px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }

        &_firstRow,
        &_secondRow,
        &_thirdRow {
            font-family: $MontserratRegular;
            font-size: $desctop18px;
            line-height: $desctop25px;
            text-align: center;
            color: $textDarkLight;
            max-width: 59vw;
            margin: 0 auto;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                text-align: justify;
                max-width: 84vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile20px;
                max-width: unset;
                text-align: justify;
            }

            a {
                margin-left: $desctop6px;
            }
        }

        &_secondRow {
            margin: $desctop40px auto;
        }
    }
}

.advantages {
    padding: 3vw 7.85vw $desctop60px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px 7.5vw;
    }

    @media only screen and (max-width: 499px) {
        padding: 8vw 6.7vw;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop25px;
        line-height: $desctop34px;
        margin-bottom: $desctop50px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $MontserratRegular;
            color: $textDarkLight;
            height: 9.5vw;
            font-size: $desctop24px;
            line-height: $desctop25px;
            width: 20vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                width: 28vw;

                &:not(:last-child) {
                    margin-right: 2vw;
                }
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                width: 100%;
                height: 100%;
                justify-content: flex-start;

                &:not(:last-child) {
                    margin-bottom: $mobile75px;
                }
            }
        }

        &_img {
            margin-right: 1.8vw;
            width: 4vw;
            height: 4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet55px;
                height: $tablet64px;
            }

            @media only screen and (max-width: 499px) {
                margin-right: $mobile24px;
                width: $mobile55px;
                height: $mobile65px;
            }
        }
    }

    &__titleWrapper {
        &_title,
        &_subTitle {
            font-family: $MontserratRegular;
            color: $textDarkLight;
        }

        &_title {
            font-size: $desctop22px;
            line-height: $desctop25px;
            margin-bottom: $desctop15px;
            text-transform: uppercase;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 1.8vw;
                line-height: 2.7vw;
                margin-bottom: $tablet10px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
                margin-bottom: $mobile10px;
                width: 100%;
            }
        }

        &_subTitle {
            font-size: $desctop16px;
            line-height: $desctop25px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet20px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile20px;
                margin-bottom: 0;
                width: 100%;
            }
        }
    }
}

.supportedAssets {
    padding: 3vw 7.85vw $desctop80px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px 7.5vw;
    }

    @media only screen and (max-width: 499px) {
        padding: 8vw 6.7vw;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop25px;
        line-height: $desctop34px;
        margin-bottom: $desctop50px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0;
        }

        @media only screen and (max-width: 499px) {
            padding: 0;
        }

        &_item {
            width: 25%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;

            @media only screen and (max-width: 499px) {
                width: 50%;
                margin-bottom: $mobile42px;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
                margin-bottom: 5vw;
            }
        }

        &_img {
            width: $desctop75px;
            height: $desctop75px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet70px;
                height: $tablet70px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile75px;
                height: $mobile75px;
            }
        }

        &_subTitle {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop34px;
            text-align: center;
            color: #234A6C;
            margin-top: $desctop10px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.1vw;
                line-height: 3.39vw;
                margin-top: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 4.3vw;
                line-height: 6.7vw;
                margin-top: $mobile10px;
            }
        }
    }
}

.supportedBrowsers {
    padding: 3vw 7.85vw $desctop60px;
    margin-bottom: $desctop60px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px 7.5vw;
    }

    @media only screen and (max-width: 499px) {
        padding: 8vw 6.7vw;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop25px;
        line-height: $desctop34px;
        margin-bottom: $desctop75px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 $desctop225px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0;
        }

        @media only screen and (max-width: 499px) {
            padding: 0;
            flex-wrap: wrap;
        }

        &_item {
            position: relative;

            @media only screen and (max-width: 499px) {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: $mobile42px;
                }
            }
        }

        &_soon,
        &_soonAssets  {
            position: absolute;
            right: 0;
            background-color: #234A6C;
            color: $white;
            padding: $desctop8px $desctop10px;
            border-radius: $desctop5px;
            top: -0.7vw;
            text-transform: uppercase;
            font-family: $MontserratRegular;
            font-size: $desctop12px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: 1.31vw $tablet10px;
                border-radius: 1vw;
                top: -0.7vw;
                font-size: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                padding: 1.31vw $mobile10px;
                border-radius: 1vw;
                top: -0.7vw;
                font-size: $mobile12px;
            }
        }

        &_soon {
            right: -2vw;
        }

        &_soonAssets {
            right: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: 2.3vw;
                right: 1vw;
            }
        }

        &_img {
            width: $desctop75px;
            height: $desctop75px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet70px;
                height: $tablet70px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile75px;
                height: $mobile75px;
            }
        }

        &_subTitle {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop34px;
            text-align: center;
            color: #234A6C;
            margin-top: $desctop10px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.1vw;
                line-height: 3.39vw;
                margin-top: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 4.3vw;
                line-height: 6.7vw;
                margin-top: $mobile10px;
            }
        }
    }
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
