@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixin';

.payouts {
    margin: $desctop30px 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin: 0 0 $tablet30px;
    }

    @media only screen and (max-width: 499px) {
        margin: 0 0 $mobile30px;
    }

    .header {
        display: flex;
        justify-content: space-between;

        &_referralTitle {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop25px;
            color: $brownColor;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }

        &_clipboardWrapper {
            display: flex;
            align-items: center;
            margin: $desctop12px 0 0 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: $tablet12px 0 0 0;
            }

            @media only screen and (max-width: 499px) {
                margin: $mobile12px 0 0 0;
            }
        }

        &_link {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop25px;
            color: $textDarkLight;
            margin: 0 $desctop12px 0 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                margin: 0 $tablet12px 0 0;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                margin: 0 $mobile12px 0 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 75vw;
            }
        }

        &_copyIcon {
            width: $desctop18px;
            height: $desctop18px;
            cursor: pointer;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet18px;
                height: $tablet18px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile20px;
                height: $mobile20px;
            }
        }
    }
}

.table {
    margin-bottom: $desctop20px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: $tablet20px;
    }

    @media only screen and (max-width: 499px) {
        display: none;
    }

    &__row {
        display: flex;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            overflow-x: scroll;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;

        &:first-child {
            .table__column_title,
            .table__column_name,
            .table__column_email {
                padding-left: $desctop30px;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-left: $tablet30px;
                }
            }

        }

        &:last-child {
            .table__column_title {
                padding-right: $desctop30px;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    padding-right: $tablet30px;
                }
            }
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: $desctop12px;
            line-height: $desctop10px;
            color: #828282;
            background: #F7F7F7;
            padding: $desctop18px 0;
            white-space: nowrap;

            &:not(:last-child) {
                padding-right: 1.5vw;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: $tablet18px 0;
                white-space: nowrap;
                padding-right: 3vw;
            }
        }

        &_name,
        &_email,
        &_date,
        &_orderType,
        &_amount,
        &_currencies {
            font-family: $MontserratRegular;
            padding-right: 1.5vw;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: $desctop12px;
            white-space: nowrap;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
            }
        }

        &_nameWrapper,
        &_date,
        &_orderType,
        &_amount,
        &_currencies {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: $desctop60px;
            border-bottom: $desctop1px solid #EFF0F3;
            max-width: 18vw;
            overflow: hidden;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                max-width: 35vw;
                white-space: nowrap;
            }
        }

        &_date,
        &_amount,
        &_currencies {
            color: $textDarkLight;
        }

        &_name {
            color: $textDarkLight;
        }

        &_email {
            color: #BDBDBD;
        }

        &_orderType {
            color: $textDarkLight;
        }
    }
}

.mobileTable {
    display: none;

    @media only screen and (max-width: 499px) {
        display: block;
    }

    &__item {
        width: 97%;
        margin: 0 auto 5.34vw;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        background: $white;
        padding: $mobile20px $mobile24px;
    }

    &__itemFullWidth {
        margin-bottom: $mobile20px;
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: $mobile12px;
        color: #828282;
    }

    &__value {
        font-family: $MontserratRegular;
        font-size: $mobile16px;
        line-height: $mobile24px;
        color: $textDarkLight;
    }

    &__nameWrapper {
        &_value {
            font-family: $MontserratRegular;
            font-size: $mobile16px;
            line-height: $mobile24px;
            color: $textDarkLight;
        }

        &_email {
            font-family: $MontserratRegular;
            font-size: $mobile12px;
            color: #BDBDBD;
        }
    }

    &__halfWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: $mobile20px;
    }

    &__itemHalfWidth {
        width: 45%;
    }
}

.noHistory {
    text-align: center;
    font-family: $MontserratRegular;
    font-size: $desctop16px;
    line-height: $desctop25px;
    color: $textDarkLight;
    margin-top: $desctop50px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
        line-height: $tablet26px;
        margin-top: $tablet30px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile25px;
        margin-top: 0;
    }
}
