@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.35vw 7.85vw;
    position: relative;
    z-index: 9999;
    background: linear-gradient(180deg, #191C2D -54.09%, #2B73A5 175.45%);
    box-shadow: 0px $desctop5px $desctop20px rgba(0, 0, 0, 0.25);

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet18px 4.4vw $tablet18px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
        background-color: $bodyColor;
    }

    &__login,
    &__signUp {
        @include mainBtnStyle;
        height: 2.5vw;
        white-space: nowrap;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: auto;
            margin-right: $tablet24px !important;
        }

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__login {
        @include btnWithoutBorder;
        font-family: $MontserratRegular;
        color: $white;
        display: flex;
        align-items: center;
        @include linkHover;
        cursor: pointer;
        height: $desctop18px;
        padding: 0;
        margin-right: $desctop20px;
        font-size: $desctop14px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 1.5vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: 4vw;
            line-height: 4vw;
            height: initial;
            margin-right: 8vw;
        }



        &:before {
            bottom: 1px;
        }

        &:hover {
            color: $brownColor;
        }
    }

    &__signUp {
        @include brownBtnWithOutBackground;
        margin-right: 2.65vw;
        font-family: $Montserrat700;
        font-size: $desctop14px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet152px;
            height: $tablet48px;
            font-size: 1.5vw;
        }
    }

    &__logo {
        display: flex;
        align-items: center;

        img {
            width: 5.5vw;
            height: 3.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 11.12vw;
                height: 5.65vw;
            }

            @media only screen and (max-width: 499px) {
                width: 25vw;
                height: 11vw;
            }
        }
    }

    &__leftSide {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_locationWrapper {
            display: flex;
            align-items: center;
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            color: $white;
            margin-left: 3.85vw;

            @media only screen and (max-width: 1024px) {
                display: none;
            }
        }

        &_location {
            margin-right: 1.4vw;
            width: 1.05vw;
            height: 1.2vw;
        }
    }

    &__rightSide {
        display: flex;
        align-items: center;
        margin-bottom: 0.16vw;

        &_userComponent {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 499px) {
                display: none;
                border-top: 1px solid $borderColor;
                border-bottom: 1px solid $borderColor;
                margin-bottom: $mobile42px;
            }
        }

        &_pesonalAreaLink {
            display: flex;
            align-items: center;
            transition: all 0.25s;

            @media only screen and (max-width: 499px) {
                flex-direction: row-reverse;
                padding: 4vw 0 4vw 6.7vw;
            }

            &:hover>* {
                color: $greenLight;
                transition: all 0.25s;
            }
        }

        &_name {
            text-align: center;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            color: $white;
            margin: 0 0.7vw 0 1.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
                margin: 0 $tablet24px 0 $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: 1px;
                margin: 0 5vw 0 2.7vw;
                color: $brownColor
            }
        }

        &_newOrder {
            display: flex;
            align-items: center;
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            color: $white;
            margin-right: 3.3vw;
            cursor: pointer;
            transition: all 0.25s;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
            }

            @media only screen and (max-width: 499px) {
                display: none;
            }

            &:hover {
                color: $greenLight;

                svg g {
                    fill: $greenLight;
                    transition: all 0.25s;
                }
            }
        }

        &_newOrderIcon {
            margin-right: 1.4vw;
            height: 1.1vw;
            width: 1.1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet16px;
                height: $tablet16px;
            }
        }

        &_bell {
            height: 1.1vw;
            width: 1.1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet16px;
                height: $tablet16px;
            }
        }
    }

    &__notificationWrapper {
        position: relative;
        cursor: pointer;
        padding: 0 1vw;
        display: flex;
        align-items: center;

        &:hover {
            path {
                stroke: $greenLight;
                transition: all 0.25s;
            }
        }
    }

    &__noNotification {
        display: flex;
        align-items: center;
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        color: #505761;
        justify-content: center;
        padding: 2vw 0;
    }

    &__notification {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 1vw;
        color: $textDarkLight;
        width: 24vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            font-weight: 900;
            color: $black;
            font-size: 0.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet18px;
            }
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet18px;
            width: 100%;
        }
    }

    &__notificationContent {
        position: absolute;
        right: -0.8vw;
        width: 28vw;
        flex-direction: column;
        background: $white;
        box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1);
        border-radius: 0.1vw;
        border: none;
        display: flex;
        font-family: $MontserratRegular;
        top: 3.6vw;
        font-size: 0.7vw;
        letter-spacing: 0.05vw;
        color: $textDarkLight;
        transition: all 0.25s;
        max-height: 17vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 49vw;
            right: -0.4vw;
            top: 5.7vw;
        }

        &_item {
            padding: 1.6vw 2vw;
            display: block;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: $tablet12px $tablet30px;
            }

            &:first-child {
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: 0;
                    top: 0vw;
                    box-sizing: border-box;
                    border: 1em solid $red;
                    border-color: transparent transparent $white $white;
                    transform-origin: 0 0;
                    transform: rotate(-225deg);
                    box-shadow: -6.5px 5px 10px -3.95px rgba(0, 0, 0, 0.1);
                }

                &:hover {
                    &::after {
                        border: 1em solid $inputColor;
                        border-color: transparent transparent $inputColor $inputColor;
                    }
                }
            }

            &:hover {
                background: $inputColor;
            }
        }

        &_overflowY {
            overflow-y: scroll;
        }
    }

    &__notificationTime {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        margin-top: 0.7vw;
        color: #92969c;

        @media only screen and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet10px;
            margin-top: $tablet10px;
        }
    }

    &__nav {
        margin-left: 6.55vw;

        &_list {
            display: flex;
            margin: 0 2vw 0 0 ;

            @media only screen and (max-width: 1024px) {
                display: none;
            }

            li {
                display: flex;
                align-items: center;
                @include linkHover;
                cursor: pointer;
                height: $desctop18px;

                &:before {
                    bottom: 1px;
                }

                &:not(:last-child) {
                    margin-right: 2.65vw;
                }

                &:hover>* {
                    color: $brownColor;
                    transition: all 0.25s;
                    overflow: visible;
                }

                a {
                    font-family: $MontserratRegular;
                    font-size: $desctop14px;
                    letter-spacing: $desctop1px;
                    text-transform: uppercase;
                    color: $white;
                    white-space: nowrap;
                }
            }
        }
    }

    &__dropDownMenu {
        position: relative;
        cursor: pointer;
        color: $blueDark;
        font-size: 1.7vw;
        margin: 0 3.3vw 0 0;
        padding: 0.6vw;
        display: flex;
        align-items: center;
        cursor: pointer;

        &_arrow {
            width: 0.5vw;
            height: 0.4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 1.5vw;
                height: 1.4vw;
                margin-left: 1vw;
            }

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
        }

        &_select {
            position: absolute;
            right: -1vw;
            width: 14.4vw;
            flex-direction: column;
            background: $white;
            box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1);
            border-radius: 0.1vw;
            border: none;
            display: flex;
            font-family: $MontserratRegular;
            top: 3.8vw;
            font-size: 0.7vw;
            letter-spacing: 0.05vw;
            color: $textDarkLight;
            transition: all 0.25s;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: 6.7vw;
                width: 27vw;
                font-size: 1.2vw;
                right: -2.7vw;
            }
        }

        &_row {
            padding: 0.85vw 2vw;
            display: flex;
            align-items: center;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: $tablet16px $tablet30px;
            }

            &:first-child {
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: 0;
                    top: 0vw;
                    box-sizing: border-box;
                    border: 1em solid $white;
                    border-color: transparent transparent $white $white;
                    transform-origin: 0 0;
                    transform: rotate(-225deg);
                    box-shadow: -6.5px 5px 10px -3.95px rgba(0, 0, 0, 0.1);
                }

                &:hover {
                    &::after {
                        border: 1em solid $inputColor;
                        border-color: transparent transparent $inputColor $inputColor;
                    }
                }
            }

            &:hover {
                background: $inputColor;
            }
        }

        &_icon {
            margin-right: 1.1vw;
            width: 1.15vw;
            height: 1.15vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet16px;
                height: $tablet16px;
            }
        }

        &_text {
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
            }
        }
    }

    &__personalArea {
        &_logo {
            width: 13.7vw;
            background: #f3f7fd;
            padding: 1.35vw 0;
            background: linear-gradient(180deg, #191C2D -54.09%, #2B73A5 175.45%);

            @media only screen and (max-width: 1024px) {
                width: fit-content;
                width: -moz-max-content;
                background: transparent;
                padding: 0;
            }

            img {
                width: 5.5vw;
                height: 3.5vw;
                margin-left: 2.9vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    width: $tablet70px;
                    height: $tablet28px;
                    margin-left: 0;
                }

                @media only screen and (max-width: 499px) {
                    width: 25vw;
                    height: 11vw;
                    margin-left: 0;
                }
            }
        }
    }

    &__mobileMenu {
        display: none;

        @media only screen and (max-width: 1024px) {
            display: flex;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet40px;
            height: $tablet40px;

            svg {
                width: 100%;
            }
        }

        @media only screen and (max-width: 499px) {
            width: $mobile42px;
            height: $mobile42px;

            svg {
                width: 100%;
            }
        }

        &_links {
            @media only screen and (max-width: 499px) {
                padding: 0 $mobile25px;

                li {
                    padding-bottom: $mobile42px;

                    a {
                        font-family: $MontserratRegular;
                        font-size: $mobile12px;
                        line-height: $mobile12px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: $textDarkLight;
                        padding: 0;
                    }
                }
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: $tablet40px 4.7vw 0 4.7vw;
                border-top: 1px solid #efefef;

                li {
                    padding-bottom: $tablet40px;

                    a {
                        font-family: $MontserratRegular;
                        font-size: $tablet12px;
                        line-height: $tablet12px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: $textDarkLight;
                        padding: 0;
                    }
                }
            }
        }

        &_login,
        &_signUp {
            @media only screen and (min-width: 500px) {
                display: none;
            }

            @media only screen and (max-width: 499px) {
                margin-left: $mobile25px;
                @include mainBtnStyle;
                min-height: $mobile42px;
                font-size: $mobile12px;
                font-family: $MontserratRegular;
                max-width: 51vw;
            }
        }

        &_login {
            @include btnWithoutBorder;
            text-align: left;
            padding-left: 0;
            padding-bottom: $mobile30px;
            color: $greenLight;
            font-weight: 700;
        }

        &_signUp {
            @include greenBtnWithBorderAndBackground;
            margin-bottom: 30vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                border-radius: $tablet20px;
            }
        }

        &_footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #efefef;
            margin-top: auto;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: $tablet24px 4.7vw $tablet24px 4.7vw;
                width: 50vw;
                margin-left: auto;
                background-color: #f8faff;
            }

            @media only screen and (max-width: 499px) {
                padding: $mobile25px $mobile42px $mobile25px $mobile25px;
                background-color: $bodyColor;
            }
        }

        &_copyright {
            font-family: $MontserratRegular;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
            }
        }

        &_symbol {
            margin: 0 5px;
        }

        &_p2p {
            font-family: $Montserrat700;
        }
    }
}

.asideMenu {
    aside {
        display: flex;
    }
}

.mobileUserComponent {

    .header__rightSide_userComponent {
        display: flex;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            display: none;
        }
    }
}
