@import '../../assets/styles/variables';

#workWith,
#orders {
    .slickSlider {
        .slickSlider__arrowPrev,
        .slickSlider__arrowNext {
            position: absolute;
            bottom: -4.5vw;

            @media only screen and (max-width: 499px) {
                bottom: -15.5vw;
            }
        }

        .slickSlider__arrowPrev {
            right: 4.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: 7.5vw;
            }

            @media only screen and (max-width: 499px) {
                right: 14.5vw;
            }
        }

        .slickSlider__arrowNext {
            right: 0vw;
        }

        .slick-slide {
            height: 10vw;
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin: 0.3vw 2vw 0.3vw 0;
            transition: all 0.25s;
            cursor: pointer;

            @media only screen and (min-width: 1025px) {
                width: 12vw !important;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet176px;
                margin: 0.3vw 0;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile150px;
                margin: 0.3vw 0;
            }

            &:hover {
                background: $white;
                box-shadow: 0px 0px $desctop14px rgba(0, 0, 0, 0.1);
                border-radius: $desctop12px;
                transition: all 0.25s;
            }
        }

        .slick-current {
            background: $white;
            transform: translateX($desctop15px);
            transition: all 0.25s;
            box-shadow: 0px 0px $desctop14px rgba(0, 0, 0, 0.1);
            border-radius: $desctop12px;

            @media only screen and (max-width: 499px) {
                transform: translateX(0);
            }
        }
    }

    .slick-list {
        height: 10.6vw;

        @media only screen and (max-width: 1024px) {
            height: auto;
        }
    }
}
