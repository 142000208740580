@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixin';

.structure {
    margin: $desctop30px 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin: 0;
    }

    @media only screen and (max-width: 499px) {
        margin: 0 0 $mobile30px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop20px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
        }

        @media only screen  and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        &__linkWrapper {
            margin-right: 3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                margin-bottom: $tablet30px;
            }
        }

        &_referralTitle {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop25px;
            color: $brownColor;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }

        &_clipboardWrapper {
            display: flex;
            align-items: center;
            margin: $desctop12px 0 0 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: $tablet12px 0 0 0;
            }

            @media only screen and (max-width: 499px) {
                margin: $mobile12px 0 0 0;
            }
        }

        &_link {
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            line-height: $desctop25px;
            color: $textDarkLight;
            margin: 0 $desctop12px 0 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                margin: 0 $tablet12px 0 0;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                margin: 0 $mobile12px 0 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 75vw;
            }
        }

        &_copyIcon {
            width: $desctop18px;
            height: $desctop18px;
            cursor: pointer;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet18px;
                height: $tablet18px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile20px;
                height: $mobile20px;
            }
        }
    }
}

.search {

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 100%;
        margin-bottom: $tablet20px;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        margin: $mobile20px 0;
    }

    &_label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: $desctop10px;
        line-height: $desctop10px;
        padding-top: $desctop5px;
        margin-bottom: 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            margin-bottom: $mobile10px;
        }
    }

    &_input {
        width: 25vw;
        height: 3.9vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding: 0 4.7vw 0 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet24px;
            padding-left: $tablet10px;
            width: 100%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding: 0 19.2vw 0 $mobile10px;
            width: 100%;
            height: $mobile60px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.8vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &_inputWrapper {
        position: relative;
    }

    &_searchImg,
    &_cancelImg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
    }

    &_searchImg {
        right: $desctop10px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            right: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            right: $mobile10px;
        }

        img {
            height: $desctop25px;
            width: $desctop25px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: 3vw;
                width: 3vw;
            }

            @media only screen and (max-width: 499px) {
                height: 7vw;
                width: 7vw;
            }
        }
    }

    &_cancelImg {
        right: $desctop40px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            right: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            right: $mobile42px;
        }

        img {
            height: $desctop22px;
            width: $desctop22px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: 3vw;
                width: 3vw;
            }

            @media only screen and (max-width: 499px) {
                height: 7vw;
                width: 7vw;
            }
        }
    }
}

.becomeReferral {
    display: flex;
    align-items: center;
    margin: $desctop40px 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin: $tablet40px 0;
    }

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
    }

    &__inputWrapperStyle {
        width: fit-content;
        width: -moz-max-content;
        display: block;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 50%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__input {
        width: 25vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding: 0 4vw 0 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet24px;
            padding-left: $tablet10px;
            margin-top: $tablet10px;
            width: 100%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding-left: $mobile10px;
            margin-top: $mobile10px;
            width: 100%;
            height: $mobile60px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.8vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }

    &__button {
        @include mainBtnStyle;
        @include darkBlueBtnWithWhiteHoverWithBorderAndBackground;
        font-family: $MontserratRegular;
        width: fit-content;
        width: -moz-max-content;
        min-width: 14vw;
        height: $desctop40px;
        font-size: $desctop16px;
        line-height: $desctop12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.6vw 0 0 $desctop15px;
        border-radius: $desctop30px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 2.6vw 0 0 $tablet16px;
            border-radius: $tablet20px;
            font-size: $tablet16px;
            line-height: $tablet12px;
            padding: 0;
            min-width: 30vw;
            height: 6vw;
        }

        @media only screen and (max-width: 499px) {
            min-width: 50%;
            height: $mobile42px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin: $mobile20px 0 0 0;
            border-radius: $mobile20px;
        }
    }
}

.comission {
    margin-top: $desctop50px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet50px;
    }

    &__wrapper {
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: $desctop24px;
        text-align: center;
        line-height: $desctop34px;
        color: $textDarkLight;
        margin-bottom: $desctop50px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
            margin-bottom: 7vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin: $mobile55px 0;
        }
    }

    &__item {
        background: $white;
        box-shadow: 0px 0px $desctop10px rgba(35, 74, 108, 0.1);
        border-radius: $desctop12px;
        padding: $desctop14px;
        position: relative;
        min-height: 6vw;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            box-shadow: 0px 0px 1.31vw rgba(35, 74, 108, 0.1);
            width: 15.5vw;
            min-height: 9.2vw;
            height: fit-content;
        }

        @media only screen and (max-width: 499px) {
            width: 44vw;
            height: 27vw;
            margin: 0 0 $mobile34px 0;
            padding: $mobile12px;
        }

        &:not(:last-child) {
            margin-right: $desctop22px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: 0 4.55vw $tablet40px 0;
            }
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: $desctop8px;
            color: $brownColor;
            margin-bottom: $desctop8px;
            text-align: center;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                margin: 3vw 0;
            }
        }

        &_text {
            font-family: $MontserratRegular;
            font-size: $desctop10px;
            line-height: $desctop14px;
            text-align: center;
            color: #4F4F4F;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet14px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile16px;
            }
        }

        &_percent {
            position: absolute;
            top: -1.4vw;
            right: 50%;
            transform: translateX(50%);
            font-family: $Montserrat700;
            font-size: $desctop32px;
            line-height: $desctop36px;
            color: $brownColor;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: -3.4vw;
                font-size: $tablet32px;
                line-height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                top: -4.4vw;
                font-size: $mobile32px;
                line-height: $mobile34px;
            }
        }
    }
}

.noHistory {
    text-align: center;
    font-family: $MontserratRegular;
    font-size: $desctop16px;
    line-height: $desctop25px;
    color: $textDarkLight;
    margin-top: $desctop50px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
        line-height: $tablet26px;
        margin-top: $tablet30px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile25px;
        margin: 20vw 0;
    }
}
