@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw 7.85vw 7vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px $tablet88px $tablet80px;
        flex-direction: column;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile25px $mobile60px;
        flex-direction: column;
    }

    &__leftSide {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop24px;
        line-height: $desctop34px;
        margin-bottom: $desctop30px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__rightSide {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        &_button {
            @include mainBtnStyle;
            @include darkBlueBtnWithBorderAndBackground;
            font-family: $Montserrat700;
            width: fit-content;
            width: -moz-max-content;
            min-width: $desctop148px;
            height: $desctop58px;
            margin-top: 3vw;
            font-size: $desctop16px;
            line-height: $desctop12px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: auto;
                padding: $tablet24px $tablet55px;
                margin-top: $tablet40px;
                border-radius: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: $mobile60px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                margin: $mobile42px 0 0 0;
                border-radius: $mobile20px;
            }
        }
    }
}

.sendQuestion {
    &__form {
        width: 45.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__inputError {
        color: $red;
        padding-top: 0.4vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: 100%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            padding-top: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            padding-top: $mobile10px;
        }
    }

    &__inputWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile42px;
        }

        &_item {
            @media only screen and (max-width: 1024px) {
                width: 45%;
            }
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }

    &__input,
    &__textarea {
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet26px;
            padding-left: $tablet12px;
            margin-top: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding-left: $mobile10px;
            margin-top: $mobile10px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 1.4vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &__input {
        width: 21.25vw;
        height: 3.9vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
        }
    }

    &__textarea {
        min-height: $desctop178px;
        padding-top: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding-top: $tablet12px;
            min-height: 25vw;
        }

        @media only screen and (max-width: 499px) {
            padding-top: $mobile12px;
            min-height: 45vw;
        }
    }
}
