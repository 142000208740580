@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.aboutUs {
    display: flex;
    justify-content: space-between;
    padding: 0 7.85vw;
    height: calc(100vh - 9vw);
    min-height: calc(100vh - 9vw);

    @media only screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding: $tablet20px $tablet55px $tablet30px $tablet55px;
    }

    @media only screen and (max-width: 499px) {
        min-height: auto;
        height: auto;
        padding: $mobile20px $mobile25px $mobile30px;
    }

    &__leftSide {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        margin-top: 7.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            max-width: -webkit-fill-available;
            margin: $tablet40px $tablet32px 0 $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            max-width: 100%;
        }

        &_textWraper {
            margin-bottom: auto;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 2.8vw;
            line-height: 3.35vw;
            margin-bottom: 1.2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet55px;
                line-height: $tablet64px;
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile32px;
                line-height: $mobile42px;
                margin: $mobile10px 0;
            }
        }

        &_subtitle {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: 1.1vw;
            line-height: 1.9vw;
            margin-bottom: 4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile32px;
                margin-bottom: 0;
            }
        }

        &_btn {
            @include mainBtnStyle;
            @include greenBtnWithBorderAndBackground;
            font-family: $MontserratRegular;
            height: 3.15vw;
            margin-bottom: 6.5vw;
            width: fit-content;
            width: -moz-max-content;

            @media only screen and (max-width: 1024px) {
                height: auto;
            }
        }
    }

    &__rightSide {
        margin-top: 4.8vw;

        @media only screen and (max-width: 499px) {
            margin-top: 0;
        }

        &_illustration {
            width: 50vw;
            height: 35vw;
            transition: 0.5s;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: 54.46vw;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: 58vw;
            }
        }
    }

    &__scrollDown {
        font-family: $MontserratRegular;
        color: $black;
        width: fit-content;
        width: -moz-max-content;
        display: flex;
        align-items: center;
        font-size: 0.8vw;
        cursor: pointer;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            line-height: $tablet14px;
        }

        @media only screen and (max-width: 499px) {
            display: none;
        }

        .firstScreen__scrollDown_mouse {
            animation-name: bounce;
        }

        &_mouse {
            margin-right: 1.2vw;
            width: 1vw;
            height: 1.7vw;
            animation-duration: 1.7s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet20px;
                height: $tablet32px;
                margin-right: $tablet32px;
            }
        }
    }
}

.team {
    padding: 3vw 7.85vw 7vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet60px $tablet88px 11.72vw $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px 31vw;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        margin-bottom: 4.5vw;
        font-size: 1.55vw;
        line-height: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet70px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile32px;
            line-height: $mobile42px;
            margin: 0 0 $mobile20px;
        }
    }

    &__wrapper {
        &_item {
            width: 15vw !important;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 22vw !important;
            }

            @media only screen and (max-width: 499px) {
                width: 90% !important;
            }
        }
    }

    &__img {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 7.23vw;
            height: 7.23vw;
            margin-bottom: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            width: $mobile75px;
            height: $mobile75px;
            margin-bottom: $mobile16px;
        }
    }

    &__link,
    &__name {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.9vw;
        color: $blueDark;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet26px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
        }
    }

    &__position {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
        margin: 2vw 0 1.3vw 0;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet26px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
        }
    }

    &__description {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 2.4vw;
        color: $greyLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile34px;
        }
    }
}

.media {
    display: flex;
    justify-content: space-between;
    padding: 3vw 7.85vw 6vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet70px $tablet88px 11.72vw;
        flex-direction: column;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile30px $mobile25px 31vw;
    }

    &__leftSide {
        max-width: 20vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            max-width: 100%;
            margin-bottom: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            max-width: 100%;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 1.55vw;
            line-height: 2vw;
            margin-bottom: 1.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                max-width: 100%;
                font-size: $tablet24px;
                line-height: $tablet36px;
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile32px;
                line-height: $mobile42px;
                margin: 0 0 $mobile20px;
            }
        }

        &_subTitle {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: 1.1vw;
            line-height: 1.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile34px;
                margin-bottom: $mobile42px;
            }
        }
    }

    &__rightSide {
        width: 52vw;

        @media only screen and (max-width: 1024px) {
            width: 100%;
        }

        &_sliderImg {
            width: 10vw;
            height: 10vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 13.2vw;
                height: 6.55vw;
            }

            @media only screen and (max-width: 499px) {
                width: 36vw;
                height: 18vw;
            }
        }

        &_link {
            width: 13vw;
            height: 9vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.3vw 0;
            transition: all 0.25s;
            padding: 0 10.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: 0;
                margin: 0;
                width: 25vw;
            }

            @media only screen and (max-width: 499px) {
                width: 36vw;
                height: 18vw;
                padding: 0;
                margin: 0;
            }
        }
    }
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
