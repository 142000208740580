@import '../../assets/styles/variables';

.animationLogo,
.animationLocation,
.animationNewOrder,
.animationPersonalAreaLogo,
.animationBell,
.animationPesonalAreaLink,
.animationSelectLang,
.animationNav ul li,
.animationButtons button,
.animationButtons div {
    transform: translateY(-100px);
    transition: transform 0.5s;

    @media only screen and (max-width: 1024px) {
        transform: translateY(0);
    }
}

.domLoaded .animationLogo,
.domLoaded .animationPersonalAreaLogo,
.domLoaded .animationNewOrder,
.domLoaded .animationLocation,
.domLoaded .animationBell,
.domLoaded .animationPesonalAreaLink,
.domLoaded .animationSelectLang,
.domLoaded .animationNav ul li,
.domLoaded .animationButtons,
.domLoaded .animationButtons button,
.domLoaded .animationButtons div {
    transform: translateY(0);
}

.animationLogo {
    transition-delay: 0.05s;
}

.animationNav ul li {
    &:nth-child(1) {
        transition-delay: 0.15s;
    }

    &:nth-child(2) {
        transition-delay: 0.3s;
    }

    &:nth-child(3) {
        transition-delay: 0.45s;
    }

    &:nth-child(4) {
        transition-delay: 0.6s;
    }

    &:nth-child(5) {
        transition-delay: 0.75s;
    }

    &:nth-child(6) {
        transition-delay: 0.9s;
    }
}

.animationButtons {
    button {
        &:nth-child(1) {
            transition-delay: 0.9s;
        }

        &:nth-child(2) {
            transition: transform 0.5s ease-in-out 1.05s;
        }
    }

    div {
        transition-delay: 1.2s;
    }
}

.animationPersonalAreaLogo {
    transition-delay: 0.05s;
    display: flex;
}

.animationLocation {
    transition-delay: 0.2s;
    display: flex;
}

.animationNewOrder {
    transition-delay: 0.35s;
    display: flex;
}

.animationBell {
    transition-delay: 0.9s;

    .ant-badge sup {
        border-radius: 50%;
        width: 1.4vw;
        height: 1.4vw;
        min-width: 1vw;
        padding: 0 0.1vw;
        font-size: 0.64vw;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -0.1vw;
        right: -0.15vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 2.8vw;
            height: 2.8vw;
            min-width: 2.4vw;
            font-size: 1.1vw;
            top: -0.5vw;
            right: -0.4vw;
        }
    }

    @media only screen and (max-width: 499px) {
        display: none;
    }
}

.animationPesonalAreaLink {
    transition-delay: 1.05s;
}

.animationSelectLang {
    transition-delay: 1.2s;
    display: flex;
    align-items: center;
}

.animationButtons {
    display: flex;
    align-items: center;
}

.headerUserAvatar {
    background: linear-gradient(140.34deg, #007aff 14.23%, #7a65ff 87.98%);
    width: 2.4vw;
    height: 2.4vw;
    border-radius: 50%;
    font-family: $MontserratRegular;
    font-size: 1vw;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05vw;
    object-fit: cover;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: $tablet36px;
        width: $tablet36px;
        font-size: $tablet12px;
        line-height: $tablet12px;
        letter-spacing: 1px;
    }

    @media only screen and (max-width: 499px) {
        height: 9.6vw;
        width: 9.6vw;
        font-size: $mobile12px;
        line-height: $mobile12px;
        letter-spacing: 1px;
    }
}

.header-personal-area {
    background-color: $cabinerBackColor;

    header {
        padding: 0 7.85vw 0 0;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet18px 4.4vw $tablet18px $tablet88px;
            background-color: #f8faff;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile30px $mobile25px;
        }
    }
}

.mobileMenu {
    &__selectLanguage {
        .flag-select .flag-option {
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
            }
        }

        .flag-select.change-language-flags .flag-options {
            right: 0;
            overflow: auto;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: -25vw;
                min-height: 23vw;
                width: 20vw;
            }

            @media only screen and (max-width: 499px) {
                top: -50vw;
                height: 45vw;
                width: 40vw;
                overflow-y: auto;
                overflow-x: hidden;
                flex-direction: row;
                right: -5.3vw;
            }
        }

        .flag-select img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            object-fit: cover;
            top: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet36px;
                height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile34px;
                height: $mobile34px;
                min-width: $mobile34px;
                min-height: $mobile34px;
            }
        }

        .flag-select .country-flag .country-label {
            font-family: $MontserratRegular;
            letter-spacing: 1px;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: 0 $tablet40px 0 $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                padding: 0 $mobile42px 0 $mobile20px;
            }
        }

        .flag-select.change-language-flags .arrow-down {
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
            }
        }
    }
}

// humburger menu

.ham {
    cursor: pointer;
    transition: transform 400ms;
    user-select: none;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #fff;
    stroke-width: 5.5;
    stroke-linecap: round;
}

.ham8 .top {
    stroke-dasharray: 40 160;
}

.ham8 .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
}

.ham8 .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
    stroke-dashoffset: -64px;
}

.ham8.active .middle {
    transform: rotate(90deg);
}

.ham8.active .bottom {
    stroke-dashoffset: -64px;
}

// humburger menu

.reactModal {
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 4vw;
    transform: translate(-50%, -50%);
    overflow-x: hidden;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 75vw;
        height: 65vw;
        padding: 10vw;
    }

    @media only screen and (max-width: 499px) {
        width: 100vw;
        padding: 15vw;
    }
}

.reactModal,
.modalRegistr {
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 4vw;
    transform: translate(-50%, -50%);
    overflow-x: hidden;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 75vw;
        height: fit-content;
        height: -moz-max-content;
        padding: 10vw;
    }

    @media only screen and (max-width: 499px) {
        position: static;
        transform: translate(0%, 0%);
        padding: inherit;
        // width: 100vw;
        // padding: 15vw;
        // overflow-y: scroll;
    }
}

.reactModal {
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 75vw;
        height: fit-content;
        height: -moz-max-content;
        padding: 10vw;
    }

    // @media only screen and (max-width: 499px) {
    //     width: 100vw;
    //     padding: 15vw;
    //     overflow-y: scroll;
    // }
}

.modalRegistr {
    overflow: hidden;

    .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999;

        &-dot-spin {
            animation: antRotate 1.8s infinite linear;
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 75vw;
        height: 100vw;
        padding: 10vw;
    }

    @media only screen and (max-width: 499px) {
        // width: 100vw;
        // margin: 10vw 0;
        // height: 100vh;
        // position: static;
        // transform: translate(0, 0);
    }
}
