@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.cards {

    &__addNewCard {
        display: flex;
        flex-direction: column;
        transition: all 0.25s;
        padding: 2.1vw 4.2vw;
        margin-bottom: 0.5vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 2.1vw 0;
        }

        @media only screen and (max-width: 499px) {
            padding: 11.1vw 0;
        }

        &_cardWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: 499px) {
               flex-direction: column;
            }
        }

        &:hover {
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                background: transparent;
                box-shadow: none;
            }
        }

        &_text {
            font-family: $MontserratRegular;
            font-size: 16px;
            line-height: 16px;
            padding-bottom: 0.1vw;
            border-bottom: 0.1vw solid $blueDark;
            color: $blueDark;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding-bottom: 0.2vw;
            }

            @media only screen and (max-width: 499px) {
                margin: 8vw 0 4vw 0;
                padding-bottom: 0.7vw;
            }
        }

        &_opened {
            max-height: 25vw;
            top: 0.1vw;
            transition: max-height 400ms;
            opacity: 1;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                max-height: 30vw;
            }

            @media only screen and (max-width: 499px) {
                max-height: 100vw;
                padding: 0 5vw;
            }
        }

        &_closed {
            top: 0;
            max-height: 0.1vw;
            transition: max-height 400ms;
            overflow: hidden;
            opacity: 0;
        }
    }

    &__inputWrapper {
        display: flex;
        margin-top: 5vw;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 5vw;
        padding-right: 20.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding-right: 4.1vw;
        }

        @media only screen and (max-width: 499px) {
            padding: 0;
            width: 100%;
        }

        &_goBack,
        &_done {
            @include mainBtnStyle;
            font-family: $MontserratRegular;
            height: 3.4vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 12.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: 6vw;
                width: 20vw;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 45%;
                font-size: $mobile12px;
                min-width: unset;
            }
        }

        &_goBack {
            @include greyBtnWithBorderAndBackground;
        }

        &_done {
            @include blueBtnWithBorderAndBackground;
        }
    }

    &__opened {
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            background: transparent;
            box-shadow: none;
        }
    }
}
