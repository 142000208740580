@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.passwordRecovery {
    margin: auto;
    width: 35vw;
    padding: 4vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 10vw;
        width: 75vw;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        padding: 5vw 15vw 15vw;
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.55vw;
        color: $textDarkLight;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__subTitle {
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet20px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile20px;
            margin-bottom: $mobile20px;
        }
    }

    &__inputWrapper {
        margin-bottom: 1.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }

        &_invalid {
            color: $red;
            padding-top: 0.4vw;
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            width: 100%;
            position: absolute;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }

    &__input {
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet24px;
            padding-left: $tablet10px;
            margin-top: $tablet10px;
            width: 100%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding-left: $mobile10px;
            margin-top: $mobile10px;
            width: 100%;
            height: $mobile60px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 1.4vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: space-around;
    }

    &__button {
        @include blueBtnWithBorderAndBackground;
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.9vw;
        width: 50%;
        margin-top: 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            font-size: $tablet12px;
            line-height: $tablet12px;
            margin-top: $tablet30px;
            letter-spacing: 1px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-top: $mobile20px;
            letter-spacing: 1px;
            width: 100%;
        }
    }
}
