@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.registration {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        align-items: flex-start;
    }

    @media only screen and (max-width: 499px) {
        padding: 15vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    form {
        @media only screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.55vw;
        color: $textDarkLight;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__subTitle,
    &__checkEmail {
        width: 100%;
        font-size: 0.8vw;
        font-family: $MontserratRegular;
        color: $textDarkLight;
    }

    &__subTitle {
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet20px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile20px;
            margin-bottom: $mobile20px;
        }
    }

    &__checkEmail {
        margin-top: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet20px;
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile20px;
            margin-top: $mobile20px;
        }
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            top: $tablet24px;
            right: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            top: $mobile25px;
            right: $mobile25px;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }

    &__inputWrapper {
        margin-bottom: 1.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }

        &_invalid {
            color: $red;
            padding-top: 0.4vw;
            font-family: $MontserratRegular;
            font-size: 0.8vw;
            width: 100%;
            max-width: 30vw;
            text-align: justify;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                max-width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                max-width: 100%;
            }
        }
    }

    &__input {
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet24px;
            padding-left: $tablet10px;
            margin-top: $tablet10px;
            width: 100%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding-left: $mobile10px;
            margin-top: $mobile10px;
            width: 100%;
            height: $mobile60px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.8vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &__checkBoxWrapper {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__checkbox {
        @media only screen and (max-width: 499px) {
            margin-bottom: 5vw;
        }

        &:first-child {
            margin-right: 1vw;
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: 0.75vw;
            color: $greyDark;
            margin-left: 0.5vw;
            padding-left: 0 !important;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                margin-left: 2vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                margin-left: 4vw;
            }
        }

        &_wrapper {
            display: flex;
            align-items: center;
        }
    }

    &__button,
    &__switchLoginButton {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.9vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 11.7vw;
        margin-top: 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            font-size: $tablet12px;
            line-height: $tablet12px;
            margin-top: $tablet30px;
            letter-spacing: 1px;
            width: 45%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-top: 0;
            letter-spacing: 1px;
            width: 100%;
        }
    }

    &__button {
        @include blueBtnWithBorderAndBackground;

        @media only screen and (max-width: 499px) {
            margin-bottom: 4vw;
        }
    }

    &__switchLoginButton {
        @include blueBtnWithOutBackground;
    }

    &__buttonWrapper {
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }
    }

    &__termOfUseModal {
        color: $blueDark;
        cursor: pointer;
        margin-left: 0.1vw;
    }

    &__termOfUseModalWrapper {
        height: 100%;
        padding: 4.2vw 6.9vw;
        overflow: scroll;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet30px $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile30px $mobile25px;
        }

        &_section {
            &:not(:last-child) {
                margin-bottom: 2vw;
            }
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 1.25vw;
            margin-bottom: 1.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet24px;
                line-height: $tablet32px;
                margin-bottom: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
                line-height: $mobile34px;
                margin-bottom: $mobile16px;
            }
        }

        &_subTitle {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: 0.8vw;
            line-height: 1.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet32px;
                margin-bottom: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile34px;
                margin-bottom: $mobile60px;
            }
        }
    }
}
