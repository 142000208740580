@import '../../../../.././assets/styles/variables';

.table {
    font-size: calc(.66vw + .66rem);
    font-family: $MontserratRegular;

    @media (max-width: 1024px) {
        overflow-x: scroll;

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.15);
            border-radius: 5px;
        }
    }

    @media(max-width: 992px) {
        font-size: calc(.86vw + .86rem);
    }

    @media(max-width: 560px) {
        font-size: calc(1vw + 1rem);
    }
}

.tHead {
    display: flex;
    background: #F7F7F7;
    padding: .75em 1.45em;
    align-items: center;
    padding-right: 1.15em;

    @media only screen and (max-width: 1024px) {
        width: fit-content;
    }

    .tItem {
        color: #828282;

        .tText {
            font-size: .6em;
            display: block;
            line-height: .8;
            letter-spacing: -.01em;
            color: #828282;
        }
    }
}

.tBody__Item {
    display: flex;
    padding: 1.1em 1.45em;
    align-items: center;
    padding-right: 1.15em;

    .itemId {
        span {
            font-size: .7em;
        }
    }
}

.tItem {}

.itemDate {
    color: #828282;
    min-width: 8.5em;
    width: 8.5em;

    .tText {
        color: #828282;
    }
}

.itemId {
    color: #4F4F4F;
    min-width: 5.35em;
    width: 5.35em;
    text-align: center;
}

.itemRentail {
    color: #151719;
    min-width: 7.8em;
    width: 7.8em;
    text-align: right;
}

.itemAmount {
    color: #151719;
    min-width: 7em;
    width: 7em;
    text-align: right;
}

.itemPeriod {
    color: #4F4F4F;
    min-width: 7em;
    width: 7em;
    text-align: right;
}

.tText {
    font-size: .8em;
    display: block;
    letter-spacing: -.07em;
    color: #151719;
}


.itemBtn {
    min-width: 7.5em;
    min-width: 7.5em;
    text-align: right;

    span {
        min-width: 6em;

        display: block;
    }
}

.noHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $MontserratRegular;
    font-size: 1.25vw;
    color: $textDarkLight;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
    }
}
