@import '../../assets/styles/variables';

.inputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    &__invalid {
        color: $red;
        bottom: -1.3vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: 100%;
        position: absolute;
        white-space: nowrap;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }
}
