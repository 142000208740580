@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.howToStart {
    padding: 3vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px 0 $tablet30px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px 0 $mobile20px $mobile25px;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop25px;
        line-height: $desctop34px;
        margin-bottom: $desctop50px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        margin-left: 1vw;

        @media only screen and (max-width: 1024px) {
            margin-left: 0;
        }

        &:first-child {
            justify-content: space-between;

            @media only screen and (max-width: 1024px) {
                justify-content: flex-start;
            }
        }



        &_itemAbove,
        &_itemUnder {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 1.1vw;
            line-height: 1.9vw;
        }

        &_itemAbove {
            &:nth-child(1) {
                margin-left: 2vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-left: 3vw;
                }

                @media only screen and (max-width: 499px) {
                    margin-left: 4vw;
                }
            }

            &:nth-child(2) {
                margin-left: 4vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-left: 21.5vw;
                }

                @media only screen and (max-width: 499px) {
                    margin-left: 52vw;
                }
            }

            &:nth-child(3) {
                margin-left: 10vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-left: 19vw;
                }

                @media only screen and (max-width: 499px) {
                    margin-left: 50vw;
                }
            }
        }

        &_itemUnder {
            &:nth-child(1) {
                margin-left: 16vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-left: 30.5vw;
                }

                @media only screen and (max-width: 499px) {
                    margin-left: 61vw;
                }
            }

            &:nth-child(2) {
                margin-left: 9.8vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-left: 20.5vw;
                }

                @media only screen and (max-width: 499px) {
                    margin-left: 51vw;
                }
            }
        }

        &_img {
            margin-right: 2.7vw;
            width: 4vw;
            height: 4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet60px;
                height: $tablet50px;
                margin-right: $tablet50px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile60px;
                height: $mobile55px;
                margin-right: 14vw;
            }
        }

        &_step {
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                width: 20vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                width: 33vw;
            }

            span {
                font-family: $Montserrat700;
                font-size: $desctop16px;
                line-height: $desctop12px;
                color: $textDarkLight;
                margin-bottom: $desctop13px;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet12px;
                    line-height: $tablet12px;
                    margin-bottom: $tablet12px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile12px;
                    margin-bottom: $mobile12px;
                }
            }
        }
    }

    &__container {
        @media only screen and (max-width: 1024px) {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                height: 0vw;
            }
        }
    }

    &__stepLine {
        width: 100%;
        margin: 2vw 0 2vw 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 2vw 0 2vw 0;
            width: fit-content;
            width: -moz-max-content;
        }

        @media only screen and (max-width: 499px) {
            margin: $mobile42px 0;
            width: fit-content;
            width: -moz-max-content;
        }
    }
}
