@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.modal {
    &__select {
        margin-bottom: 1.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile24px;
        }

        .ant-select-selection {
            width: 100%;
        }
    }

    &__qrCode {
        display: flex;
        justify-content: center;

        canvas {
            width: 10vw !important;
            height: 10vw !important;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 23vw !important;
                height: 23vw !important;
            }

            @media only screen and (max-width: 499px) {
                width: 35vw !important;
                height: 35vw !important;
            }
        }
    }
}

.fiatCoinSelect {
    width: 100%;
}

.depositFiatModal {
    @media only screen and (max-width: 499px) {
        height: 100%;
    }
}

.depositModalWindow {
    height: 90% !important;
}

.overflowHidden {
    overflow: hidden;
}
