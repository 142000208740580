@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.PromoVideo {
    font-size: 1.39vw;
    padding: 4.2vw 7.85vw;
    min-height: 63vw;
    @media(max-width: 1024px){
        font-size: 16px;
        padding: 4.35vw 4.4vw 4.35vw 11.5vw;
    }
    .title {
        margin-bottom: 2em;

        h2 {
            font-size: 1.2em;
            line-height: 1;
            color: #151719;
        }
    }

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        max-width: 21.5em;
        margin-right: 3em;
        display: flex;
    flex-direction: column;
    @media(max-width: 680px){
        max-width: 100%;
        width: 100%;
        margin-bottom: 2em;
    }
    }

    .caption {
        margin-bottom: .4em;

        h3 {
            font-size: 1em;
            line-height: 1.7;
            color: #151719;
        }
    }

    .description {
        margin-bottom: .2em;

        p {
            font-size: .8em;
            line-height: 1.62;
            color: #505761;
        }
    }

    .videoOuter {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        margin-top: auto;
    }

    .videoInner {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .labelP2P, .labelCards {
        text-align: center;
        position: absolute;
        width: 100px;
        height: 33px;
        background: #2F80ED;
        border-radius: 5px;
        border-bottom-left-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 13px;
        left: -18px;
        z-index: 2;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 9px solid #2F80ED;
            border-left: 19px solid transparent;
            top: calc(100% - 1px);
            left: -1px;
            border-top-left-radius: 4px;
        }

        span {
            font-weight: 600;
            font-size: 12px;
            line-height: .67;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    .labelCards {
        background: #219653;
        &::after {
            border-top: 9px solid #219653;
        }
    }
}
