@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixin';

.workWith {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6vw 7.85vw 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        flex-direction: column;
        padding: $tablet30px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: 15.34vw 6.7vw 20vw;
    }

    &__leftSide {
        display: none;
        max-width: 100%;
        margin-bottom: 5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            max-width: -webkit-fill-available;
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            max-width: 100%;
            margin-bottom: $mobile20px;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: $desctop24px;
            line-height: $desctop34px;
            margin-bottom: 1.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet24px;
                line-height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
                line-height: $mobile34px;
            }
        }

        &_subTitle {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: $desctop16px;
            line-height: $desctop36px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile34px;
            }
        }
    }

    &__rightSide {
        width: 100%;

        &_sliderImg {
            width: $desctop75px;
            height: $desctop75px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet80px;
                height: $tablet80px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile68px;
                height: $mobile68px;
            }
        }
    }
}
