@import '../../assets/styles/variables';

.footer {
    display: flex;
    background: $footerGradient;
    padding: $desctop68px $desctop120px;
    margin-top: auto;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet50px 0px 6.55vw 5vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile60px 6.7vw $mobile75px
    }

    &__logo {
        width: $desctop124px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 14.12vw;
            height: 10.65vw;
        }

        @media only screen and (max-width: 499px) {
            width: 25vw;
            height: 16.9vw;
        }
    }

    &__socialIconsWrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 15vw;

        @media only screen and (max-width: 499px) {
            max-width: 100%;
            justify-content: space-between;
        }
    }

    &__community {
        width: max-content;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-top: 8vw;
        }
    }

    &__bottomWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $desctop30px;

        @media only screen and (max-width: 499px) {
            margin-top: $mobile42px;
            display: flex;
            justify-content: center;
        }

        &_socialIcons {
            margin-bottom: $desctop14px;
            display: block;

            &:not(:last-child) {
                margin-right: $desctop20px;
            }

            img {
                width: $desctop32px;
                height: $desctop32px;

                @media only screen and (max-width: 499px) {
                    width: $mobile32px;
                    height: $mobile32px;
                }
            }
        }
    }

    &__copyright {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 499px) {
            display: none;
        }

        &_content {
            font-family: $MontserratRegular;
            color: $white;
            font-size: 0.8vw;
            line-height: 0.8vw;
            margin-top: $desctop75px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet24px;
            }
        }

        &_symbol {
            margin: 0 0.4vw;
        }

        &_p2p {
            font-family: $Montserrat700;
        }
    }

    &__copyrightMobile {
        display: flex;
        flex-direction: column;
        width: 45%;

        @media only screen and (min-width: 500px) {
            display: none;
        }

        &_content {
            font-family: $MontserratRegular;
            color: $white;
            font-size: $mobile12px;
            line-height: $mobile32px;
            margin-top: 4.8vw;
        }

        &_symbol {
            margin: 5px;
        }

        &_p2p {
            font-family: $Montserrat700;
        }
    }

    &__about,
    &__support,
    &__services {
        display: flex;
        flex-direction: column;
        margin-right: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: 5vw;
        }

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-right: 0;
        }
    }

    &__about,
    &__support {
        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile60px;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $white;
        font-size: $desctop22px;
        line-height: $desctop16px;
        margin-bottom: $desctop30px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            margin-bottom: $mobile30px;
        }
    }

    &__services {
        .footer__title {
            @media only screen and (max-width: 499px) {
                height: $mobile28px;
                margin-bottom: 4.6vw;
                display: flex;
                align-items: center;
            }
        }
    }

    &__linksWrapper {
        display: flex;
        margin-left: 7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-left: 5vw;
        }

        @media only screen and (max-width: 499px) {
            margin: 0;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__links {
        font-family: $MontserratRegular;
        color: $white;
        font-size: $desctop14px;
        line-height: $desctop32px;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: $desctop10px;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet32px;
            white-space: normal;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile32px;
            white-space: normal;
            margin-bottom: 0;
        }

        &:hover {
            color: $brownColor;
        }
    }
}

.footerPersonalArea {
    position: relative;
    padding: 0 7.85vw 0 0;
    display: flex;
    justify-content: space-between;
    background-color: $cabinerBackColor;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet36px $tablet70px $tablet36px $tablet40px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile32px $mobile25px $mobile42px $mobile25px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 86.3vw;
        border-bottom: 1px solid $borderColor;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            border-bottom: 1px solid $borderColor;
        }
    }

    &__leftSide {
        margin-right: 8vw;
    }

    &__leftSide,
    &__rightSide {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            align-items: flex-start;
            width: 45%;
        }

        &_links {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 0.8vw;
            line-height: 1.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
            }


            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;

                &:not(:last-child) {
                    margin-bottom: $mobile30px;
                }
            }

            &:hover {
                transition: 0.25s;
                color: $greenLight;
            }

            &:not(:last-child) {
                margin-right: 7vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-right: $tablet70px;
                }
            }
        }
    }

    &__copyright {
        padding: 1.85vw 0 1.85vw 2.9vw;
        margin-right: 3.85vw;
        width: 13.7vw;
        background: #f3f7fd;
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet12px;
            padding: 0;
            margin-right: 11.85vw;
            background: transparent;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile12px;
            padding: 0;
            background: transparent;
            width: auto;
            margin-bottom: $mobile30px;
        }

        &_symbol {
            margin: 0 0.4vw;
        }

        &_p2p {
            font-family: $Montserrat700;
        }
    }
}
