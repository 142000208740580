@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.walletWrapper {
    width: 74.6vw;
    margin: 2vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }
}

.wallet {
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: $tablet40px;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile20px;
        }
    }
}

.walletTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.1vw 3vw 2.1vw 3vw;
    margin-bottom: 0.5vw;
    transition: all 0.25s;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet24px $tablet32px;
        margin-bottom: $tablet10px;
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        position: relative;
        justify-content: flex-end;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px;
        margin-bottom: $mobile10px;
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
    }

    &:hover {
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    }

    &__logo {
        width: 2.4vw;
        height: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet36px;
            width: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            height: 9.6vw;
            width: 9.6vw;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: $greyLight;
        margin-bottom: 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            margin-bottom: $mobile10px;
        }
    }

    &__coin,
    &__fullName,
    &__totalBalance,
    &__availableBalance,
    &__inOrders {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 15vw;
            padding-right: 10px;
            margin: 0;
        }

        &_value {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.2vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $greyLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }
    }

    &__logoWrapper,
    &__coin,
    &__fullName {
        @media only screen and (max-width: 499px) {
            width: 33%;
            margin: 0;
            padding-right: $mobile10px;
        }
    }

    &__totalBalance,
    &__availableBalance,
    &__inOrders {
        @media only screen and (max-width: 499px) {
            width: 33%;
            margin: $mobile20px 0;
            padding-right: $mobile10px;
        }
    }

    &__fullName,
    &__totalBalance {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: $tablet10px;
        }
    }

    &__coin {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-left: auto;
        }

        &_value {
            width: 4.5vw;

            @media only screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }

    &__fullName {
        &_value {
            width: 6vw;

            @media only screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }

    &__totalBalance {
        max-width: 7vw;

        @media only screen and (max-width: 1024px) {
            max-width: 100%;
        }

        &_value {
            max-width: 5vw;
            width: fit-content;
            width: -moz-max-content;

            @media only screen and (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    &__availableBalance {
        max-width: 8vw;

        @media only screen and (max-width: 1024px) {
            max-width: 100%;
        }

        &_value {
            max-width: 7vw;
            width: fit-content;
            width: -moz-max-content;

            @media only screen and (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    &__inOrders {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            position: absolute;
            bottom: 3.3vw;
            left: 10vw;
        }

        &_value {
            width: 5vw;

            @media only screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }

    &__totalBalance {
        &_value {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.2vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $greenLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }
    }

    &__buttonsWrapper {
        display: flex;
        align-items: center;
        width: 26vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            justify-content: flex-end;
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            justify-content: space-between;
        }

        button {
            &:not(:last-child) {
                margin-right: 0.5vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-right: $tablet10px;
                }

                margin-right: 1.2vw;
            }
        }
    }

    &__depositBtn,
    &__withdrawBtn,
    &__tradeBtn,
    &__depositFiatBtn,
    &__withdrawFiatBtn {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 2.1vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 7.1vw;
        padding: 0 1.3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            min-width: 15vw;
            height: $tablet40px;
            padding: 0;
            line-height: normal;
        }

        @media only screen and (max-width: 499px) {
            width: 33%;
            height: $mobile34px;
            font-size: $mobile12px;
            line-height: $mobile12px;
        }
    }

    &__depositBtn,
    &__depositFiatBtn {
        @include blueBtnWithOutBackground;
    }

    &__tradeBtn {
        @include greenBtnWithOutBackground;
    }

    &__withdrawBtn,
    &__withdrawFiatBtn {
        @include redBtnWithOutBackground;
    }

    &__depositFiatBtn,
    &__withdrawFiatBtn {
        margin-top: $desctop25px;
        width: $desctop225px;
        height: $desctop60px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet28px;
            width: 30vw;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: 0;
            width: 100%;
            height: $mobile60px;
        }
    }
}

.modal {
    position: relative;
    height: fit-content;
    height: -moz-max-content;
    padding: 4vw 6.9vw;
    overflow-y: scroll;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile34px $mobile24px;
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            top: $tablet20px;
            right: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            top: $mobile20px;
            right: $mobile20px;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.55vw;
        color: $textDarkLight;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile24px;
        }
    }

    &__copyContent {
        position: absolute;
        width: 1.3vw;
        height: 1.3vw;
        right: 1vw;
        top: 50%;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            right: 1.6vw;
            width: 2.3vw;
            height: 2.3vw;
        }

        @media only screen and (max-width: 499px) {
            top: 53%;
            right: 3.2vw;
            width: 4vw;
            height: 4vw;
        }
    }

    &__inputWrapper {
        margin-bottom: 1.7vw;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile24px;
        }

        &_label {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;
            font-size: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
            }
        }

        &_input {
            width: 100%;
            height: 3.9vw;
            margin-top: 0.5vw;
            background: $inputColor;
            border-radius: 0.1vw;
            border: none;
            padding: 0 3.3vw 0 1vw;
            font-size: 1vw;
            line-height: 1.4vw;
            outline: none;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                line-height: $tablet16px;
                border-radius: 1px;
                padding: 0 5.6vw 0 1.6vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                border-radius: 1px;
                height: $mobile60px;
                margin-top: $mobile10px;
                padding: 0 11.2vw 0 3.2vw;
            }

            &::placeholder {
                font-family: $MontserratRegular;
                font-size: 1vw;
                line-height: 3.8vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    line-height: $tablet16px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile16px;
                    line-height: $mobile16px;
                }
            }
        }

        &_inputDisabled {
            user-select: none;
            pointer-events: none;
            opacity: 0.6;
        }

        &_inputFiatAmount {
            padding-right: 4.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding-right: 9vw;
            }

            @media only screen and (max-width: 499px) {
                padding-right: 17vw;
            }
        }

        &_fiatTicker {
            position: absolute;
            right: $desctop18px;
            top: 62%;
            transform: translateY(-50%);
            font-family: $MontserratRegular;
            font-size: $desctop16px;
            color: #3E3E3E;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                right: $tablet18px;
                font-size: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                right: $mobile16px;
                font-size: $mobile16px;
            }
        }
    }

    &__buttonWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__depositFee,
    &__depositWarning {
        font-family: $MontserratRegular;
        margin-bottom: 1.5vw;
        font-size: 0.7vw;
        line-height: 1.1vw;
        color: #92969c;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet24px;
            margin-bottom: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile24px;
            margin-bottom: 6.4vw;
        }
    }

    &__depositFee {
        color: #92969c;
    }

    &__depositWarning {
        color: #007aff;
    }

    &__withdrawFee {
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        line-height: 1.1vw;
        color: $blueDark;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile24px;
        }
    }

    &__depositButton,
    &__withdrawButton {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.15vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 11.7vw;
        margin-top: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            min-width: 31vw;
            margin-top: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            min-width: 100%;
            margin-top: $mobile16px;
            font-size: $mobile12px;
        }
    }

    &__depositButton {
        @include blueBtnWithBorderAndBackground;
        margin-top: 3vw;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 499px) {
            margin-top: 5vw;
        }

        i {
            margin-left: 0.5vw;
            font-size: 0.9vw;
            display: flex;
            align-items: center;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-left: $tablet16px;
                font-size: $tablet12px;
                line-height: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                margin-left: $mobile16px;
                font-size: $mobile12px;
                line-height: $mobile12px;
            }
        }
    }

    &__withdrawButton {
        @include redBtnWithBorderAndBackground;
    }

    &__minimalSumToWithdraw {
        display: flex;
        justify-content: center;
        margin-bottom: 1.7vw;
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        line-height: 1.1vw;
        color: $blueDark;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet24px;
            font-size: $tablet12px;
            line-height: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile24px;
            font-size: $mobile12px;
            line-height: $mobile24px;
        }
    }
}

.fiatCoinWrapperSelect {
    position: relative;
    margin-bottom: $desctop30px;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: 3.14vw;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: 6.4vw;
    }
}

.inputWrapper {
    &__invalid {
        color: $red;
        bottom: -1.3vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: fit-content;
        width: -moz-max-content;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }
}
