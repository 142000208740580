@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.tradeModalWindow {
    width: 39%;
    height: fit-content;
    height: -moz-max-content;
    padding: 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 75%;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
    }

    &__cardNumber {
        &_input {

        }
    }

    .width100 {
        width: 100%;
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        .paymentMethod {
            width: 100%;
        }
    }
}

.disabled {
    opacity: 0.7;
    user-select: none;

    &:hover {
        border-color: transparent !important;
    }
}
