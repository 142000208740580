@import '../.././assets/styles/variables';

.termOfService {
    padding: 3vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet20px $tablet88px 0;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 2vw;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.25vw;
        margin-bottom: 1.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile16px;
        }
    }

    &__subTitle {
        font-family: $MontserratRegular;
        color: $greyLight;
        font-size: 1vw;
        line-height: 1.9vw;
        text-align: justify;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet36px;
            margin-bottom: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile34px;
            margin-bottom: $mobile60px;
        }
    }
}
