@import '../../../../assets/styles/variables';

.newOrder {

    &__wrapperPage {
        .filterBlock-select .ant-select-selection {
            width: 20.8vw;

            @media only screen and (max-width: 1024px) {
                width: 100%;
            }
        }

        .filterBlock-select__wrapper-input {
            max-width: 20.8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                max-width: 30%;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 0;
            }
        }

        .filterBlock-select__wrapper-input {
            flex-direction: column;
        }

        .filterBlock-select__wrapper_item {
            width: 20.8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 30%;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin-bottom: $mobile30px;
            }
        }
    }

    &__marketPriceFields {
        max-height: 18vw;
        top: 0.1vw;
        transition: max-height 400ms;
        opacity: 1;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            max-height: 41vw;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            max-height: 90vw;
        }
    }

    &__marketPriceDisableFields {
        top: 0;
        max-height: 0.1vw;
        transition: max-height 400ms;
        overflow: hidden;
        opacity: 0;
    }

    &__wrapper {
        &_input {
            width: 20.8vw;
            height: 3.91vw;
            line-height: 1.1vw;
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw;
            margin-top: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet60px;
                line-height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                padding: $tablet12px;
                border: 1px solid #f2f3f9;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: $mobile60px;
                line-height: $mobile60px;
                margin-top: $mobile10px;
                font-size: $mobile16px;
                padding: $mobile12px;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    border-radius: 2px;
                }

                @media only screen and (max-width: 499px) {
                    border-radius: 2px;
                    font-size: $mobile16px;
                }
            }
        }
    }

    &__setPrice {

        &_input {
            width: 15.6vw;
            height: 3.91vw;
            line-height: 1.1vw;
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw;
            margin-top: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet60px;
                line-height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                padding: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: $mobile60px;
                line-height: $mobile60px;
                margin-top: $mobile10px;
                font-size: $mobile16px;
                padding: $mobile12px;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    border: 1px solid #f2f3f9;
                    padding: $tablet12px;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    border-radius: 2px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile16px !important;
                    border-radius: 2px;
                }
            }
        }
    }

    &__row {
        &_paymentWindow {

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: 0;
            }

            @media only screen and (max-width: 499px) {
                order: 3;
                margin-bottom: 8vw !important;
            }
        }



        @media only screen and (max-width: 499px) {
            &_limitsFrom {
                margin-bottom: $mobile30px !important;
            }

            &_buyOrSell,
            &_whichToTradeWith,
            &_paymentMethod,
            &_firstCryptocurrency,
            &_location,
            &_whichToTradeWith {
                position: relative;
            }

            &_cardholder {
                order: -1;
                margin-bottom: $mobile30px !important;
            }
        }
    }
}

.setPrice {
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 100%;
        padding-left: $tablet40px;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
    }

    .filterBlock-select__wrapper-input {
        max-width: 15.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            max-width: 45% !important;
            width: 100%;
            margin-bottom: 0;
        }

        @media only screen and (max-width: 499px) {
            max-width: 100% !important;
            width: 100%;
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        .ant-radio-wrapper {
            margin-right: auto;
            margin-top: 6vw;
        }
    }

    @media only screen and (max-width: 499px) {
        .ant-radio-wrapper {
            margin-bottom: $mobile42px;
        }
    }
}

.disableBlock {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
}

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.select-search-box {
    position: relative;
    transition: transform 0.3s,
}

.select-search-box::after {
    font-family: fontawesome;
    content: url('./images/arrow.svg');
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1vw;
    width: 0.8vw;
    height: 0.8vw;
    text-align: center;
    color: #222f3e;
    z-index: 1;
    transition: transform 0.3s;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet12px;
        right: $tablet12px !important;
        width: $tablet12px !important;
        height: $tablet12px !important;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile12px;
        right: $mobile12px !important;
        width: 2.6vw !important;
        height: 2.6vw !important;
    }
}

.newOrder__row_location {

    .select-search-box__search,
    .select-search-box__select {
        max-width: 100%;
    }
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
    content: url('./images/arrow.svg');
    transform: rotate(180deg) translateY(0.5vw);
    transition: transform 0.3s;
}

.select-search-box__icon--disabled::after {
    content: none !important;
}

.select-search-box--input::after {
    display: none !important;
}

.select-search-box__out {
    display: none;
}

.select-search-box__search {
    width: 20.8vw;
    height: 3.91vw;
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: $MontserratRegular;
    font-size: 1.1vw;
    color: $textDarkLight;
    border-radius: 0.1vw;
    border: 1px solid #f2f3f9;
    outline: none;
    padding: 0 1vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 100% !important;
        height: $tablet60px !important;
        font-size: $tablet16px !important;
        padding: $tablet12px !important;
        border: 1px solid #f2f3f9;
    }

    @media only screen and (max-width: 499px) {
        width: 100% !important;
        max-width: 100%;
        height: $mobile60px !important;
        font-size: $mobile16px !important;
        padding: $mobile12px !important;
    }

    &:hover,
    &:active,
    &:focus {
        border: 1px solid $blueDark;

        @media only screen and (max-width: 1024px) {
            border: 1px solid $blueDark;
        }
    }

    &::placeholder {
        background: #f2f3f9;
        border-radius: 0.1vw;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $greyButton;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px !important;
            border-radius: 2px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px !important;
            border-radius: 2px;
        }

    }
}

input.select-search-box__search {
    line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
    box-shadow: none;
}

.select-search-box--input .select-search-box__search {
    cursor: text;
}

.select-search-box__search:focus {
    cursor: text;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

.select-search-box__select {
    display: none;
    position: absolute;
    top: 4.2vw;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 9999;
    box-sizing: border-box;
    background-color: $white;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 20.8vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        top: 8.2vw !important;
        width: 100% !important;
    }

    @media only screen and (max-width: 499px) {
        top: 17.2vw !important;
        width: 100% !important;
    }
}

.select-search-box--multiple .select-search-box__select {
    display: block;
    position: static;
    border-radius: 0;
    box-shadow: none;
}

.select-search-box__select--display {
    display: block;
}

.select-search-box__option {
    width: 100%;
    height: 3.91vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    font-family: $MontserratRegular;
    font-size: 1.1vw;
    line-height: 1.1vw;
    color: #92969c;
    cursor: pointer;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: 7.82vw !important;
        font-size: 2.1vw !important;
        line-height: 3.39vw !important;
        padding: 1.6vw !important;
    }

    @media only screen and (max-width: 499px) {
        height: 16vw !important;
        font-size: 4.3vw !important;
        line-height: 6.7vw !important;
        padding: 0 3.2vw !important;
    }

    &:first-child,
    &:last-child {
        border-radius: 0.3vw 0.3vw 0 0;
    }
}

.select-search-box__option:first-child {
    border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
    background: #f4f7fa;
}

.select-search-box__option--selected {
    background: linear-gradient(0deg, $blueDark, $blueDark);
    border: 1px solid $blueDark;
    border-radius: 0;
    color: $white;
    font-weight: 400;

    @media only screen and (max-width: 1024px) {
        border: 1px solid $blueDark;
    }
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
    background: $blueDark;
    color: $white;
    border-top-color: $blueDark;
}

.ant-select-dropdown {
    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.coinSellInput {
    transform: translateX(26.8vw);
    position: relative;
    z-index: 10;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        transform: translateX(27vw);
    }


    @media only screen and (max-width: 499px) {
        transform: translateX(0);
        transform: translateY(29vw);
    }
}

.coinBuyInput {
    transform: translateX(-26.8vw);
    position: relative;
    z-index: 10;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        transform: translateX(-27vw);
    }

    @media only screen and (max-width: 499px) {
        transform: translateX(0);
        transform: translateY(-29vw);
    }
}

.inputWrapper {
    &__invalid {
        color: $red;
        bottom: -1.3vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: fit-content;
        width: -moz-max-content;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }
}

.cardDisabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
}

.paymentMethod {
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: 5.21vw;
    }
}
