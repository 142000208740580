@import '../../assets/styles/variables';

.slickSlider {
    &__arrowPrev,
    &__arrowNext {
        cursor: pointer;
        width: 2.5vw;
        height: 2.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet36px;
            height: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            width: $mobile34px;
            height: $mobile34px;
        }

        .slickSlider__arrowNext_fill,
        .slickSlider__arrowNext_stroke {
            stroke: #92969C;
            opacity: 0.3;
            transition: all 0.25s;
        }

        .slickSlider__arrowNext_fill {
            fill: #92969C;
            opacity: 0.3;
            transition: all 0.25s;
        }

        &:hover {

        .slickSlider__arrowNext_fill,
        .slickSlider__arrowNext_stroke {
            stroke: #2C3139;
            opacity: 1;
        }

        .slickSlider__arrowNext_fill {
            fill: #2C3139;
            opacity: 1;
        }
        }
    }
}
