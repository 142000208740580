@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.advertisement {
    width: 74.6vw;
    margin: 2vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile20px;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__status {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.9vw;
        margin-bottom: 2.2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            margin-bottom: $mobile34px;
        }

        &_stateActive {
            color: $blueDark;
        }

        &_stateRed {
            color: $red;
        }
    }

    &__buttonGroup {
        display: flex;
        align-items: center;
        margin-bottom: 4.2vw;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $mobile70px;
        }

        &_iWant {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 0.9vw;
            margin-right: 2.1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                margin-right: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                margin: 0 $mobile24px $mobile20px 0;
            }
        }

        &_disable,
        &_activate,
        &_edit,
        &_delete {
            @include mainBtnStyle;
            font-family: $MontserratRegular;
            height: 3.4vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 12.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                min-width: 20vw;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                min-width: $mobile150px;
                height: $mobile42px;
                font-size: $mobile12px;
            }
        }

        &_disable {
            @include redBtnWithOutBackground;

            @media only screen and (max-width: 499px) {
                min-width: 39.6vw;
                margin-bottom: 8vw;
            }
        }

        &_activate {
            @include greenBtnWithOutBackground;

            @media only screen and (max-width: 499px) {
                min-width: 39.6vw;
                margin-bottom: 8vw;
            }
        }

        &_edit {
            @include blackBtnWithOutBackground;
            margin: 0 2.1vw;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 499px) {
                margin: 0 $mobile24px 0 0;
            }
        }

        &_delete {
            @include redBtnWithBorderAndBackground;
        }
    }

    &__details {
        display: flex;

        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        &_logo {
            width: 5.3vw;
            height: 5.3vw;
            border-radius: 50%;
            object-fit: cover;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 9.8vw;
                height: 9.8vw;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile75px;
                height: $mobile75px;
            }
        }

        &_rightSide {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 0 0 2.1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: $tablet20px 0 0 0;
            }

            @media only screen and (max-width: 499px) {
                margin: $mobile20px 0 0 0;
            }
        }

        &_itemWrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 2.4vw 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                justify-content: space-between;
            }

            @media only screen and (max-width: 499px) {
                margin: 0 0 $mobile20px 0;
            }
        }

        &_item {
            width: calc(25% - 1vw);
            margin: 0 1vw 1.5vw 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: calc(33% - 3vw);
                margin: 0 3vw $tablet30px 0;
            }

            @media only screen and (max-width: 499px) {
                width: calc(50% - 5vw);
                margin: 0 5vw $mobile30px 0;
            }
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            color: $greyLight;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                margin-bottom: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_subTitle,
        &_subTitleBlue,
        &_subTitleBuy,
        &_subTitleSell,
        &_subTitleExchange,
        &_safeToSell,
        &_whenIRecive {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.4vw;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: 2.4vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: 4.7vw;
            }
        }

        &_subTitleBuy {
            color: $greenLight;
        }

        &_subTitleSell {
            color: $red;
        }

        &_subTitleExchange {
            color: $blueDark;
        }

        &_subTitle,
        &_safeToSell,
        &_whenIRecive {
            color: $textDarkLight;
        }

        &_subTitleBlue {
            color: $blueDark;
        }

        &_safeToSell,
        &_whenIRecive {
            margin-bottom: 1.4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
            }
        }

        &_safeToSellDescription,
        &_whenIReciveDescription {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            color: $greyLight;
            line-height: 2.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet32px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile32px;
            }
        }

        &_answersWrapper {
            margin: 0 0 2.4vw 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile60px;
            }
        }
    }
}
