@import '../../assets/styles/variables';

.rc-pagination {
    display: flex;
    align-items: center;
    padding: 0;
    width: 70.5vw;
    margin: 0 auto;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 0 5vw 0;
        margin: 0 auto;
    }

    @media only screen and (max-width: 499px) {
        padding: 0;
        margin: 0 $mobile25px $mobile75px;
        width: auto;
        position: relative;
        justify-content: center;
    }

    .rc-pagination-prev,
    .rc-pagination-next {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        color: $textDarkLight;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet12px;
            letter-spacing: 1px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile12px;
            letter-spacing: 1px;
        }

        &:hover {
            color: $brownColor !important;
        }
    }

    .rc-pagination-prev {
        margin-right: auto;
    }

    .rc-pagination-next {
        margin-left: auto;
    }

    .rc-pagination-prev {
        @media only screen and (max-width: 499px) {
            position: absolute;
            bottom: -11vw;
            left: 0;
        }
    }

    .rc-pagination-next {
        @media only screen and (max-width: 499px) {
            position: absolute;
            bottom: -11vw;
            right: 0;
        }
    }

    .rc-pagination-item {
        border: none;
        background-color: transparent;
        width: 3.1vw;
        height: 3.1vw;
        outline: none;
        margin-right: 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet48px;
            height: $tablet48px;
        }

        @media only screen and (max-width: 499px) {
            width: 11vw;
            height: 11vw;
        }

        &:hover {
            border-color: $brownColor;
            a {
                color: $brownColor !important;
            }
        }

        a {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
            }
        }
    }

    .rc-pagination-item-active {
        border: 1px solid $brownColor;
        border-radius: 50%;
        background-color: transparent;
        width: 3.1vw;
        height: 3.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            border: 1px solid $brownColor;
            width: $tablet48px;
            height: $tablet48px;
        }

        @media only screen and (max-width: 499px) {
            border: 1px solid $brownColor;
            width: 11vw;
            height: 11vw;
        }

        a {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $brownColor;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
            }
        }
    }

    .rc-pagination-jump-next,
    .rc-pagination-jump-prev {
        outline: none;
        width: 1.7vw;
        min-width: 1.7vw;
        margin: 0;
        transform: translateX(-0.3vw);

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            display: flex;
            align-items: flex-end;
        }

        @media only screen and (max-width: 499px) {
            min-width: $mobile25px;
            display: flex;
            align-items: flex-end;
        }

        &:after {
            color: $brownColor !important;
        }

        &::after {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            letter-spacing: 0.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                letter-spacing: 1px;
                min-width: $mobile25px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
        }
    }
}
