@import '../../../../.././assets/styles/variables';

.table {
    font-size: calc(0.66vw + 0.66rem);
    font-family: $MontserratRegular;
    overflow-x: scroll;

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 5px;
    }

    @media (max-width: 992px) {
        font-size: calc(0.86vw + 0.86rem);
    }

    @media (max-width: 560px) {
        font-size: calc(1vw + 1rem);
    }
}


.tHead {
    display: flex;
    background: #f7f7f7;
    padding: 0.75em 1.25em;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1024px) {
        width: fit-content;
    }

    .tItem {
        color: #828282;

        .tText {
            font-size: 0.6em;
            display: block;
            line-height: 0.8;
        }
    }
}

.tBody__Item {
    display: flex;
    padding: 0.5em 1.25em;
    align-items: center;
    justify-content: space-between;

    .itemId {
        span {
            font-size: 0.7em;
        }
    }
}

.tItem {
    &:last-child {
        min-width: 6em;
        width: 6em;
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-left: 2vw;
    }

    @media only screen and (max-width: 499px) {
        margin-left: 4vw;
    }
}

.itemDate {
    color: #828282;
    min-width: 8.5em;
    width: 8.5em;
    margin-right: 2vw;
}

.itemId {
    color: #4f4f4f;
    min-width: 6.35em;
    width: 6.35em;
}

.itemRentail {
    color: #151719;
    min-width: 6.8em;
    width: 6.8em;
}

.itemAmount {
    color: #151719;
    min-width: 7.5em;
    width: 7.5em;
}

.itemPeriod {
    color: #4f4f4f;
    min-width: 4.6em;
    width: 4.6em;
}

.tText {
    font-size: 0.8em;
    display: block;
    letter-spacing: -0.07em;
}

.itemBtn {
    min-width: 6em;
    width: 6em;

    span {
        min-width: 6em;
        display: block;
    }
}

.returnButton {
    width: 6em;
    height: 2em;
    border: 1px solid #28a745;
    border-radius: 1em;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    font-size: calc(0.66vw + 0.66rem);
    transition: background-color 0.3s;
    outline: none;
    cursor: pointer;

    @media (max-width: 780px) {
        width: 100%;
    }

    &:hover {
        background-color: #28a745;

        span {
            color: #fff;
        }
    }

    span {
        font-weight: bold;
        font-size: 0.6em;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        color: #28a745;
        transition: 0.3s;

        @media (max-width: 780px) {
            font-size: 0.7em;
        }
    }
}

.downloadButton {
    width: 6em;
    min-width: 6em;
    height: 2em;
    border: 1px solid $blueDark;
    border-radius: 1em;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    font-size: calc(0.66vw + 0.66rem);
    transition: background-color 0.3s;
    outline: none;
    cursor: pointer;

    @media (max-width: 780px) {
        width: 100%;
        min-width: 7em;
    }

    @media only screen and (max-width: 499px) {
        min-width: 8.5em;
    }

    &:hover {
        background-color: $blueDark;

        span {
            color: #fff;
        }
    }

    span {
        min-width: 6em;
        display: block;
        font-weight: bold;
        font-size: 0.6em;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        color: $blueDark;
        transition: 0.3s;

        @media (max-width: 780px) {
            font-size: 0.7em;
        }
    }
}

.returnButtonDisabled {
    width: 6em;
    height: 2em;
    border: 1px solid #BDBDBD;
    border-radius: 1em;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    font-size: calc(0.66vw + 0.66rem);
    transition: background-color 0.3s;
    outline: none;
    pointer-events: none;
    user-select: none;

    @media (max-width: 780px) {
        width: 100%;
    }

    span {
        font-weight: bold;
        font-size: 0.6em;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        color: #BDBDBD;
        transition: 0.3s;

        @media (max-width: 780px) {
            font-size: 0.7em;
        }
    }
}

.noHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $MontserratRegular;
    font-size: 1.25vw;
    color: $textDarkLight;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
    }
}
