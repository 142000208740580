@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixin';

.historyPage {
    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
        }
    }

    &__table {
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }
    }
}

.tableRowWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.1vw 2vw;
    margin-bottom: 0.5vw;
    transition: all 0.25s;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet30px $tablet10px $tablet30px $tablet30px;
        margin-bottom: $tablet10px;
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile42px $mobile20px;
        margin-bottom: $mobile10px;
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &:hover {
        background: $white;
        box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    }

    &__txWrapper {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.2vw;
        color: $blueDark;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto 0.5vw 0 0.5vw;
        width: 12vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            order: 2;
            width: calc(40% - 5vw);
            margin: 0 $tablet10px $tablet20px 0;
            padding-right: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__numberWrapper,
    &__amountWrapper,
    &__amountWrapperWithComission,
    &__amountWrapperWithdraw,
    &__currencyWrapper,
    &__dateWrapper,
    &__timeWrapper,
    &__typeWrapper,
    &__statusWrapper {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.2vw;
        color: $textDarkLight;
        margin: auto 0.5vw 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 1.7vw;
            line-height: 1.7vw;
            margin: 0;
            padding-right: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 45%;
            margin: 0;
            margin-bottom: $mobile30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__numberWrapper,
    &__amountWrapper,
    &__amountWrapperWithdraw,
    &__dateWrapper,
    &__timeWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__amountWrapperWithComission {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }


    &__numberWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 1;
        }

        @media only screen and (max-width: 499px) {
            order: -1;
        }
    }

    &__typeWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 3;
            width: 20%;
        }
    }

    &__numberWrapper,
    &__typeWrapper,
    &__statusWrapper {

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 $tablet10px $tablet20px 0;
        }
    }

    &__statusWrapper {
        width: 7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 4;
            width: 20%;
            max-width: 12vw;
        }

        @media only screen and (max-width: 499px) {
            width: 45%;
        }
    }

    &__cancelWithdraw {
        display: flex;
        margin-top: auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 8;
        }

        @media only screen and (max-width: 499px) {
            order: 8;
        }

        &_button {
            @include mainBtnStyle;
            @include redBtnWithBorderAndBackground;
            height: $desctop30px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: 1.5vw;
                height: fit-content;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                padding: 2.5vw 8vw;
                height: fit-content;
            }
        }
    }

    &__amountWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 5;
            margin-right: $tablet10px;
        }
    }

    &__amountWrapperWithComission,
    &__amountWrapperWithdraw {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 5;
            margin: 0 1.31vw 2.61vw 0;
        }
    }

    &__currencyWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 6;
            padding: 0;
            width: calc(40% - 5vw);
            margin: 2.1vw $tablet10px 0 0;
            display: flex;
            flex-direction: column;
        }

        @media only screen and (max-width: 499px) {
            order: -1;
        }
    }

    &__dateWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 7;
            margin-right: $tablet10px;
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        @media only screen and (max-width: 499px) {
            order: 1;
        }
    }

    &__timeWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            order: 8;
            margin-right: 9.4vw;
        }

        @media only screen and (max-width: 499px) {
            order: 1;
        }
    }

    &_number {
        width: 2.5vw;
    }

    &_amount {
        width: 7vw;
    }

    &_currency {
        width: 3.5vw;
    }

    &_date {
        width: 7vw;
    }

    &_time {
        width: 5.5vw;
    }

    &_tx {
        &:hover {
            transition: all 0.25s;
            color: $greenLight;
        }

        span {
            width: 10vw;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
            }
        }
    }

    &_address {
        span {
            width: 10vw;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
            }
        }
    }

    &_type {
        width: 6vw;
    }

    &_statusPending,
    &_statusCompleted,
    &_statusCanceled {
        width: 8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }
    }

    &_number,
    &_amount,
    &_currency,
    &_date,
    &_time,
    &_tx,
    &_address,
    &_type,
    &_status,
    &_statusPending,
    &_statusCompleted,
    &_statusCanceled {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
        }
    }

    &_numberLabel,
    &_amountLabel,
    &_amountLabelWithComission,
    &_txLabel,
    &_typeLabel,
    &_statusLabel {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: $greyLight;
        margin-bottom: 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet12px;
            margin-bottom: 5px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: 6px;
        }
    }

    &_amountLabelWithComission {

        @media only screen and (max-width: 499px) {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &_statusPending {
        color: $blueDark;
    }

    &_statusCompleted {
        color: $greenLight;
    }

    &_statusCanceled {
        color: $red;
    }
}

.noHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
    font-family: $MontserratRegular;
    font-size: 1.25vw;
    color: $textDarkLight;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: 0;
        font-size: $tablet16px;
    }

    @media only screen and (max-width: 499px) {
        margin-top: 0;
        font-size: $mobile16px;
    }
}

.withdrawRequests {
    margin-bottom: 5vw;

    &__titleWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: 4vw;
            flex-direction: column;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
            flex-direction: column;
        }
    }

    &__showRejected {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            min-width: 3.7vw;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: 2vw;
            justify-content: flex-start;
        }

        @media only screen and (max-width: 499px) {
            margin-top: 3vw;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 1vw;
            line-height: 2vw;
            margin-right: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
               font-size: 2vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.5vw;
                margin-right: 3vw;
            }
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-bottom: 8vw;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: 20vw;
    }
}
