@import '../../../../assets/styles/variables';

.chat {
    &__attachFile {
        .ant-upload-list {
            display: flex;
            width: 100%;
            overflow: auto;
            background-color: #fff;

            &::-webkit-scrollbar {
                height: 0.5vw;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: $inputColor;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $greyLight;
            }

            &-picture {
                .ant-upload-list-item-name {
                    width: 34vw;
                }
            }
        }

        .ant-upload-list-item {
            width: 10vw;
            margin-bottom: 0.5vw;

            @media only screen and (max-width: 499px) {
                width: auto;
            }

            &:not(:last-child) {
                margin-right: 1vw;
            }
        }
    }
}

.hideOnMobile {
    @media only screen and (max-width: 499px) {
        display: none;
    }
}

.ant-upload-select-picture {
    @media only screen and (max-width: 499px) {
        position: absolute;
        bottom: 20vw;
        left: 1vw;
    }
}

.feedBackModalWindow {
    width: 39%;
    height: fit-content;
    height: -moz-max-content;
    padding: 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 50%;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
    }
}

.chatRoom #aside {
    max-height: calc(100vh - 10.85vw);
}
