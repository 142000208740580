@import '../../../../../assets/styles/variables';

.historyPagedatePicker {
    min-width: 12.7vw;
    height: 3.91vw;
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: $MontserratRegular;
    font-size: 1.1vw;
    color: $textDarkLight;
    border-radius: 0.1vw;
    border: 1px solid #f2f3f9;
    outline: none;
    padding: 0 1vw;
    margin: 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: $tablet60px;
        border: 1px solid #f2f3f9;
        width: 100%;
        font-size: $tablet16px;
        border-radius: 2px;
        padding: 0 $tablet12px;
    }

    @media only screen and (max-width: 499px) {
        height: $mobile60px;
        border: 1px solid #f2f3f9;
        width: 100%;
        font-size: $mobile16px;
        border-radius: 2px;
        padding: 0 $mobile12px;

        &::placeholder {

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px !important;
                line-height: $mobile25px !important;
            }
        }
    }

    &:hover,
    &:active,
    &:focus {
        border: 1px solid $blueDark;

        @media only screen and (max-width: 1024px) {
            border: 1px solid $blueDark;
        }
    }

    &::placeholder {
        background: #f2f3f9;
        border-radius: 0.1vw;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $greyButton;
    }
}

.historyPage__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.6vw 0;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 100%;
        flex-wrap: wrap;
        margin: 4vw 0 0;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        flex-wrap: wrap;
        margin: $mobile20px 0 0;
    }

    .filterBlock-select__wrapper_item {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 40%;
        }
    }
}
