@import '../assets/styles/variables';
@import '../assets/styles/reset.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

html {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
}

body {
    background-color: $bodyColor;
}

#root {
    display: flex;
    flex-direction: column;
    font-family: $MontserratRegular;
    min-height: 100vh;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

// remove arrows for input type number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

// custom scrollbar

::-webkit-scrollbar {
    width: 0.7vw;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #555;
}

::-webkit-scrollbar-thumb:hover {
    background: #222;
}

// custom scrollbar

// hide transparent on focus
input:focus::-webkit-input-placeholder {
    color: transparent;
}

.ant-message {
    font-family: $MontserratRegular;
    color: $greyLight;
    font-size: 1vw;
    z-index: 9999999;
}

// feedback star
.star-container {
    svg {
        width: 2.6vw !important;
        height: 2.6vw !important;

        @media only screen and (max-width: 499px) {
            width: 7.6vw !important;
            height: 7.6vw !important;
            margin-top: 4vw;
        }

        path {
            stroke: $blueDark;
            stroke-width: 0.1vw;
        }
    }
}

// feedback star

//upload avatar

.profile__logoWrapper {
    .ant-upload.ant-upload-select-picture-card>.ant-upload {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-upload.ant-upload-select-picture-card {
        margin: 0;
        width: 5.3vw;
        height: 5.3vw;
        border-radius: 50%;
        display: flex;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 9.8vw;
            height: 9.8vw;
        }

        @media only screen and (max-width: 499px) {
            width: 20.1vw;
            height: 20.1vw;
        }
    }

    .ant-upload.ant-upload-select-picture-card>* {
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        color: $greyLight;
        transition: all 0.3s;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
        }
    }

    .ant-upload.ant-upload-select-picture-card:hover>* {
        color: $blueDark !important;
        transition: all 0.3s;
    }

    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: $blueDark;
        transition: all 0.3s;
    }
}

.disabledVerifyBlock {
    user-select: none;
    pointer-events: none;

}

.activeVerifyBlock,
.disabledVerifyBlock {
    height: 7.4vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: 14vw;
    }

    @media only screen and (max-width: 499px) {
        height: 28vw;
        margin-bottom: 13vw;
    }

    input {
        opacity: 0;
        width: inherit;
        height: inherit;
    }
}

.reactModal {
    .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
}

.verification__upload {
    display: flex;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        align-items: center;
    }


    @media only screen and (max-width: 499px) {
        align-items: center;
        margin-bottom: 20vw;
    }

    .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .ant-upload div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .verification__upload_svg {
        width: 2vw;
        height: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 6vw;
            height: 6.4vw;
        }

        @media only screen and (max-width: 499px) {
            width: 10vw;
            height: 10.4vw;
        }
    }

    .ant-upload.ant-upload-select-picture-card:hover {
        .verification__upload_svg path {
            stroke: $blueDark;
            transition: all 0.3s;
        }
    }

    input {
        opacity: 0;
        width: inherit;
        height: inherit;
        cursor: pointer;
        z-index: 10;
        position: relative;
    }
}

.ant-upload.ant-upload-select-picture-card {
    width: 7.4vw;
    height: 7.4vw;
    margin: 0;
    border: 0.1vw dashed #d9d9d9;
    transition: all 0.25s;

    &:hover {
        border: 0.1vw dashed $blueDark;
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 14vw;
        height: 14vw;
        border: 2px dashed #d9d9d9;
    }

    @media only screen and (max-width: 499px) {
        width: 28vw;
        height: 28vw;
        border: 2px dashed #d9d9d9;
    }
}

.verification__upload_done {
    user-select: none;
    pointer-events: none;

    div:first-of-type {
        border-color: #007aff;
        transition: all 0.3s;
        background: #e5f1ff;
    }

    .verification__upload_svg path {
        stroke: $blueDark;
        transition: all 0.3s;
    }

    .ant-upload.ant-upload-select-picture-card {
        border-color: $blueDark;
        transition: all 0.3s;
    }

    .ant-upload {
        background: #e5f1ff;
    }
}

//upload avatar

// ant messages

.ant-message-notice {
    &:first-child {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: 6.1vw;
        }

        @media only screen and (max-width: 499px) {
            margin-top: 16vw;
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        &-content {
            padding: $tablet26px $tablet50px;
        }
    }

    @media only screen and (max-width: 499px) {
        &-content {
            padding: $mobile25px $mobile55px;
        }
    }
}

.ant-message-custom-content {

    span {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
        }
    }

    i {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet20px;
            margin-right: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile20px;
            margin-right: $mobile10px;
        }
    }
}

// ant messages

// radio button

.ant-radio-wrapper {
    display: flex;
    align-items: center;
    margin-top: 2.5vw;

    &>* {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
    }

    &:hover {
        .ant-radio-inner {
            border-color: #1890ff;
        }
    }
}

span.ant-radio+* {
    padding: 0 1vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
        line-height: $tablet16px;
        padding: 0 $tablet24px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile16px;
        padding: 0 $mobile24px;
    }
}

// radio button

// switch button

.ant-switch {
    background-color: $greyButton;
    @media only screen and (max-width: 499px) {
        min-width: 50.2px;
    }
}

.ant-switch-checked {
    background-color: $greenLight !important;
}

.ant-switch-inner {
    @media only screen and (max-width: 499px) {
        margin-left: 20px;
    }
}

// switch button

// ant notification

.ant-notification {
    z-index: 999999;

    @media only screen and (max-width: 499px) {
      display: none;
    }

    &-notice-description {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 1.5vw;
            line-height: 1.5vw;
        }
    }

    &-notice-message {
        font-family: $MontserratRegular;
        color: $black;
        text-transform: uppercase;
    }
}

// ant notification

.filterFooter {
    margin-top: auto;
}

#scrollOnChat {
    height: calc(100vh - 10.7vw);
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        width: 0;
    }
}

#root .__react_modal_image__modal_container {
    z-index: 9999999;
}

// modal overlay

.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10001;

    @media only screen and (max-width: 499px) {
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.ReactModal__Body--open #root {
    filter: grayscale(100%);
}

// modal overlay

// showHide-transition
.showHide-transition-enter,
.showHide-transition-appear {
    top: 0;
    max-height: 0.1vw;
}

.showHide-transition-enter-active,
.showHide-transition-appear-active {
    top: 0.1vw;
    max-height: 200px;
    transition: all 400ms;
}

.showHide-transition-exit {
    top: 0.1vw;
    max-height: 200px;
}

.showHide-transition-exit-active {
    top: 0;
    max-height: 0.1vw;
    transition: all 400ms;
}
//showHide-transition


// date picker
.react-datepicker {
    border: 1px solid #efefef;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;

    &__day--keyboard-selected {
        background-color: #007aff;
    }

    &__header {
        background-color: #f2f3f9;
        border-bottom: 1px solid #efefef;
    }
}
.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before {
    top: inherit;
}
// date picker


// tooltip
.ant-tooltip {
    max-width: 255px;
}

.ant-tooltip-inner {
    background-color: #007aff;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    border-top-color: #007aff;
}

.ant-tooltip-arrow::before {
    background-color: #007aff;
}

// tooltip

.rc-pagination-disabled {
    opacity: 0 !important;
    user-select: none;
    pointer-events: none;
}

// remove input number arrows firefox

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

// remove input number arrows firefox

.ant-drawer-body {
    @media only screen and (max-width: 499px) {
        overflow-y: scroll;
    }
}

// ant-tabs-tab

.ant-tabs-tab {
    font-family: $MontserratRegular;
    font-size: $desctop18px;
    line-height: $desctop20px;
    color: #828282;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet18px;
        line-height: $tablet20px;
    }

    @media only screen and (max-width: 499px) {
        font-size: $mobile20px;
        line-height: $mobile24px;
    }
}

// ant-tabs-tab
