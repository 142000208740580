@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.orders {
    padding: 0 7.85vw 5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet30px;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 $mobile25px $mobile30px;
    }

    &__wrapper {
        margin: 0 0 4vw;

        @media only screen and (max-width: 499px) {
            margin: 0;
        }
    }

    &__filter {
        padding: 2vw 7.85vw 0 7.85vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet60px $tablet88px 0 $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile30px $mobile25px 0 $mobile25px ;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
    }

    &__allOrdersButton {
        @include mainBtnStyle;
        @include greenBtnWithBorderAndBackground;
        font-family: $MontserratRegular;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        width: -moz-max-content;
        height: 3.15vw;
        margin: 3vw auto 0 auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet18px $tablet24px;
            margin-top: $tablet40px;
            height: $tablet48px;
        }
    }
}
