@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.profile {
    width: 74.6vw;
    margin: 2vw 7.85vw 3.85vw 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin: $tablet20px $tablet88px $tablet20px;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        margin: $mobile20px $mobile25px 0 $mobile25px;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile30px;
        }
    }

    &__logoAndBlockAccountWrapper {
        display: flex;
        margin-bottom: 4vw;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile42px;
        }
    }

    &__personalInfoWrapper {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: 1024px) {
            position: relative;
        }
    }

    &__logo {
        width: calc(5.3vw - 0.1vw);
        height: calc(5.3vw - 0.1vw);
        border-radius: 50%;
        object-fit: cover;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 9.8vw;
            height: 9.8vw;
        }

        @media only screen and (max-width: 499px) {
            width: 20.1vw;
            height: 20.1vw;
        }
    }

    &__userName {
        margin-top: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
        }
    }

    &__content {

        &_item,
        &_itemNumber {
            width: 25%;
            margin-bottom: 2vw;
            display: flex;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 50%;
                margin-bottom: $tablet40px;
            }

            @media only screen and (max-width: 499px) {
                width: 50%;
                margin-bottom: $mobile42px;
            }

            span {
                width: fit-content;
                width: -moz-max-content;
                position: relative;
            }
        }

        &_item {
            flex-direction: column;
            justify-content: flex-end;

            @media only screen and (max-width: 499px) {
                padding-right: $mobile10px;
            }
        }

        &_accountStatus {
            @media only screen and (max-width: 1024px) {
                order: 1;
            }
        }

        &_uploadDocuments {
            @media only screen and (max-width: 1024px) {
                order: 2;
            }
        }

        &_itemNumber {
            align-items: flex-end;
            white-space: nowrap;

            @media only screen and (max-width: 1024px) {
                order: 3;
            }

            @media only screen and (max-width: 499px) {
                white-space: pre-line;
            }
        }

        &_itemStars {

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                position: absolute;
                top: -8.6vw;
                right: 0;
            }

            @media only screen and (max-width: 499px) {
                position: absolute;
                top: -20.4vw;
                right: 0;
            }

        }

        &_title {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            color: $greyLight;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                margin-bottom: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_subTitle,
        &_subTitleBlue,
        &_subTitleRed {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
            }
        }

        &_subTitle {
            color: $textDarkLight;
        }

        &_subTitleBlue {
            color: $blueDark;
        }

        &_subTitleRed {
            color: $red;
        }

        &_upload {
            cursor: pointer;

            &:hover {
                color: $greenLight;
                transition: all 0.25s;
            }
        }

        &_phoneNumberinput {
            width: 13vw;
            padding: 0 1.1vw 0 0;

            @media only screen and (max-width: 499px) {
                width: 100%;
                padding: 0;
            }
        }
    }

    &__unBlockAccount,
    &__blockAccount {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.4vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 12.7vw;
        margin: 1vw 0 0 8.3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet176px;
            height: $tablet48px;
            margin: 2vw 0 0 26.2vw;
        }

        @media only screen and (max-width: 499px) {
            width: 40vw;
            height: 13vw;
            margin: 3.5vw 0 0 8.6vw;
            font-size: $mobile12px;
            line-height: $mobile12px;
            letter-spacing: 1px;
        }
    }

    &__blockAccount {
        @include redBtnWithOutBackground;
    }

    &__unBlockAccount {
        @include greenBtnWithOutBackground;
    }

    &__phoneNumber {
        border: none;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: $blueDark;
        width: 100%;
        max-width: 13vw;
        background-color: transparent;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            max-width: 100%;
            padding: 0;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            line-height: 1.5vw;
            color: $blueDark;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
            }
        }
    }

    &__noFeedbacksWrapper {
        margin-top: 4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet48px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile42px;
        }

        &_noFeedbacks {
            font-family: $MontserratRegular;
            font-size: 1.25vw;
            line-height: 2vw;
            color: $textDarkLight;
            text-align: center;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }
    }
}

.feedback {
    margin-top: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet40px;
    }

    @media only screen and (max-width: 499px) {
        margin-top: $mobile30px;
        margin: $mobile30px 0;
    }

    &__wrapper {
        display: flex;
        padding: 1.6vw 2vw;
        margin-bottom: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet40px $tablet30px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            padding: $mobile30px;
            margin-bottom: $mobile12px;
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        }

        &:hover {
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
            transition: all 0.25s;
        }

        &_img {
            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                order: 1
            }
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__img {
        width: 2.4vw;
        height: 2.4vw;
        border-radius: 50%;
        object-fit: cover;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet36px;
            height: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            width: 11.3vw;
            height: 11.3vw;
        }
    }

    &__nameTextWrapper {
        margin: 0 auto 0 2.1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 auto 0 $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin: 0;
            order: 3;
        }
    }

    &__name,
    &__text {
        font-family: $MontserratRegular;
        color: $textDarkLight;
    }

    &__name {
        font-size: 0.9vw;
        margin-bottom: 1vw;
        width: fit-content;
        width: -moz-max-content;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet10px;
            font-size: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile10px;
            font-size: $mobile16px;
        }
    }

    &__text {
        font-size: 0.8vw;
        line-height: 1vw;
        width: 55vw;
        margin-top: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet16px;
            width: 45vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile16px;
            width: 100%;
        }
    }

    &__date {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: #9B9B9B;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            order: 2;
            margin-bottom: $mobile10px;
        }
    }

    &__ratingTitle {
        font-family: $MontserratRegular;
        font-size: 0.9vw;
        color: #505761;
        margin-bottom: 0.5vw;
        width: fit-content;
        width: -moz-max-content;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 1.7vw;
            margin-bottom: 1vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            margin-bottom: $mobile10px;
        }
    }
}

.allCards {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 2.1vw 0vw 0;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            justify-content: space-between;
            padding: 2.1vw 0 0;
        }

        @media only screen and (max-width: 499px) {
            padding: 0;
            justify-content: center;
        }
    }
}

.card {
    position: relative;
    width: 17.4vw;
    height: 10.8vw;
    color: #555;
    border-radius: $desctop14px;
    box-shadow: 0 0 $desctop20px rgba(0, 0, 0, 0.2);
    padding: 1.3vw 1.5vw 1.5vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;


    &:not(:nth-child(3n)) {
        margin-right: 2.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: 0;
        }

        @media only screen and (max-width: 499px) {
            margin-right: 0;
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: 22vw;
        width: 37vw;
        border-radius: $tablet14px;
        box-shadow: 0 0 $tablet20px rgba(0, 0, 0, 0.2);
        padding: $tablet26px;
    }

    @media only screen and (max-width: 499px) {
        width: 78vw;
        height: 50vw;
        border-radius: $mobile16px;
        box-shadow: 0 0 $mobile20px rgba(0, 0, 0, 0.2);
        padding: $mobile25px;
        margin: 0 0 10vw 0;
    }

    &__chip {
        width: $desctop30px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            width: $mobile42px;
            height: $mobile42px;
        }
    }

    &__logo {
        width: 4.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 8.5vw;
        }

        @media only screen and (max-width: 499px) {
            width: 20vw;
            height: $mobile42px;
        }
    }

    &__number {
        margin: auto 0;
        font-size: 1.1vw;
        line-height: 1.5vw;
        word-spacing: -0.28vw;

        @media only screen and (max-width: 499px) {
            font-size: 4.9vw;
            line-height: 6.5vw;
            word-spacing: -1vw;
        }
    }

    &__name {
        font-size: 1vw;
        text-transform: uppercase;
        line-height: 1;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 85%;

        @media only screen and (max-width: 499px) {
            font-size: 4vw;
        }
    }

    &__number,
    &__name {
        font-family: Consolas, Courier, monospace;
        opacity: 0.8;
        border: none;
        outline: none;
        background-color: transparent;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.2vw;
            line-height: 2.7vw;
        }
    }

    &__topWrapper,
    &__bottomWrapper {
        display: flex;
        justify-content: space-between;
    }

    &__bottomWrapper {
        align-items: flex-end;
    }

    &__deleteIcon {
        width: 1.2vw;
        height: 1.2vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 2.4vw;
            height: 2.4vw;
        }

        @media only screen and (max-width: 499px) {
            width: 4.4vw;
            height: 4.4vw;
        }
    }
}

.cards {
    margin: 2vw 0 6vw;

    @media only screen and (max-width: 499px) {
        margin: 14vw 0 6vw;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin-bottom: 10vw;
        }
    }

    &__input {
        width: 24.2vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1vw;
        line-height: 1.4vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            width: 90%;
            height: $tablet60px;
            margin-top: $tablet10px;
            border-radius: 1px;
            padding-left: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: inherit;
            height: $mobile60px;
            margin: $mobile10px 0 $mobile16px 0;
            border-radius: 1px;
            padding-left: $mobile10px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.6vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: 6vw;
            }
        }

        &_error {
            bottom: -2.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                bottom: -3.5vw;
            }

            @media only screen and (max-width: 499px) {
                bottom: 0;
            }
        }

        &_errorCardNumber {
            @media only screen and (max-width: 499px) {
                bottom: 0;
            }
        }
    }

    &__label,
    &__subLabel {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            white-space: nowrap;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            white-space: nowrap;
        }
    }

    &__subLabel {
        margin-top: 0.5vw;
    }

    &__wrapper {
        margin-right: 9.1vw;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: 4vw;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            margin-right: 0;
            width: 100%;
        }

        &_cardHolderName {
            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin-top: 8vw;
            }
        }
    }
}

.inputWrapper {

    &__invalid {
        color: $red;
        bottom: 0;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: 100%;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }
}
