@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.newOrder {
    width: 74.6vw;
    margin: 2vw 7.85vw 2vw 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile30px;
        }

    }

    &__row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            flex-wrap: wrap;
            align-items: flex-end;
            margin-bottom: 0;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }
        }

        &:not(:last-child) {
            margin-bottom: 3.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: 0;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 6vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet80px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: 0;
            }
        }
    }

    &__path {
        position: absolute;
        right: 5vw;
        width: 27vw;
        bottom: -2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30vw;
            bottom: -4vw;
        }

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__setPrice {
        display: flex;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            flex-wrap: wrap;
        }

        &_leftSide,
        &_rightSide {
            display: flex;
            align-items: flex-start;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                align-items: flex-start;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &_leftSide {
            margin-right: 8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: 0;
            }

            @media only screen and (max-width: 499px) {
                margin: 0;
            }
        }

        &_leftSideWrapper {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                align-items: flex-start;
                flex-direction: column;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &_rightSideDisabled {
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        }

        &_underInput {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;
            margin-top: 1vw;
            transform: translateY(1vw);

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                margin-top: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                margin-top: $mobile10px;
            }
        }
    }

    &__btnSubmit {
        @include mainBtnStyle;
        @include greenBtnWithBorderAndBackground;
        font-family: $MontserratRegular;
        height: 3.15vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 11.7vw;
        margin-top: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: $tablet30px 0;
            width: 31.3vw;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: 16vw;
            font-size: 3.2vw;
            line-height: 3.2vw;
            margin-bottom: 0;
            margin: $mobile30px 0;
        }
    }

    &__WrapperRow {
        @media only screen and (max-width: 499px) {
           display: flex;
           flex-direction: column;
        }
    }

    &__marketPriceInputWrapper {
        margin-bottom: 2vw;
        display: flex;
        white-space: nowrap;
        justify-content: space-between;
        margin-right: 8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 0 5.21vw 0;

        }

        @media only screen and (max-width: 499px) {
            margin: 0;
            flex-direction: column;
            margin-bottom: 7vw;
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            display: flex;
            align-items: center;
            padding: 0 1vw 0 2.1vw;
            margin-right: 8px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.1vw;
                line-height: 2.1vw;
                padding: 0 5.3vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: 2.67vw;
                padding: 0;
                line-height: 2.67vw;
            }
        }

        &_value {
            width: 15.6vw;
            height: 3.91vw;
            line-height: 1.1vw;
            background: $inputColor;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid $inputColor;
            outline: none;
            padding: 0 1vw;
            display: flex;
            align-items: center;
            opacity: 0.6;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: 7.82vw;
                line-height: 7.82vw;
                font-size: 2.1vw;
                padding: 1.6vw;
                max-width: 45% !important;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: 16vw;
                line-height: 16vw;
                margin-top: 2.67vw;
                font-size: 4.3vw;
                padding: 3.2vw;
            }
        }
    }

    &__marketPriceFee {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
    }
}

.inputWrapper {
    &__invalid {
        color: $red;
        bottom: -1.3vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: fit-content;
        width: -moz-max-content;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }
}
