@import '../../assets/styles/variables';

.emptyFilterResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: 0;
    }

    @media only screen and (max-width: 499px) {
        margin-top: 0;
    }

    &_text,
    &_link {
        font-family: $MontserratRegular;
        font-size: 1.25vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
        }
    }

    &_text {
        color: $textDarkLight;
        margin-right: 0.5vw;

        @media only screen and (max-width: 499px) {
            margin-right: 5px;
        }
    }

    &_link {
        color: $blueDark;
        cursor: pointer;

        &::first-letter {
            text-transform: lowercase;
        }
    }

    &_createYourOwn {
        display: flex;
        margin-top: 1vw;

        @media only screen and (max-width: 499px) {
            margin-right: 10px;
        }
    }
}
