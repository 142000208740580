.newOrder__wrapperPage .filterBlock-select .ant-select-selection {
  width: 20.8vw; }
  @media only screen and (max-width: 1024px) {
    .newOrder__wrapperPage .filterBlock-select .ant-select-selection {
      width: 100%; } }

.newOrder__wrapperPage .filterBlock-select__wrapper-input {
  max-width: 20.8vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .newOrder__wrapperPage .filterBlock-select__wrapper-input {
      width: 100%;
      max-width: 30%; } }
  @media only screen and (max-width: 499px) {
    .newOrder__wrapperPage .filterBlock-select__wrapper-input {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0; } }

.newOrder__wrapperPage .filterBlock-select__wrapper-input {
  flex-direction: column; }

.newOrder__wrapperPage .filterBlock-select__wrapper_item {
  width: 20.8vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .newOrder__wrapperPage .filterBlock-select__wrapper_item {
      width: 30%; } }
  @media only screen and (max-width: 499px) {
    .newOrder__wrapperPage .filterBlock-select__wrapper_item {
      width: 100%;
      margin-bottom: 8vw; } }

.newOrder__marketPriceFields {
  max-height: 18vw;
  top: 0.1vw;
  transition: max-height 400ms;
  opacity: 1; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .newOrder__marketPriceFields {
      width: 100%;
      max-height: 41vw; } }
  @media only screen and (max-width: 499px) {
    .newOrder__marketPriceFields {
      width: 100%;
      max-height: 90vw; } }

.newOrder__marketPriceDisableFields {
  top: 0;
  max-height: 0.1vw;
  transition: max-height 400ms;
  overflow: hidden;
  opacity: 0; }

.newOrder__wrapper_input {
  width: 20.8vw;
  height: 3.91vw;
  line-height: 1.1vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .newOrder__wrapper_input {
      width: 100%;
      height: 7.82vw;
      line-height: 7.82vw;
      margin-top: 1.31vw;
      font-size: 2.1vw;
      padding: 1.6vw;
      border: 1px solid #f2f3f9; } }
  @media only screen and (max-width: 499px) {
    .newOrder__wrapper_input {
      width: 100%;
      max-width: 100%;
      border: 1px solid #f2f3f9;
      height: 16vw;
      line-height: 16vw;
      margin-top: 2.67vw;
      font-size: 4.3vw;
      padding: 3.2vw; } }
  .newOrder__wrapper_input:hover, .newOrder__wrapper_input:active, .newOrder__wrapper_input:focus {
    border: 1px solid #007aff; }
    @media only screen and (max-width: 1024px) {
      .newOrder__wrapper_input:hover, .newOrder__wrapper_input:active, .newOrder__wrapper_input:focus {
        border: 1px solid #007aff; } }
  .newOrder__wrapper_input::placeholder {
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
    color: #92969c; }
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      .newOrder__wrapper_input::placeholder {
        font-size: 2.1vw;
        border-radius: 2px; } }
    @media only screen and (max-width: 499px) {
      .newOrder__wrapper_input::placeholder {
        border-radius: 2px;
        font-size: 4.3vw; } }

.newOrder__setPrice_input {
  width: 15 .6vw;
  height: 3.91vw;
  line-height: 1.1vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .newOrder__setPrice_input {
      width: 100%;
      height: 7.82vw;
      line-height: 7.82vw;
      margin-top: 1.31vw;
      font-size: 2.1vw;
      padding: 1.6vw; } }
  @media only screen and (max-width: 499px) {
    .newOrder__setPrice_input {
      width: 100%;
      max-width: 100%;
      border: 1px solid #f2f3f9;
      height: 16vw;
      line-height: 16vw;
      margin-top: 2.67vw;
      font-size: 4.3vw;
      padding: 3.2vw; } }
  .newOrder__setPrice_input:hover, .newOrder__setPrice_input:active, .newOrder__setPrice_input:focus {
    border: 1px solid #007aff; }
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      .newOrder__setPrice_input:hover, .newOrder__setPrice_input:active, .newOrder__setPrice_input:focus {
        border: 1px solid #f2f3f9;
        padding: 1.6vw; } }
  .newOrder__setPrice_input::placeholder {
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
    color: #92969c; }
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      .newOrder__setPrice_input::placeholder {
        font-size: 2.1vw;
        border-radius: 2px; } }
    @media only screen and (max-width: 499px) {
      .newOrder__setPrice_input::placeholder {
        font-size: 4.3vw !important;
        border-radius: 2px; } }

@media only screen and (max-width: 499px) {
  .newOrder__row_paymentWindow {
    order: 3;
    margin-bottom: 8vw !important; } }

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .newOrder__row_paymentMethod, .newOrder__row_firstCryptocurrency, .newOrder__row_whichToTradeWith {
    position: relative; }
  .newOrder__row_limitsFrom, .newOrder__row_limitsTo {
    margin-bottom: 0; } }

@media only screen and (max-width: 499px) {
  .newOrder__row_limitsFrom {
    margin-bottom: 8vw !important; }
  .newOrder__row_buyOrSell, .newOrder__row_whichToTradeWith, .newOrder__row_paymentMethod, .newOrder__row_firstCryptocurrency, .newOrder__row_location, .newOrder__row_whichToTradeWith {
    position: relative; }
  .newOrder__row_cardholder {
    order: -1;
    margin-bottom: 8vw !important; } }

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .setPrice {
    width: 100%;
    padding-left: 5.21vw; } }

@media only screen and (max-width: 499px) {
  .setPrice {
    width: 100%; } }

.setPrice .filterBlock-select__wrapper-input {
  max-width: 15.6vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .setPrice .filterBlock-select__wrapper-input {
      max-width: 45% !important;
      width: 100%;
      margin-bottom: 0; } }
  @media only screen and (max-width: 499px) {
    .setPrice .filterBlock-select__wrapper-input {
      max-width: 100% !important;
      width: 100%; } }

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .setPrice .ant-radio-wrapper {
    margin-right: auto;
    margin-top: 6vw; } }

@media only screen and (max-width: 499px) {
  .setPrice .ant-radio-wrapper {
    margin-bottom: 11vw; } }

.disableBlock {
  opacity: 0.6;
  user-select: none;
  pointer-events: none; }

[class*="fontawesome-"]:before {
  font-family: 'fontawesome', sans-serif; }

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.select-search-box {
  position: relative;
  transition: transform 0.3s; }

.select-search-box::after {
  font-family: fontawesome;
  content: url("./images/arrow.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1vw;
  width: 0.8vw;
  height: 0.8vw;
  text-align: center;
  color: #222f3e;
  z-index: 1;
  transition: transform 0.3s; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .select-search-box::after {
      font-size: 1.6vw;
      right: 1.6vw !important;
      width: 1.6vw !important;
      height: 1.6vw !important; } }
  @media only screen and (max-width: 499px) {
    .select-search-box::after {
      font-size: 3.2vw;
      right: 3.2vw !important;
      width: 2.6vw !important;
      height: 2.6vw !important; } }

.newOrder__row_location .select-search-box__search,
.newOrder__row_location .select-search-box__select {
  max-width: 100%; }

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: url("./images/arrow.svg");
  transform: rotate(180deg) translateY(0.5vw);
  transition: transform 0.3s; }

.select-search-box__icon--disabled::after {
  content: none !important; }

.select-search-box--input::after {
  display: none !important; }

.select-search-box__out {
  display: none; }

.select-search-box__search {
  width: 20.8vw;
  height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .select-search-box__search {
      width: 100% !important;
      height: 7.82vw !important;
      font-size: 2.1vw !important;
      padding: 1.6vw !important;
      border: 1px solid #f2f3f9; } }
  @media only screen and (max-width: 499px) {
    .select-search-box__search {
      width: 100% !important;
      max-width: 100%;
      height: 16vw !important;
      font-size: 4.3vw !important;
      padding: 3.2vw !important; } }
  .select-search-box__search:hover, .select-search-box__search:active, .select-search-box__search:focus {
    border: 1px solid #007aff; }
    @media only screen and (max-width: 1024px) {
      .select-search-box__search:hover, .select-search-box__search:active, .select-search-box__search:focus {
        border: 1px solid #007aff; } }
  .select-search-box__search::placeholder {
    background: #f2f3f9;
    border-radius: 0.1vw;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
    color: #92969c; }
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      .select-search-box__search::placeholder {
        font-size: 2.1vw !important;
        border-radius: 2px; } }
    @media only screen and (max-width: 499px) {
      .select-search-box__search::placeholder {
        font-size: 4.3vw !important;
        border-radius: 2px; } }

input.select-search-box__search {
  line-height: 1; }

.select-search-box--multiple .select-search-box__search {
  box-shadow: none; }

.select-search-box--input .select-search-box__search {
  cursor: text; }

.select-search-box__search:focus {
  cursor: text; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

.select-search-box__select {
  display: none;
  position: absolute;
  top: 4.2vw;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 9999;
  box-sizing: border-box;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 20.8vw; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .select-search-box__select {
      top: 8.2vw !important;
      width: 100% !important; } }
  @media only screen and (max-width: 499px) {
    .select-search-box__select {
      top: 17.2vw !important;
      width: 100% !important; } }

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-radius: 0;
  box-shadow: none; }

.select-search-box__select--display {
  display: block; }

.select-search-box__option {
  width: 100%;
  height: 3.91vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  line-height: 1.1vw;
  color: #92969c;
  cursor: pointer; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .select-search-box__option {
      height: 7.82vw !important;
      font-size: 2.1vw !important;
      line-height: 3.39vw !important;
      padding: 1.6vw !important; } }
  @media only screen and (max-width: 499px) {
    .select-search-box__option {
      height: 16vw !important;
      font-size: 4.3vw !important;
      line-height: 6.7vw !important;
      padding: 0 3.2vw !important; } }
  .select-search-box__option:first-child, .select-search-box__option:last-child {
    border-radius: 0.3vw 0.3vw 0 0; }

.select-search-box__option:first-child {
  border-top: none; }

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa; }

.select-search-box__option--selected {
  background: linear-gradient(0deg, #007aff, #007aff);
  border: 1px solid #007aff;
  border-radius: 0;
  color: #ffffff;
  font-weight: 400; }
  @media only screen and (max-width: 1024px) {
    .select-search-box__option--selected {
      border: 1px solid #007aff; } }

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #007aff;
  color: #ffffff;
  border-top-color: #007aff; }

.ant-select-dropdown a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; }

.coinSellInput {
  transform: translateX(26.8vw);
  position: relative;
  z-index: 10; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .coinSellInput {
      transform: translateX(27vw); } }
  @media only screen and (max-width: 499px) {
    .coinSellInput {
      transform: translateX(0);
      transform: translateY(29vw); } }

.coinBuyInput {
  transform: translateX(-26.8vw);
  position: relative;
  z-index: 10; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .coinBuyInput {
      transform: translateX(-27vw); } }
  @media only screen and (max-width: 499px) {
    .coinBuyInput {
      transform: translateX(0);
      transform: translateY(-29vw); } }

.inputWrapper__invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: fit-content;
  width: -moz-max-content;
  position: absolute; }
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    .inputWrapper__invalid {
      font-size: 1.31vw;
      line-height: 1.31vw;
      bottom: -15px; } }
  @media only screen and (max-width: 499px) {
    .inputWrapper__invalid {
      font-size: 2.67vw;
      line-height: 2.67vw;
      bottom: -15px; } }

.cardDisabled {
  opacity: 0.6;
  user-select: none;
  pointer-events: none; }

.editPage .newOrder__row_buyOrSell {
  user-select: none;
  pointer-events: none;
  opacity: 0.6; }
