@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        align-items: flex-start;
    }

    @media only screen and (max-width: 499px) {
        padding: 15vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100vh;
        overflow: scroll;
    }

    &__form {
        width: 26vw;

        @media only screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.55vw;
        color: $textDarkLight;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            top: $tablet26px;
            right: $tablet26px;
        }

        @media only screen and (max-width: 499px) {
            top: $mobile25px;
            right: $mobile25px;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }

    &__inputWrapper {
        margin-bottom: 2vw;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }

        &_eye {
            position: absolute;
            right: 1vw;
            top: 2.7vw;
            cursor: pointer;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: 50%;
                right: $tablet10px;
                width: $tablet30px;
                height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                top: 50%;
                right: 4vw;
                width: $mobile30px;
                height: $mobile20px;
            }

            img {
                @media only screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }

    &__input {
        width: 26vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding: 0 4vw 0 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet24px;
            padding-left: $tablet10px;
            margin-top: $tablet10px;
            width: 100%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            padding-left: $mobile10px;
            margin-top: $mobile10px;
            width: 100%;
            height: $mobile60px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.8vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
            }
        }
    }

    &__button,
    &__switchRegistrButton {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.9vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 11.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            font-size: $tablet12px;
            line-height: $tablet12px;
            letter-spacing: 1px;
            width: 45%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
            width: 100%;
        }
    }

    &__button {
        @include blueBtnWithBorderAndBackground;
    }

    &__switchRegistrButton {
        @include blueBtnWithOutBackground;
    }

    &__buttonWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__passwordRecovery {
        margin-right: auto;
        width: 100%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet30px;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;
            font-size: 0.8vw;
            cursor: pointer;
            width: max-content;
            margin-top: 2vw;
            user-select: none;
            padding-bottom: 0.1vw;
            border-bottom: 0.05vw solid $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                margin-top: 1vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }

        &_btn {
            @include mainBtnStyle;
            @include blueBtnWithOutBackground;
            font-family: $MontserratRegular;
            height: 3.9vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 11.7vw;
            margin-top: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                font-size: $tablet12px;
                line-height: $tablet12px;
                margin-top: $tablet30px;
                letter-spacing: 1px;
                width: 45%;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                margin-bottom: $mobile20px;
                letter-spacing: 1px;
                width: 100%;
            }
        }
    }

    &__twoFactorAuth {
        margin-top: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
        }

        &_title {
            font-family: $MontserratRegular;
            color: #4A4A4A;
            font-size: 0.8vw;
            line-height: 1.3vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }

        &_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-top: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                margin-top: $mobile20px;
            }
        }

        &_label {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;
            font-size: 0.8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }

        &_inputWrapper {
            position: relative;

            @media only screen and (max-width: 1024px) {
                width: 45%;
            }

            &_eye {
                position: absolute;
                right: 1vw;
                top: 2.7vw;
                cursor: pointer;
            }
        }

        &_input {
            width: 11.7vw;
            height: 3.9vw;
            margin-top: 0.5vw;
            background: $inputColor;
            border-radius: 0.1vw;
            border: none;
            padding: 0 1vw;
            font-size: 1vw;
            line-height: 1.4vw;
            outline: none;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet24px;
                padding-left: $tablet10px;
                margin-top: $tablet10px;
                width: 100%;
                height: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                padding-left: $mobile10px;
                margin-top: $mobile10px;
                width: 100%;
                height: $mobile60px;
            }

            &::placeholder {
                font-family: $MontserratRegular;
                font-size: 1vw;
                line-height: 3.9vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    line-height: $tablet24px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile16px;
                    line-height: $mobile25px;
                }
            }
        }

        &_submit {
            @include mainBtnStyle;
            @include blueBtnWithOutBackground;
            font-family: $MontserratRegular;
            height: 3.9vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 11.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
                font-size: $tablet12px;
                line-height: $tablet12px;
                letter-spacing: 1px;
                width: 45%;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: 1px;
                width: 45%;
            }
        }
    }
}
