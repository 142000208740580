@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin.scss';

.chatWrapper {
    display: flex;
    background-color: $cabinerBackColor;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
}

.allChats {
    width: 74.6vw;
    margin: 2vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2vw;
        padding-left: 1.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile20px;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__noMessages {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-top: 4vw;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.55vw;
            margin-top: 5vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: 4.55vw;
            margin-top: 9vw;
        }
    }

    &__item {
        display: flex;
        padding: 1.6vw 2vw;
        margin-bottom: 1vw;

        @media only screen and (max-width: 1024px) {
            padding: $tablet24px $tablet64px $tablet24px $tablet50px;
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile24px;
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
            margin-bottom: $mobile10px;
        }

        &:hover {
            background: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
            transition: all 0.25s;
        }

        &_img {
            width: 2.4vw;
            height: 2.4vw;
            border-radius: 50%;
            object-fit: cover;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet36px;
                width: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                height: 9.6vw;
                width: 9.6vw;
            }
        }
    }

    &__namePreview {
        margin: 0 auto 0 2.1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin: 0 auto 0 $mobile24px;
        }

        &_name,
        &_preview {
            font-family: $MontserratRegular;
            color: $textDarkLight;
        }

        &_name {
            font-size: 0.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }

        &_preview {
            font-size: 0.8vw;
            line-height: 1vw;
            width: 45vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet20px;
                width: 39vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile20px;
            }
        }
    }

    &__lastSeenMobile {
        display: none;
        padding: 4px 0 8px;

        @media only screen and (max-width: 499px) {
            display: block;
        }

        &_title,
        &_online {
            font-family: $MontserratRegular;
            font-size: 0.8vw;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }

        &_title {
            color: $greyButton;
        }

        &_online {
            color: $greenLight;
        }
    }

    &__lastSeen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        &_title,
        &_online {
            font-family: $MontserratRegular;
            font-size: 0.8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        &_title {
            color: $greyButton;
            white-space: nowrap;
        }

        &_online {
            color: $greenLight;
        }

        &_unreadMessages {
            font-family: $MontserratRegular;
            font-weight: 700;
            background: $red;
            color: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.3vw;
            height: 1.2vw;
            font-size: 0.6vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet20px;
                height: $tablet20px;
                font-size: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile20px;
                height: $mobile20px;
                font-size: $mobile10px;
            }
        }
    }
}

.chat {
    width: 57vw;
    height: calc(100vh - 10.95vw);

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 63%;
        height: calc(100vh - 21vw);
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
        height: calc(100vh - 27vw);
    }

    &__header {
        display: flex;
        border-bottom: 1px solid $borderColor;
        padding: 1.7vw 3.3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding-left: 11.5vw;
            padding-top: 20px;
            padding: $tablet20px $tablet20px $tablet20px $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile20px $mobile25px;
            position: relative;
        }

        &_logo {
            width: 2.4vw;
            height: 2.4vw;
            border-radius: 50%;
            object-fit: cover;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet36px;
                height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                width: 9.6vw;
                height: 9.6vw;
            }
        }

        &_user {
            margin-left: 1vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-left: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                margin: 0 0 0 $mobile12px;
                padding-right: 12vw;
            }
        }

        &_mobileMenu {
            display: none;

            @media only screen and (max-width: 499px) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                font-family: $MontserratRegular;
                font-size: $mobile12px;
                background-color: $inputColor;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 20vw;
                color: $greyButton;

                img {
                    margin-bottom: 1vw;
                    width: 4.3vw;
                    height: 4.3vw;
                }
            }
        }

        &_userWrapper {
            display: flex;
            align-items: center;
        }

        &_userName {
            font-family: $MontserratRegular;
            font-size: 1vw;
            color: #181c2f;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                display: flex;
                align-items: center;
                margin-bottom: $mobile10px;
            }
        }

        &_userStatusOnline,
        &_userStatusOffline,
        &_lastSeen {
            font-family: $MontserratRegular;
            font-size: 0.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }

        &_userStatusOnline {
            color: $greenLight;
        }

        &_userStatusOffline,
        &_dot {
            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        &_userStatusOffline,
        &_lastSeen {
            color: #9b9b9b;
        }

        &_dot {
            width: 0.3vw;
            height: 0.3vw;
            border-radius: 50%;
            background-color: #9b9b9b;
            margin: 0 0.6vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 6px;
                height: 6px;
                margin: 0 12px;
            }
        }
    }

    &__conversationBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 16.7vw);

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: calc(100vh - 29.9vw);
        }

        @media only screen and (max-width: 499px) {
            height: calc(100vh - 50vw);
        }
    }

    &__messagesList {
        overflow: auto;
        margin: 1.3vw 0 2vw 0;
        padding: 0 5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
            margin-bottom: 0;
            padding: 0 $tablet20px 0 $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: 0;
            margin-bottom: 7vw;
            padding: 0 $mobile25px;
        }

        li {
            margin-bottom: 1.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
            }

            &:first-child {
                @media only screen and (max-width: 499px) {
                    margin-top: $mobile20px;
                }
            }

        }

        &::-webkit-scrollbar {
            width: 0;
        }

        &_user,
        &_guest,
        &_moderator,
        &_moderatorPage {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
            border-radius: 1vw;;
            padding: 1.5vw;
            max-width: 80%;
            width: fit-content;
            width: -moz-max-content;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                padding: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                padding: $mobile24px $mobile20px $mobile24px $mobile30px;
            }

            &>* {
                font-family: $MontserratRegular;
                font-size: 0.9vw;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                }
            }
        }

        &_user {
            background: $blueDark;
            margin-left: auto;
            border-radius: 1.125rem 1.125rem 0 1.125rem;
            padding: 1rem 1.5rem;

            &>* {
                color: $white;
            }
        }

        &_guest {
            background: $white;
            margin-right: auto;
            border-radius: 1.125rem 1.125rem 1.125rem 0;
            padding: 1rem 1.5rem;

            &>* {
                color: $textDarkLight;
            }
        }

        &_moderator,
        &_moderatorPage  {
            background: $greenLight;
            &>* {
                color: $white;
            }
        }

        &_moderator {
            margin-right: auto;
            border-radius: 1.125rem 1.125rem 1.125rem 0;
            padding: 1rem 1.5rem;
        }

        &_moderatorPage {
            margin-left: auto;
            border-radius: 1.125rem 1.125rem 0 1.125rem;
            padding: 1rem 1.5rem;
        }

        &_userName {
            display: flex;
            font-size: 1vw;
            font-weight: 500;
            margin-bottom: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                margin-bottom: 5px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                margin-bottom: 4vw;
            }
        }

        &_message {
            line-height: 1.2vw;
            display: flex;
            flex-direction: column;
            word-wrap: break-word;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                line-height: 4.2vw;
            }
        }

        &_img {
            width: 100%;
            max-width: 15vw !important;
            margin-top: 1vw;

            @media only screen and (max-width: 499px) {
                max-width: 56vw !important;
            }
        }

        &_dot {
            margin-right: 0.7vw;
            display: flex;
            align-items: flex-end;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-right: $tablet14px;
            }

            @media only screen and (max-width: 499px) {
                margin-right: 3vw;
            }

            img {
                width: 1vw;
                height: 1vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    width: $tablet14px;
                    height: $tablet14px;
                }

                @media only screen and (max-width: 499px) {
                    width: 3.8vw;
                    height: 3.8vw;
                }
            }
        }
    }

    &__typeMessageWrapper {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        margin: 0 1vw 1.6vw 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0;
            padding: 0 $tablet18px $tablet24px $tablet18px;
        }

        @media only screen and (max-width: 499px) {
            margin: 0;
            padding: 0 $mobile25px $mobile12px $mobile25px;
        }

        &_input {
            width: 100%;
            height: 3.3vw;
            background: #f2f3f9;
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            color: $textDarkLight;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw 0 4.5vw;
            border-radius: 4vw;
            padding-right: 4vw;

            @media only screen and (max-width: 1024px) {
                border: 1px solid #f2f3f9;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet48px;
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: 0 8vw 0 8.5vw;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile42px;
                font-size: $mobile12px;
                line-height: $mobile12px;
                padding: 0 13vw 0 10.5vw;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 4vw;
                font-family: $MontserratRegular;
                font-size: 0.9vw;
                color: $greyButton;
                padding-left: 0.2vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet12px;
                    line-height: $tablet12px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile12px;
                    padding: 0 0 0 0.5vw;
                }
            }
        }

        &_label {
            display: flex;
        }

        &_submitButton {
            border: none;
            border-radius: 50%;
            width: 3.3vw;
            height: 3.3vw;
            background: $footerGradient;
            cursor: pointer;
            outline: none;
            padding: 0;
            text-transform: uppercase;
            transition: all 0.25s;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet48px;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile42px;
                height: $mobile42px;
                right: -1vw;
            }

            img {
                width: 1.45vw;
                height: 1.45vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    width: $tablet20px;
                    height: $tablet20px;
                }

                @media only screen and (max-width: 499px) {
                    width: $mobile20px;
                    height: $mobile20px;
                }
            }
        }

        &_attachFile {
            cursor: pointer;
            position: absolute;
            bottom: 0.85vw;
            left: 2.2vw;
            width: 1.65vw;
            height: 1.65vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet26px;
                height: $tablet26px;
                top: 4.5vw;
                left: 5.5vw;
            }

            @media only screen and (max-width: 499px) {
                top: 8.5vw;
                left: 9vw;
                width: 5.3vw;
                height: 5.3vw;
            }
        }
    }

    &__typeMessageInput {
        position: relative;
        margin-top: 0.5vw;
    }
}

.aside {
    height: calc(100vh - 10.95vw);
    overflow-y: scroll;
    width: 29.9vw;
    padding: 2.5vw 1.75vw 1vw 1.75vw;
    border-left: 1px solid $borderColor;
    background-color: $cabinerBackColor;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 37%;
        border-left: 1px solid $borderColor;
        height: calc(100vh - 21vw);
        padding: 5.05vw $tablet16px $tablet30px $tablet16px;
    }

    &__opened,
    &__closed {
        @media only screen and (max-width: 499px) {
            position: absolute;
            transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7),
            box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7),
        }
    }

    &__opened {
        @media only screen and (max-width: 499px) {
            transform: translateX(0);
            width: 100vw;
            padding: 0;
        }
    }

    &__closed {
        @media only screen and (max-width: 499px) {
            transform: translateX(-100%);
            width: 100vw;
            padding: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 0 0 1.75vw 0;
        margin-bottom: 1.6vw;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 22vw;
            height: 2vw;
            background-image: linear-gradient(to right, transparent 33%, $dottedLine 0%);
            background-position: bottom;
            background-size: 1.3vw 1px;
            background-repeat: repeat-x;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                background-size: 1.3vw 1px;
                width: 100%;
                height: 1px;
            }

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        &_traderInfo {
            display: flex;
            position: relative;
            margin-bottom: 1.6vw;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 22vw;
                height: 2vw;
                background-image: linear-gradient(to right, transparent 33%, $dottedLine 0%);
                background-position: bottom;
                background-size: 1.3vw 1px;
                background-repeat: repeat-x;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    background-size: 1.3vw 1px;
                    width: 100%;
                    height: 1px;
                }

                @media only screen and (max-width: 499px) {
                    width: 99vw;
                    height: 1px;
                }
            }

            @media only screen and (max-width: 499px) {
                padding: 20px 0 0 45px;
            }
        }

        &_traderWrapper {
            display: flex;
            flex-wrap: wrap;
            width: 45%;
            margin-right: 5%;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 60%;
            }
        }

        &_starRating {
            width: 50%;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 40%;
            }
        }

        &_trader,
        &_name {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            word-break: break-all;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile20px;
            }
        }

        &_trader {
            color: #9b9b9b;
            margin: 0 0.5vw 0.5vw 0;
        }

        &_name {
            color: $blueDark;
            margin-bottom: 0.5vw;
        }

        &_back {
            display: none;

            @media only screen and (max-width: 499px) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                font-family: $MontserratRegular;
                font-size: $mobile12px;
                background-color: $inputColor;
                height: 21.4vw;
                width: 20vw;
                color: $greyButton;
                margin-right: $mobile25px;

                img {
                    margin-bottom: 2vw;
                    width: 4.3vw;
                    height: 4.3vw;
                }
            }
        }

        &_wrapper {
            @media only screen and (max-width: 499px) {
                display: flex;
                border-bottom: 1px solid #efefef;
            }
        }
    }

    &__userInformation {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media only screen and (max-width: 499px) {
            padding: 20px 0 0 45px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 22vw;
            height: 3vw;
            background-image: linear-gradient(to right, transparent 33%, $dottedLine 0%);
            background-position: bottom;
            background-size: 1.3vw 1px;
            background-repeat: repeat-x;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                background-size: 1.3vw 1px;
                width: 100%;
                height: 1px;
            }

            @media only screen and (max-width: 499px) {
                width: 99vw;
                height: 1px;
            }
        }

        &_item,
        &_itemFull {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5vw;
        }

        &_item {
            width: 45%;
            margin-right: 5%;

            @media only screen and (max-width: 499px) {
                margin-bottom: 5vw;
            }
        }

        &_itemFull {

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 45%;
                margin-right: 5%;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: 5vw;
            }
        }

        &_title,
        &_subTitle {
            font-family: $MontserratRegular;
            font-size: 0.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile20px;
            }
        }

        &_title {
            color: #9b9b9b;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: 3vw;
            }
        }

        &_subTitle {
            color: $textDarkLight;
            line-height: 1.1vw;
        }
    }

    &__requestedPrice {
        display: flex;
        margin-top: 1.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            justify-content: center;
            margin-top: 3vw;
        }

        &_title,
        &_value,
        &_currency {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            color: #9b9b9b;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 1.6vw;
                line-height: 2.61vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile16px;
            }
        }

        &_value {
            margin: 0 0.3vw;
        }
    }

    &__changePrice {
        margin-top: 1.6vw;
        position: relative;
        width: 22vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }

        &_input {
            width: 100%;
            height: 3.91vw;
            background: #f2f3f9;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 7vw 0 1vw;

            @media only screen and (max-width: 1024px) {
                border: 1px solid #f2f3f9;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 100%;
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: 1px;
                padding: 0 20vw 0 5vw;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile12px;
                    letter-spacing: 1px;
                }
            }
        }

        &_label {
            display: flex;
        }

        &_currency {
            font-family: $MontserratRegular;
            font-size: 1vw;
            color: $textDarkLight;
            position: absolute;
            right: 1.5vw;
            top: 50%;
            transform: translateY(-50%);

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile16px;
            }
        }
    }

    &__counter {
        display: flex;
        align-items: center;
        width: 22vw;
        height: 3.91vw;
        margin-top: 1.6vw;
        background: #f2f3f9;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
        border-radius: 0.1vw;
        border: 1px solid #f2f3f9;
        outline: none;
        padding: 0 7vw 0 1vw;

        @media only screen and (max-width: 1024px) {
            border: 1px solid #f2f3f9;
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
            width: 100%;
            height: $tablet60px;
            font-size: $tablet16px;
        }


        @media only screen and (max-width: 499px) {
            height: 16vw;
            width: 100%;
            margin-bottom: 5vw;
            font-size: $mobile12px;
            line-height: $mobile12px;
            letter-spacing: 1px;
            padding: 0 20vw 0 5vw;
        }
    }

    &__havePaidCancelTradeWrapper,
    &__acceptDeclineWrapper,
    &__receivedNotReceivedWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__allButtonsWrapper {
        width: 22vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            padding: 20px 45px;
            width: 100%;
        }
    }

    &__btnRequestForPrice,
    &__btnIHavePaid,
    &__btnIHavePaidDisabled,
    &__btnCancelTradeDisabled,
    &__btnReceived,
    &__btnCancelTrade,
    &__notReceived,
    &__notReceivedDisable,
    &__btnCancelTradeFullWidth,
    &__btnCancelTradeFullWidthDisable,
    &__acceptPrice,
    &__declinePrice,
    &__btnNotReceivedFullWidth {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.91vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            width: 100%;
        }
    }

    &__btnRequestForPrice {
        @include greenBtnWithBorderAndBackground;
        width: 100%;

        @media only screen and (max-width: 499px) {
            margin-top: 5vw;
        }
    }

    &__btnIHavePaid,
    &__btnReceived {
        @include greenBtnWithBorderAndBackground;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__btnCancelTrade,
    &__declinePrice {
        @include redBtnWithBorderAndBackground;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__btnIHavePaidDisabled,
    &__btnCancelTradeDisabled {
        @include greyBtnWithBorderAndBackground;
        user-select: none;
        pointer-events: none;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__notReceived {
        @include redBtnWithBorderAndBackground;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__notReceivedDisable {
        @include greyBtnWithBorderAndBackground;
        width: 10vw;
        user-select: none;
        pointer-events: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__acceptPrice {
        @include blueBtnWithBorderAndBackground;
        width: 10vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }

    }

    &__btnCancelTradeFullWidth {
        @include redBtnWithBorderAndBackground;
        width: 100%;

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__btnCancelTradeFullWidthDisable {
        @include redBtnWithBorderAndBackground;
        width: 100%;
        background-color: $greyButton;
        pointer-events: none;
        user-select: none;

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
        }
    }

    &__btnNotReceivedFullWidth {
        @include greyBtnWithBorderAndBackground;
        width: 100%;
    }

    &__fullWidthCancelTradeWrapper,
    &__btnRequestForPrice,
    &__fullWidthNotReceivedWrapper {
        margin-top: 1.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
            width: 100%;
        }
    }

    &__canceledTradeText,
    &__closedTradeText {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        margin-top: 2vw;
        text-align: center;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile20px;
        }
    }

    &__canceledTradeText {
        color: $red;
    }

    &__closedTradeText {
        color: $greenLight;
    }
}

.feedback {
    position: relative;
    padding: 3.2vw 5.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 8vw;
    }

    @media only screen and (max-width: 499px) {
        padding: 10vw;
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (max-width: 499px) {
            top: 3.7vw;
            right: 3.7vw
        }
    }

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.7vw;
        line-height: 2vw;
        margin-bottom: 2vw;
        color: #1f1f1f;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin-bottom: $mobile20px;
        }
    }

    &__subTitle {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: $greyButton;
        margin-bottom: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile34px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__textarea {
        margin-top: 2.6vw;
        background: #f2f3f9;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
        border-radius: 0.1vw;
        border: 1px solid #f2f3f9;
        outline: none;
        padding: 1.5vw;
        width: 100%;
        height: 10vw;
        max-height: 10vw;
        min-height: 5vw;

        @media only screen and (max-width: 1024px) {
            border: 1px solid #f2f3f9;
        }

        @media only screen and (max-width: 499px) {
            height: 40vw;
            max-height: initial;
            font-size: $mobile16px;
            line-height: $mobile34px;
        }
    }

    &__submitBtnWrapper {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    &__submitBtn {
        @include blueBtnWithBorderAndBackground;
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.91vw;
        width: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            font-size: $tablet12px;
            line-height: $tablet12px;
            margin-bottom: $tablet20px;
            letter-spacing: 1px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin-bottom: $mobile20px;
            letter-spacing: 1px;
            width: 100%;
        }
    }
}

.aside__opened .aside__header {
    @media only screen and (max-width: 499px) {
        padding: 0;
        margin: 0;
    }
}
