@import '../../../assets/styles/variables';

.active {
    svg path {
        fill: $blueDark;
    }
}

.profile__logoWrapper .headerUserAvatar {
    width: 5.3vw;
    height: 5.3vw;
    font-size: 1.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 9.8vw;
        height: 9.8vw;
        font-size: 2.1vw;
    }

    @media only screen and (max-width: 499px) {
        width: 20.1vw;
        height: 20.1vw;
        font-size: 4.3vw;
    }
}

#aside {

    .active {
        .ant-badge {
            color: $blueDark;
        }
    }
    .ant-badge {
        display: flex;
        align-items: center;
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        color: $greyLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            letter-spacing: 1px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            letter-spacing: 1px;
        }

        &:hover {
            transition: all 0.25s;
            color: $blueDark;
        }
    }

    .ant-badge-count {
        border-radius: 50%;
        width: 1.4vw;
        height: 1.4vw;
        min-width: 1vw;
        padding: 0 0.1vw;
        font-size: 0.64vw;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -0.1vw;
        right: -0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 2.8vw;
            height: 2.8vw;
            min-width: 2.4vw;
            font-size: 1.1vw;
            top: -0.5vw;
            right: -1.4vw;
        }
    }
}
