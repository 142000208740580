@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.contactPage {
    display: flex;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        flex-direction: column;
        padding: $tablet20px $tablet88px $tablet10px;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile20px $mobile25px $mobile30px;
    }

    &__subtitleWrapper {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__leftSide {
        padding: 5.2vw 7.85vw 3.2vw 7.85vw;
        width: 35vw;

        @media only screen and (max-width: 1024px) {
            padding: 0;
            width: 100%;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 2.8vw;
            line-height: 3.35vw;
            margin-bottom: 1.2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet24px;
                line-height: $tablet36px;
                margin-bottom: $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
                line-height: $mobile34px;
                margin-bottom: $mobile20px;
            }
        }

        &_subtitle {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: 1.1vw;
            line-height: 1.9vw;
            margin-bottom: 4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                width: 45%;
                margin-bottom: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;

                &:last-child {
                    display: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: 2vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-bottom: $tablet60px;
                }

                @media only screen and (max-width: 499px) {
                    margin-bottom: $mobile42px;
                }
            }
        }

        &_subtitleMobile {
            font-family: $MontserratRegular;
            color: $greyLight;
            font-size: $mobile16px;
            line-height: $mobile25px;
            margin-bottom: $mobile42px;

            @media only screen and (min-width: 500px) {
                display: none;
            }
        }
    }

    &__rightSide {
        display: flex;
        width: 39.1vw;
        justify-content: space-between;
        padding-top: 9vw;

        @media only screen and (max-width: 1024px) {
            padding: 0;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }

        &_item {
            width: 45%;

            @media only screen and (max-width: 499px) {
                width: 100%;

                &:first-child {
                    margin-bottom: $mobile75px;
                }
            }
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            color: $textDarkLight;
            margin-bottom: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_mail {
            display: flex;
            align-items: center;
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            line-height: 1.2vw;
            color: #92969c;
            background: $inputColor;
            border-radius: 0.05vw;
            width: 18.55vw;
            height: 4vw;
            padding-left: 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                padding-left: $tablet12px;
                margin-top: $tablet10px;
                height: $tablet60px;
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile25px;
                padding-left: $mobile10px;
                margin-top: $mobile10px;
                height: $mobile60px;
                width: 100%;
            }
        }

        &_contactInfo {
            display: flex;
            flex-wrap: wrap;
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 1.9vw;
            color: $textDarkLight;
            width: 18.55vw;
            margin-top: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet26px;
                width: 100%;
                margin-top: $tablet40px;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                font-size: $mobile16px;
                line-height: $mobile25px;
                margin-top: $mobile20px;
            }

            span,
            a {
                color: $blueDark;
                margin-left: 0.3vw;
            }
        }
    }
}
