@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin.scss';

.settings {
    width: 74.6vw;
    margin: 2vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet64px 0 $tablet64px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1.55vw;
        line-height: 2vw;
        margin-bottom: 2.4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet32px;
            margin: 0 0 $tablet30px $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile32px;
            margin-bottom: $mobile30px;
        }
    }

    &__collapseTitle,
    &__verificationStyle {
        font-family: $MontserratRegular;
        font-size: 1.3vw;
    }

    &__collapseTitle {
        color: $textDarkLight;
    }

    &__verificationStyle {
        div {
            color: $blueDark !important;
        }
    }
}

.profile {
    padding: 0 7vw 2.1vw 3vw;
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet60px $tablet88px;
        flex-direction: column;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 6vw $mobile42px 6vw;
        flex-wrap: wrap;
    }

    &__leftSide {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__rightSide {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media only screen and (max-width: 1024px) {
            align-items: flex-start;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__inputWrapper {
        margin-bottom: 3.5vw;
        position: relative;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet26px;
            width: 46%;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile25px;
            width: 100%;
        }

        &_invalid {
            color: $red;
            bottom: -1.3vw;
            font-family: $MontserratRegular;
            font-weight: 400;
            font-size: 0.8vw;
            width: 100%;
            position: absolute;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                bottom: -15px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                bottom: -15px;
            }
        }
    }

    &__input {
        width: 24.2vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1vw;
        line-height: 1.4vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            width: 100%;
            height: $tablet60px;
            margin-top: $tablet10px;
            border-radius: 1px;
            padding-left: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
            height: $mobile60px;
            margin-top: $mobile10px;
            border-radius: 1px;
            padding-left: $mobile10px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.9vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet55px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile55px;
            }
        }

        &_email {
            opacity: 0.6;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            white-space: nowrap;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            white-space: nowrap;
        }
    }

    &__button {
        @include mainBtnStyle;
        @include greenBtnWithOutBackground;
        font-family: $MontserratRegular;
        height: 3.15vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 9.5vw;
        margin-top: 3vw;

        @media only screen and (max-width: 1024px) {
            display: none;
        }

        &_mobile {
            display: none;

            @media only screen and (max-width: 1024px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                min-width: 17.5vw;
                height: $tablet48px;
                margin-top: $tablet26px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 100%;
                font-size: $mobile12px;
            }
        }
    }
}

.uploadDocuments {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        display: none;
    }

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
    }

    &__mobile {
        display: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            display: flex;
        }
    }

    &_item,
    &_itemUpload {
        width: 45%;
        margin-bottom: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile20px;
        }

        span {
            width: fit-content;
            width: -moz-max-content;
            position: relative;
        }
    }

    &_itemUpload {
        p {
            margin-top: 1.8vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-top: 3vw;
            }
        }
    }

    &_title {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: $greyLight;
        margin-bottom: 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            margin-bottom: $mobile10px;
        }
    }

    &_subTitle,
    &_subTitleBlue,
    &_subTitleRed {
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        line-height: 1.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
        }
    }

    &_subTitle {
        color: $textDarkLight;
    }

    &_subTitleBlue {
        color: $blueDark;
    }

    &_subTitleRed {
        color: $red;
    }

    &_upload {
        cursor: pointer;

        &:hover {
            color: $greenLight;
            transition: all 0.25s;
        }
    }
}

.friendVerification {
    padding: 0 7vw 2.1vw 3vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet60px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 6vw $mobile42px 6vw;
        flex-wrap: wrap;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 7vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 9.5vw $tablet60px $tablet88px;
            justify-content: space-between;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 6vw $mobile42px 6vw;
            flex-wrap: wrap;
        }
    }

    &__leftSide {
        width: 52%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 60%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__rightSide {
        width: 21%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-top: $mobile20px;
        }
    }

    &__title,
    &__subTitle {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
        }

    }

    &__title {
        margin-bottom: 2.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
        }
    }

    &__subTitle {
        margin-bottom: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
        }
    }

    &__blueCode {
        font-family: $MontserratRegular;
        color: $blueDark;
        font-size: 1.2vw;
        margin: 0 0.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 1.8vw;
            margin: 0 5px;
        }

        @media only screen and (max-width: 499px) {
            font-size: 4.6vw;
            margin: 0 5px;
        }

    }

    &__mainText {
        font-family: $MontserratRegular;
        font-size: 0.9vw;
        line-height: 1.6vw;
        color: $greyLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            line-height: $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: 5.2vw;
        }

        &:not(:last-child) {
            margin-bottom: 2vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
            }
        }
    }

    &__verificationStatus {
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        color: #9b9b9b;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
        }
    }

    &__verified,
    &__notVerified {
        font-family: $MontserratRegular;
        font-size: 1.2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
        }
    }

    &__verified {
        color: $greenLight;
    }

    &__notVerified {
        color: $red;
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
        }
    }

    &__inputWrapper {
        margin: 2.2vw 0 2vw 0;
        position: relative;
    }

    &__input {
        width: 9.6vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding: 0 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            width: 100%;
            height: $tablet60px;
            margin-top: $tablet10px;
            border-radius: 1px;
            padding-left: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
            height: $mobile60px;
            margin-top: $mobile10px;
            border-radius: 1px;
            padding-left: $mobile10px;
        }

        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.9vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet55px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile55px;
            }
        }

        &::-moz-placeholder {
            line-height: 4vw;
        }
    }

    &__buttonSubmit,
    &__buttonRefresh {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.15vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 9.5vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            height: $tablet48px;
            margin-top: $tablet26px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
        }
    }

    &__buttonSubmit {
        @include greenBtnWithOutBackground;
    }

    &__buttonRefresh {
        @include blueBtnWithOutBackground;
    }
}

.verification {

    &__doneImg {
        position: absolute;
        right: 4.2vw;
        top: 2.6vw;
        width: 1vw;
        height: 1vw;
    }

    &__wrapper {
        padding: 0 20vw 2.1vw 3vw;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px 0 $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 $mobile25px 0 6vw;
            flex-wrap: wrap;
        }

        &_item {
            width: 7.4vw;
            height: 7.4vw;
            margin: 0;
            border: 0.1vw dashed #d9d9d9;
            transition: all 0.25s;
            position: relative;

            &:hover {
                border-color: #007aff;
                transition: all 0.3s;
                background: #e5f1ff;

                svg path {
                    stroke: #007aff;
                }
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                cursor: pointer;
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 14vw;
                height: 14vw;
            }

            @media only screen and (max-width: 499px) {
                width: 28vw;
                height: 28vw;
            }
        }
    }

    &__wrapperUpload {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vw;


        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: 3vw;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }
    }

    &__requiered {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: $tablet20px;
        }
    }

    &__fileIsntSelected {
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        color: $greyButton;
        margin: 1vw 0;
        text-align: center;
        height: 1.042vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet12px;
            margin: $tablet20px 0;
            height: fit-content;
            height: -moz-max-content;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            margin: $mobile20px;
            height: fit-content;
            height: -moz-max-content;
        }
    }

    &__uploadTitle,
    &__uploadTitleSelected {
        font-family: $MontserratRegular;
        font-size: 1.3vw;
        margin: 2vw 0 0 1.6vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet26px;
            margin: 0 0 0 1.91vw;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile25px;
            margin: 0 0 0 $mobile30px;
        }
    }

    &__uploadTitle {
        color: $textDarkLight;
    }

    &__uploadTitleSelected {
        color: $blueDark;
    }

    &__requiered {
        padding: 0 3vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 11.5vw 2vw 11.5vw;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 6.7vw 11vw 6vw;
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: $desctop14px;
            line-height: 1.5vw;
            color: $textDarkLight;
            margin-bottom: 1vw;
            text-indent: 1.5vw;
            text-align: justify;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet18px;
                margin: 0 0 $tablet10px 0;
                text-indent: 2.5vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.5vw;
                line-height: $mobile20px;
                margin: 0 0 $mobile20px 0;
                text-indent: 7.5vw;
            }
        }

        &_content,
        &_subContent {
            font-family: $MontserratRegular;
            font-size: $desctop12px;
            line-height: 1.5vw;
            color: $textDarkLight;
            text-align: justify;

            &:not(:last-child) {
                margin-bottom: 1vw;

                @media only screen and (max-width: 499px) {
                    margin: 0 0 $mobile20px 0;
                }
            }

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet16px;
                margin: 0 0 $tablet10px 0;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.5vw;
                line-height: $mobile20px;
                margin: 0 0 $mobile20px 0;
            }

            span {
                font-family: $Montserrat700;
                color: #000;
            }
        }

        &_subContent {
            text-indent: 1.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                text-indent: 2.5vw;
            }

            @media only screen and (max-width: 499px) {
                text-indent: 7.5vw;
            }
        }

        &_list {
            margin: 0 0 1vw 3vw;

            @media only screen and (max-width: 499px) {
                margin: 0;
            }
        }

        &_img {
            float: right;
            width: 19.1vw;
            height: 19.1vw;

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        &_wrapper {
            display: flex;

            @media only screen and (max-width: 499px) {
                flex-wrap: wrap;
            }
        }
    }
}

.apiAccess {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 7vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px $tablet60px $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 6vw $mobile42px 6vw;
            flex-wrap: wrap;
        }
    }

    &__leftSide {
        &_text {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            line-height: 1.6vw;
            color: $greyLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet24px;
                width: 85%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }
    }

    &__rightSide {
        margin-left: 2vw;

        @media only screen and (max-width: 1024px) {
            margin-left: 0;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
            width: 100%;
        }

        &_button {
            @include mainBtnStyle;
            @include blackBtnWithOutBackground;
            font-family: $MontserratRegular;
            height: 3.15vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 9.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 20vw;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 100%;
                font-size: $mobile12px;
            }
        }
    }
}

.deleteAccount {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 7vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px $tablet60px $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 6vw $mobile42px 6vw;
            flex-wrap: wrap;
        }
    }

    &__leftSide {
        &_text {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            line-height: 1.6vw;
            color: $greyLight;


            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet24px;
                width: 85%;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile24px;
            }
        }
    }

    &__rightSide {
        margin-left: 2vw;

        @media only screen and (max-width: 1024px) {
            margin-left: 0;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
            width: 100%;
        }

        &_button {
            @include mainBtnStyle;
            @include redBtnWithOutBackground;
            font-family: $MontserratRegular;
            height: 3.15vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 9.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 20vw;
                height: $tablet48px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 100%;
                font-size: $mobile12px;
            }
        }
    }
}

.lastAccountActivities {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 7vw 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px $tablet60px $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 0 $mobile42px 6vw;
            flex-wrap: wrap;
        }
    }

    &__leftSide {
        @media only screen and (max-width: 499px) {
            width: 50%;
        }

        &_location {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 2.5vw;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet14px;
                margin-bottom: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile16px;
                margin-bottom: $mobile24px;
                display: block;
                height: 13vw;
            }
        }
    }

    &__rightSide {
        @media only screen and (max-width: 499px) {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &_time {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 2.5vw;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet14px;
                margin-bottom: $tablet24px;
                display: block;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile24px;
                margin-bottom: $mobile24px;
                display: block;
                height: 13vw;
            }
        }
    }
}

.trustedDevices {
    padding: 0 7vw 2.1vw 3vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet60px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 $mobile30px $mobile42px $mobile30px;
        flex-wrap: wrap;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 25px 0;

        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 499px) {
            background: #FFFFFF;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
            padding: 25px 8vw;
            margin-bottom: $mobile20px;
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            color: $greyLight;
            margin-bottom: 0.5vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                margin-bottom: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_date,
        &_time,
        &_userAgent,
        &_location,
        &_ip,
        &_curentBrowser {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            line-height: 1.2vw;
            color: $textDarkLight;
            margin: auto 1vw 0 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                line-height: $tablet14px;
                margin-bottom: $mobile20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile16px;
                width: 100% !important;
                margin: auto $mobile10px $mobile20px 0;
            }
        }

        &_date {
            width: 6.5vw;
            display: flex;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 12.5vw;
            }
        }

        &_time {
            width: 5.5vw;
            display: flex;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 8.5vw;
            }
        }

        &_userAgent {
            width: 7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 19vw;
            }
        }

        &_location {
            width: 7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 13vw;
            }
        }

        &_ip {
            width: 10vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 14vw;
            }
        }

        &_curentBrowser {
            width: 10vw;
            color: $blueDark;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 16vw;
            }
        }

        &_button {
            @include mainBtnStyle;
            @include redBtnWithOutBackground;
            font-family: $Montserrat700;
            height: 3.15vw;
            width: fit-content;
            width: -moz-max-content;
            min-width: 9.5vw;
            margin-left: auto;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet176px;
                height: $tablet48px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
                width: 100%;
                font-size: $mobile12px;
            }
        }
    }

    &__confirmNewBrowser {
        display: flex;
        padding: 2.1vw 0;

        @media only screen and (max-width: 499px) {
            justify-content: space-between;
            margin-bottom: 5vw;
        }

        &_label {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 1vw;
            margin-right: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet14px;
                margin-right: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }
    }
}

.notification {

    &__title,
    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            margin-right: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            margin-right: $mobile25px;
        }
    }

    &__title {
        padding: 0 0 2.1vw 3vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px;
            margin-bottom: $tablet30px;
            justify-content: space-between;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 $mobile60px;
            margin-bottom: $mobile30px;
            flex-wrap: wrap;
        }
    }

    &__wrapper {
        padding: 0 7vw 2.1vw 3vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: 0 $tablet88px $tablet60px $tablet88px;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 499px) {
            padding: 0 $mobile30px 0 6vw;
            flex-wrap: wrap;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 22vw;
        margin-bottom: 2.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 45%;
            margin-bottom: $tablet30px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__rightSide {
        &_item {
            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: 0;
            }
        }
    }
}

.blacklist {
    padding: 0 7vw 2.1vw 3vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet60px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 7vw $mobile42px $mobile60px;
        flex-wrap: wrap;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 2.1vw 4.2vw 2.1vw 3vw;
        transition: all 0.25s;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            background: $white;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
            padding: $tablet20px $tablet48px;
            margin-bottom: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            background: #FFFFFF;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
            margin-bottom: $mobile20px;
            padding: $mobile20px;
        }

        &:hover {
            background-color: $white;
            box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
        }
    }

    &__leftSide {
        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        @media only screen and (max-width: 499px) {
            justify-content: space-between;
        }
    }

    &__leftSide,
    &__rightSide {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__img {
        width: 2.4vw;
        height: 2.4vw;
        border-radius: 50%;
        object-fit: cover;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet36px;
            width: $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            height: 9.6vw;
            width: 9.6vw;
        }
    }

    &__name {
        font-family: $MontserratRegular;
        font-size: 1.3vw;
        color: $textDarkLight;
        margin-left: 2.1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            margin-left: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            margin-left: $mobile24px;
        }
    }

    &__blockedAccaunt {
        font-family: $MontserratRegular;
        font-size: 1vw;
        color: $textDarkLight;
        margin-right: 2.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            margin-left: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3.7vw;
        }
    }
}

.twoFactor {
    display: flex;
    padding: 0 7vw 2.1vw 3vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet60px $tablet88px;
        justify-content: space-between;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 6vw $mobile42px 6vw;
        flex-wrap: wrap;
    }

    &__leftSide,
    &__rightSide {
        width: 50%;
    }

    &__leftSide {
        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 60%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__rightSide {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30%;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-top: $mobile20px
        }
    }

    &__title {
        display: flex;
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet14px;
            line-height: $tablet24px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__statusDisabled,
    &__statusEnabled {
        margin-left: 0.5vw;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__statusDisabled {
        color: $red;
    }

    &__statusEnabled {
        color: $blueDark;
    }

    &__inputWrapper {
        margin: 2vw 0 1.5vw 0;
        position: relative;
        width: 23.6vw;

        @media only screen and (max-width: 1024px) {
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            margin: $mobile20px 0;
        }
    }

    &__label {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
        }
    }

    &__input {
        width: 23.6vw;
        height: 3.9vw;
        margin-top: 0.5vw;
        background: $inputColor;
        border-radius: 0.1vw;
        border: none;
        padding-left: 1vw;
        font-size: 1vw;
        line-height: 1.4vw;
        outline: none;
        font-family: $MontserratRegular;
        font-size: 1vw;
        line-height: 1.4vw;
        color: $textDarkLight;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            width: 100%;
            height: $tablet60px;
            margin-top: $tablet10px;
            border-radius: 1px;
            padding-left: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile16px;
            width: 100%;
            height: $mobile60px;
            margin-top: $mobile10px;
            border-radius: 1px;
            padding-left: $mobile10px;
        }


        &::placeholder {
            font-family: $MontserratRegular;
            font-size: 1vw;
            line-height: 3.9vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet55px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile55px;
            }
        }
    }

    &__enable,
    &__disable,
    &__submit {
        @include mainBtnStyle;
        font-family: $Montserrat700;
        height: 3.15vw;
        width: fit-content;
        width: -moz-max-content;
        min-width: 9.5vw;
        margin-left: auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            min-width: 17.5vw;
            height: $tablet48px;
            margin-top: $tablet26px;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: 100%;
            font-size: $mobile12px;
        }
    }

    &__submit {
        margin-top: 2.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: 3.25vw;
        }

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
        }
    }

    &__enable {
        @include greenBtnWithOutBackground;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-top: 0;
        }
    }

    &__disable {
        @include redBtnWithOutBackground;
    }

    &__submit {
        @include greenBtnWithBorderAndBackground;
    }
}
