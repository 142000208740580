@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.firstScreen {
    .slick-dots {
        bottom: $desctop40px;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            bottom: 10.5vw;
        }

        @media only screen and (max-width: 499px) {
            bottom: 8.8vw;
        }

        li {
            margin: 0 $desctop12px;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: 0 $tablet12px;
            }

            @media only screen and (max-width: 499px) {
                margin: 0 $mobile12px;
            }

            button:before {
                font-size: $desctop25px;
                line-height: $desctop25px;
                opacity: .3;
                color: #DDDDDD;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet24px;
                    line-height: $tablet24px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile24px;
                    line-height: $mobile24px;
                }
            }
        }

        li.slick-active button:before {
            color: #B2947B;
        }
    }
}
