@import '../../../../assets/styles/variables';

.aside {
    display: flex;
    flex-direction: column;
    width: 13.7vw;
    min-width: 13.7vw;
    min-height: calc(100vh - 10.85vw);
    background: #f3f7fd;
    overflow: hidden;

    @media only screen and (max-width: 1024px) {
        display: none;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: transparent;
    }

    &__nav {
        margin: 2.4vw 0 0 2.9vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: $tablet36px 0 12vw $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            margin: $mobile25px $mobile25px $mobile75px $mobile25px;
        }

        li {
            margin-bottom: 2.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-bottom: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile42px;
            }

            &:hover {
                svg path {
                    transition: all 0.25s;
                    fill: $blueDark;
                }

                .logOutSvg path {
                    transition: all 0.25s;
                    fill: none;
                    stroke: $blueDark;
                }
            }

            svg {
                margin-right: 1vw;
                width: 1.4vw;
                min-width: 1.4vw;
                height: 1.35vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-right: $tablet24px;
                    width: $tablet20px;
                    min-width: $tablet20px;
                    height: $tablet20px;
                }

                @media only screen and (max-width: 499px) {
                    margin-right: $mobile24px;
                    width: $mobile16px;
                    min-width: $mobile16px;
                    height: $mobile16px;
                }
            }

            img {
                margin-right: 1vw;
                width: 1.3vw;
                height: 1.3vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-right: $tablet24px;
                    width: $tablet20px;
                    height: $tablet20px;
                }

                @media only screen and (max-width: 499px) {
                    margin-right: $mobile24px;
                    width: $mobile16px;
                    height: $mobile16px;
                }
            }

            a {
                display: flex;
                align-items: center;
                font-family: $MontserratRegular;
                font-size: 0.8vw;
                line-height: 1.2vw;
                letter-spacing: 0.1vw;
                text-transform: uppercase;
                color: $greyLight;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    line-height: $tablet16px;
                    letter-spacing: 1px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: $mobile16px;
                    line-height: $mobile16px;
                    letter-spacing: 1px;
                }

                &:hover {
                    transition: all 0.25s;
                    color: $blueDark;
                }
            }
        }
    }
}
