@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.orders {
    padding: 0 7.85vw 6vw 7.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 0 $tablet88px $tablet30px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: 0 $mobile25px $mobile30px $mobile25px ;
    }

    &__filter {
        padding: $desctop75px 7.85vw 0 7.85vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            padding: $tablet60px $tablet88px 0 $tablet88px;
        }

        @media only screen and (max-width: 499px) {
            padding: $mobile30px $mobile25px 0 $mobile25px ;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: $desctop25px;
        line-height: $desctop34px;
        margin-bottom: $desctop50px;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            line-height: $tablet36px;
            margin-bottom: $tablet40px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile42px;
            width: 100%;
        }
    }

    &__allOrdersButton {
        @include mainBtnStyle;
        @include darkBlueBtnWithBorderAndBackground;
        font-family: $Montserrat700;
        width: fit-content;
        width: -moz-max-content;
        min-width: $desctop148px;
        height: $desctop58px;
        font-size: $desctop16px;
        line-height: $desctop12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3vw auto 0;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: auto;
            padding: $tablet24px $tablet55px;
            margin-top: $tablet40px;
            border-radius: $tablet20px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: $mobile60px;
            font-size: $mobile12px;
            line-height: $mobile12px;
            margin: $mobile42px 0 0 0;
            border-radius: $mobile20px;
        }
    }
}
